<rk-app-configuration-submenu
  (submenuSelected)="onSelectSubmenu($event)"
></rk-app-configuration-submenu>
<rk-setting-divider></rk-setting-divider>
@if(selectedSubMenu === 'content') {
<div class="p-6">
  <div class="font-bold mb-4">{{ 'page-config.icon-and-name' | translate }}</div>
  <rk-page-icon-and-name-editor
    [pageIndex]="activePageIndex$ | async"
  ></rk-page-icon-and-name-editor>
</div>
<ng-content select="[content]"></ng-content>
} @else {
<rk-page-design-editor ></rk-page-design-editor>
<ng-content select="[design]"></ng-content>
}
