<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  class="p-5 rounded apple-notification-form admin-form"
>
  <h2 class="m-8 font-bold">Ajouter une Clef API
    <fa-icon [icon]="faAndroid"></fa-icon></h2>
  <div class="flex flex-col m-8">
    <label> ApiKey </label>
    <input type="text" formControlName="androidApiKey" />
  </div>

  <div class="flex gap-2 m-8">
    <rk-button
      [variant]="'secondary'"
      [text]="'Cancel'"
      (buttonClick)="onNoClick()"
    ></rk-button>
    <rk-button [variant]="'primary'" [text]="'Save'" type="submit" (buttonClick)="onSubmit()"></rk-button>
  </div>
</form>
