import { Component } from '@angular/core';
import { UpdateAppConfig } from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'rk-div-shadow-settings',
  templateUrl: './div-shadow-settings.component.html',
  styleUrl: './div-shadow-settings.component.scss',
})
export class DivShadowSettingsComponent {
  @Select(AppConfigurationState.divShadow)
  divShadow$: Observable<number>;

  constructor(private readonly store: Store) {}

  onDivShadowSelection(shadow: number) {
    this.store.dispatch(new UpdateAppConfig(['onglets', '0', 'divShadow'], shadow));
  }
}
