<div class="p-8 flex flex-col max-w-[610px] relative">
  <fa-icon
    class="absolute top-4 right-4 text-base opacity-70 cursor-pointer"
    [icon]="'xmark'"
    (click)="onClose()"
  ></fa-icon>
  <div class="mb-8">
    <!-- cdkFocusInitial is a hack to avoid auto focusing on buttons
    couldn't find another way to do it -->
    <h1 class="text-2xl font-bold mb-3" cdkFocusInitial>
      {{ 'publication-addon-dialog.title' | translate }}
    </h1>
    <p
      class="opacity-70"
      [innerHTML]="'publication-addon-dialog.message' | translate: { price: addOnPrice }"
    ></p>
  </div>
  <div class="flex justify-end gap-2 md:gap-4">
    <rk-button
      [text]="'publication-addon-dialog.refuse' | translate"
      [variant]="'secondary'"
      (buttonClick)="onDismiss()"
    ></rk-button>
    <rk-button
      [text]="'publication-addon-dialog.accept' | translate"
      (buttonClick)="onConfirm()"
    ></rk-button>
  </div>
</div>
