import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'rk-api-error-alert',
  templateUrl: './api-error-alert.component.html',
  styleUrl: './api-error-alert.component.scss',
})
export class ApiErrorAlertComponent implements OnInit {
  @Select(AppConfigurationState.apiErrorMessage)
  apiErrorMessage$: Observable<string>;

  showAlertDetails = true;
  errorExists: boolean;

  constructor(
    private readonly eRef: ElementRef,
    private readonly store: Store,
  ) {}

  ngOnInit() {
    this.apiErrorMessage$.subscribe(error => {
      if (error) {
        this.errorExists = true;
        this.showAlertDetails = true;

        // event tracking
        this.store.dispatch(
          new SetEventProperties({
            name: 'AlertNextStep',
            component: 'Alert',
          }),
        );
        this.store.dispatch(new LogEvent('Alert Added'));
      }
    });
  }

  onIconClick() {
    this.showAlertDetails = true;

    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: 'AlertClick',
        component: 'Alert',
      }),
    );
    this.store.dispatch(new LogEvent('Alert Clicked'));
  }

  @HostListener('document:click', ['$event'])
  clickout(event: MouseEvent) {
    if (
      !this.eRef.nativeElement.contains(event.target as Node) &&
      this.showAlertDetails &&
      this.errorExists
    ) {
      this.showAlertDetails = false;
    }
  }
}
