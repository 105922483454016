import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Variants =
  | 'primary'
  | 'secondary'
  | 'white'
  | 'border-grey'
  | 'border-white'
  | 'info'
  | 'success'
  | 'danger'
  | 'warning';
type Sizes = 'small' | 'medium' | 'large' | 'extra-large';

@Component({
  selector: 'rk-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @HostBinding('class')
  @Input()
  variant: Variants = 'primary';
  @Input()
  size: Sizes = 'medium';
  @Input()
  iconOnly?: boolean;
  @Input()
  icon?: IconProp;
  @Input()
  text?: string;
  @Input()
  disabled?: boolean;
  @Input()
  colorVariant?: Variants;
  @HostBinding('class.full-width')
  @Input()
  fullWidth?: boolean = false;
  @Input()
  isLoading?: boolean = false;

  @Output()
  readonly buttonClick = new EventEmitter();

  getButtonClasses() {
    const customColor = `color-${this.colorVariant}`;
    const classes: any = {
      'icon-only': this.iconOnly,
      'has-icon': !!this.icon,
      sm: this.size === 'small',
      lg: this.size === 'large',
      xl: this.size === 'extra-large',
    };
    if (this.colorVariant) {
      classes[customColor] = true;
    }

    return classes;
  }

  onClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.buttonClick.emit(event);
  }
}
