import { Component, OnDestroy, OnInit } from '@angular/core';
import { UpdateAppConfig } from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Select, Store } from '@ngxs/store';
import { filter, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'rk-font-size-selector',
  templateUrl: './font-size-selector.component.html',
  styleUrl: './font-size-selector.component.scss',
})
export class FontSizeSelectorComponent implements OnInit, OnDestroy {
  @Select(AppConfigurationState.fontSize)
  fontSize$: Observable<number>;

  fontSize = 3;

  constructor(private readonly store: Store) {}

  private readonly subscriptions = new Subscription();

  ngOnInit(): void {
    this.subscriptions.add(
      this.fontSize$
        .pipe(filter(size => !!size))
        .subscribe(size => (this.fontSize = size)),
    );
  }

  onSizeChange() {
    this.store.dispatch(new UpdateAppConfig(['onglets', '0', 'fontSize'], this.fontSize));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
