import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Logout } from '@app/core/auth/states/auth.actions';
import { AuthState } from '@app/core/auth/states/auth.state';
import { TranslateService } from '@ngx-translate/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable, combineLatest, map } from 'rxjs';

@Component({
  selector: 'rk-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  @Select(AuthState.currentUsername)
  userName$: Observable<string>;
  @Select(AuthState.currentFirstname)
  firstName$: Observable<string>;
  @Select(AuthState.currentLastname)
  lastName$: Observable<string>;
  @Select(AuthState.currentEmail)
  userEmail$: Observable<string>;
  @Select(AuthState.currentProfilePic)
  currentProfilePic$: Observable<string>;
  @Select(AuthState.userRKDomain)
  userRKDomain$: Observable<string>;
  displayName$: Observable<string>;

  dropdownActive = false;
  customerAreaLink$: Observable<string>;
  profileLink$: Observable<string>;

  @ViewChild('userMenuDropdown', { static: false })
  userMenuDropdown: ElementRef;

  constructor(private readonly translateService: TranslateService) {}

  ngOnInit() {
    this.displayName$ = combineLatest([this.firstName$, this.lastName$]).pipe(
      map(([firstName, lastName]) => `${firstName} ${lastName.charAt(0)}.`),
    );
    this.customerAreaLink$ = this.userRKDomain$.pipe(
      map(domain => `${domain}/on/clientarea.php`),
    );
    const currentLocale = this.translateService.currentLang;
    this.profileLink$ = this.userRKDomain$.pipe(
      map(domain => {
        if (currentLocale === "fr-FR") {
          return `${domain}/profil`;
        } else {
          return `${domain}/profile`
        }
      }),
    );
  }

  @Dispatch()
  logout() {
    return new Logout();
  }

  toggleDropdown(): void {
    this.dropdownActive = !this.dropdownActive;
  }

  triggerLogout(): void {
    this.logout();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    if (
      this.userMenuDropdown &&
      !this.userMenuDropdown.nativeElement.contains(targetElement)
    ) {
      this.dropdownActive = false;
    }
  }
}
