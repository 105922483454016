import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';


@NgModule({
  imports: [MatIconModule],
})
export class MaterialModule {
  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
  ) {
    this.matIconRegistry
      .registerFontClassAlias('fontawesome', 'fa')
      .addSvgIcon(
        'close-menu',
        this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/close-menu.svg'),
      );
  }
}
