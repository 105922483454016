<div class="w-full autocomplete-container">
  <div class="relative flex items-center">
    <fa-icon
      [icon]="'language'"
      class="absolute left-[16px] text-[#CDCDCD]"
    ></fa-icon>
    <input
    matInput
    [matAutocomplete]="auto"
    [formControl]="optionsCtrl"
    placeholder="{{ 'notification-form.language-placeholder' | translate }}"
  />
  </div>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChange($event)">
    <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
      {{ displayFn(option) }}
    </mat-option>
  </mat-autocomplete>
</div>
