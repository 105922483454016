<div
  class="ml-6 pl-5 pr-5 border-l-2 border-opacity-10 page-list-container page-list-drop-boundary"
  cdkDropList
  cdkDropBoundary=".page-list-drop-boundary"
  [cdkDropListConnectedTo]="['add-new-page']"
  (cdkDropListDropped)="drop($event)"
  id="page-list"
>
  <div
    *ngFor="let page of currentAppConfigPageList$ | async; let i = index"
    class="px-5 py-2 rounded cursor-pointer page-title"
    [class.selected]="i === (activePageIndex$ | async)"
    (click)="setActivePage(i)"
    cdkDrag
    [cdkDragDisabled]="i === 0 || isEditing[i]"
    (cdkDragStarted)="dragStart()"
  >
    <div class="drag-placeholder" *cdkDragPlaceholder></div>
    <div
      class="flex items-center opacity-70"
      [class.opacity-100]="i === (activePageIndex$ | async)"
    >
      <!-- page icon -->
      <div class="page-icon">
        <!-- font-awesome icon -->
        <fa-icon
          class="mr-[7px] text-[15px]"
          *ngIf="page.faIcon !== null; else legacyIcon"
          [icon]="getFaIcon(page.faIcon)"
        ></fa-icon>
        <!-- legacy icon -->
        <ng-template #legacyIcon>
          <img
            class="w-[15px] h-[15px] mr-2"
            [src]="radiokingDomain + '/images/mobile/icons/' + page.icon + '.png'"
            alt="icon"
          />
        </ng-template>
      </div>

      <!-- grip icon -->
      <fa-icon
        class="grip-icon mr-[7px] text-[15px]"
        [icon]="i === 0 ? 'lock' : 'grip-vertical'"
      ></fa-icon>
      <!-- page name -->
      <div class="w-full">
        <span
          *ngIf="!isEditing[i]"
          class="opacity-70 w-full"
          (dblclick)="startEditing(i, $event)"
          >{{ page.titre }}</span
        >
        <input
          *ngIf="isEditing[i]"
          #editInput
          [value]="page.titre"
          (blur)="finishEditing(i, $event)"
          (keyup.enter)="finishEditing(i, $event)"
          autofocus
          maxlength="45"
          (click)="stopPropagation($event)"
          class="w-full"
        />
        <div class="action-buttons">
          <!-- pen icon -->
          <fa-icon
            class="edit-pen mr-3 text-[15px]"
            [icon]="'pen'"
            *ngIf="!isEditing[i]"
            (click)="startEditing(i, $event)"
          >
          </fa-icon>
          <!-- delete page button -->
          <fa-icon
            class="delete-page-button mr-5 text-[15px]"
            [icon]="'trash'"
            (click)="deletePage($event, i)"
            *ngIf="i !== 0 && !isEditing[i]"
          >
          </fa-icon>
        </div>
      </div>
    </div>
  </div>
</div>
