import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthState } from '@app/core/auth/states/auth.state';
import { AdminApp, App } from '@app/core/models/app.model';
import { SetCurrentAppAsAdmin } from '@app/core/states/app.actions';
import { environment } from '@env/environment';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { Select, Store } from '@ngxs/store';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
} from 'rxjs';
import {
  DecrementPage,
  FetchAdminAppListsRequest,
  IncrementPage,
} from '../../states/admin.actions';
import { AdminState } from '../../states/admin.state';
import { AndroidNotificationFormComponent } from '../android-notification-form/android-notification-form.component';
import { AppGenerationFilesComponent } from '../app-generation-files/app-generation-files.component';
import { AppleNotificationFormComponent } from '../apple-notification-form/apple-notification-form.component';

@Component({
  selector: 'rk-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent implements OnInit {
  @Select(AdminState.adminAppList)
  adminAppList$: Observable<AdminApp[]>;
  @Select(AdminState.isLoading)
  isLoading$: Observable<boolean>;
  @Select(AdminState.currentPage)
  currentPage$: Observable<number>;
  @Select(AuthState.isSuperUser)
  isSuperUser$: Observable<boolean>;

  faAndroid = faAndroid;
  faApple = faApple;

  displayedColumns: string[] = ['logo', 'id', 'name', 'platforms'];
  searchQuery = new BehaviorSubject<string>('');

  constructor(
    private readonly store: Store,
    public dialog: MatDialog,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.currentPage$,
      this.activatedRoute.queryParams,
      this.searchQuery.pipe(debounceTime(500), distinctUntilChanged()),
      this.isSuperUser$.pipe(filter(user => user !== undefined)),
    ]).subscribe(([currentPage, queryParams, localSearchQuery, isSuperUser]) => {
      if (!isSuperUser) {
        this.router.navigateByUrl('/apps');

        return;
      }
      const searchTerm = queryParams['search'] || localSearchQuery;
      this.store.dispatch(new FetchAdminAppListsRequest(currentPage, searchTerm));
    });
  }

  onSearchInputChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.searchQuery.next(value);
  }

  increment(): void {
    this.store.dispatch(new IncrementPage());
  }

  decrement(): void {
    this.store.dispatch(new DecrementPage());
  }

  openAppleNotificationForm(app: AdminApp): void {
    this.dialog.open(AppleNotificationFormComponent, {
      data: app,
    });
  }

  openAndroidNotificationForm(app: AdminApp): void {
    this.dialog.open(AndroidNotificationFormComponent, {
      data: app,
    });
  }

  openAppGenerationFiles(app: AdminApp, platform: 'android' | 'apple') {
    this.dialog.open(AppGenerationFilesComponent, {
      data: { app, platform },
    });
  }

  manageApp(app: App) {
    this.store.dispatch(new SetCurrentAppAsAdmin(app));
    this.router.navigateByUrl('/apps');
  }

  setDefaultImage(event: any): void {
    event.target.src = '/assets/rk-logo-square.png';
  }

  generateWhmcsUrl(userId: number, serviceId: number): string {
    return `https://www.${environment.urls.RADIOKING_DOMAIN}/on/admin/clientsservices.php?userid=${userId}&id=${serviceId}`;
  }
}
