import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { AlertComponent } from './alert.component';
import { AlertProps } from './alert.model';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  rootViewContainer: any;
  componentsReferences: ComponentRef<AlertComponent>[] = [];

  setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }
  public addAlert(alertProps: AlertProps) {
    const alertC = AlertComponent;
    const alert: ComponentRef<AlertComponent> =
      this.rootViewContainer.createComponent(alertC);
    alert.instance.init(alertProps);
    this.componentsReferences.push(alert);
  }

  removeDynamicComponent() {
    if (this.rootViewContainer.length < 1) {
      return;
    }

    // let componentRef = this.componentsReferences.filter(x => console.log(x.instance.index) )[0];
    // let component: ChildComponent = <ChildComponent>componentRef.instance;

    // let vcrIndex: number = this.VCR.indexOf(componentRef)

    // // removing component from container
    // this.VCR.remove(vcrIndex);

    // this.componentsReferences = this.componentsReferences.filter(x => x.instance.index !== index);
  }
}
