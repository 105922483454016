import { Component, Input } from '@angular/core';
import { fadeInOutAnimation } from '@app/core/animations';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'rk-setting-container',
  templateUrl: './setting-container.component.html',
  styleUrls: ['./setting-container.component.scss'],
  animations: [fadeInOutAnimation],
})
export class SettingContainerComponent {
  @Input()
  name?: string;
  @Input()
  icon?: IconProp
  isContentVisible = true;

  toggleContent(): void {
    this.isContentVisible = !this.isContentVisible;
  }
}
