import { PlatformSubscriber, SubscribersPerDay } from '../models/statistics.models';

const tag = '[Statistics]';

export class FetchSubscribersCountRequest {
  static readonly type = `${tag} fetch subscribers count request`;
}

export class FetchSubscribersCountSuccess {
  static readonly type = `${tag} fetch subscribers count success`;
  constructor(public subscriberCount: PlatformSubscriber[]) {}
}

export class FetchSubscribersCountError {
  static readonly type = `${tag} fetch subscribers count error`;
  constructor(public error: Error) {}
}

export class FetchNewSubscribersRequest {
  static readonly type = `${tag} fetch new subscribers request`;
}

export class FetchNewSubscribersSuccess {
  static readonly type = `${tag} fetch new subscribers success`;
  constructor(public newSubscribers: SubscribersPerDay[]) {}
}

export class FetchNewSubscribersError {
  static readonly type = `${tag} fetch new subscribers error`;
  constructor(public error: Error) {}
}

export class FetchConsumptionRequest {
  static readonly type = `${tag} fetch consumption request`;
}

export class FetchConsumptionSuccess {
  static readonly type = `${tag} fetch consumption success`;
  constructor(
    public consumption: { consumption: number; limit: number; use_percent: number },
  ) {}
}

export class FetchConsumptionError {
  static readonly type = `${tag} fetch consumption error`;
  constructor(public error: Error) {}
}

export class FetchNotificationsSentRequest {
  static readonly type = `${tag} fetch notifications sent request`;
}

export class FetchNotificationsSentSuccess {
  static readonly type = `${tag} fetch notifications sent success`;
  constructor(public notificationsSent: SubscribersPerDay[]) {}
}

export class FetchNotificationsSentError {
  static readonly type = `${tag} fetch notifications sent error`;
  constructor(public error: Error) {}
}

export class ResetStatisticsState {
  static readonly type = `${tag} reset statistics state`;
}
