import * as amplitude from '@amplitude/analytics-browser';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  InitEventTracking,
  LogEvent,
  LogUserProperty,
  SetEventProperties,
} from './event-tracking.actions';

export interface UserProperties {
  language: string;
  user_id: string;
  device_size: string;
  device_category: string;
  product_name: string;
}

export interface EventProperties {
  name?: string;
  component?: string;
  origin?: string;
  origin_URL?: string;
  status?: string;
  current_step?: number;
  has_errors?: boolean;
  is_empty?: boolean;
  error_type?: number;
  error_content?: string;
}

export class EventTrackingStateModel {
  eventProperties: EventProperties;
}

@State<EventTrackingStateModel>({
  name: 'EventsTracking',
  defaults: {
    eventProperties: {
      origin: 'None',
    },
  },
})
@Injectable()
export class EventsTrackingState {
  @Action(InitEventTracking)
  initEventTracking(
    ctx: StateContext<EventsTrackingState>,
    { userProperties }: InitEventTracking,
  ) {
    amplitude.init(environment.amplitudeKey, userProperties.user_id, {
      minIdLength: 1,
      logLevel: amplitude.Types.LogLevel.Warn,
    });

    const identifyEvent = new amplitude.Identify();

    Object.entries(userProperties).forEach(([key, value]) => {
      identifyEvent.set(key, value);
    });

    amplitude.identify(identifyEvent);
  }

  @Action(LogEvent)
  logEvent(ctx: StateContext<EventTrackingStateModel>, { event }: LogEvent) {
    amplitude.track(event, ctx.getState().eventProperties);
    const currentEventName = ctx.getState().eventProperties.name;
    ctx.patchState({
      eventProperties: { origin: currentEventName },
    });
  }

  @Action(SetEventProperties)
  setEventProperties(
    ctx: StateContext<EventTrackingStateModel>,
    { eventProperties }: SetEventProperties,
  ) {
    ctx.setState(
      patch({
        eventProperties: {
          ...ctx.getState().eventProperties,
          ...eventProperties,
          origin_URL: window.location.href,
        },
      }),
    );
  }

  @Action(LogUserProperty)
  setUserProperties(
    ctx: StateContext<EventTrackingStateModel>,
    { property, value }: LogUserProperty,
  ) {
    const identifyEvent = new amplitude.Identify();
    identifyEvent.set(property, value);
    amplitude.identify(identifyEvent);
  }
}
