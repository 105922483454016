import { Component } from '@angular/core';
import { UpdateAppConfig } from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'rk-button-style-settings',
  templateUrl: './button-style-settings.component.html',
  styleUrl: './button-style-settings.component.scss',
})
export class ButtonStyleSettingsComponent {
  constructor(private readonly store: Store) {}

  @Select(AppConfigurationState.mainColor)
  mainColor$: Observable<string>;

  @Select(AppConfigurationState.buttonStyle)
  buttonStyle$: Observable<string>;

  onButtonColorChange(color: string) {
    this.store.dispatch(new UpdateAppConfig(['onglets', '0', 'mainColor'], color));
  }

  onButtonStyleSelection(style: string) {
    this.store.dispatch(new UpdateAppConfig(['onglets', '0', 'buttonStyle'], style));
  }
}
