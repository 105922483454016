import { Injectable } from '@angular/core';

import { RedirectToLogin } from '@app/core/auth/states/auth.actions';
import { AuthState } from '@app/core/auth/states/auth.state';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Store } from '@ngxs/store';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class AuthGuard  {
  constructor(private readonly store: Store) {}

  @Dispatch()
  redirectToLogin = () => new RedirectToLogin();

  canActivate(): Observable<boolean> {

    return this.store.select(AuthState.isAuthenticated).pipe(
      filter(auth => auth !== undefined),
      map((isAuth: boolean) => {
        if (isAuth) {
          return true;
        }
        this.redirectToLogin();
        // Autre méthode, mais la 1ère permet d'éviter l'import du Store si utilisé uniquement pour dispatch
        // this.store.dispatch(new RedirectToLogin());

        return false;
      }),
    );
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }
}
