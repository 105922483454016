<div class="relative flex items-center w-full">
  <div
    class="cursor-pointer flex justify-center items-center rounded-[5px] size-[40px] bg-brand-light mr-2"
    (click)="onIconClick($event)"
  >
    <!-- font-awesome icon -->
    <fa-icon
      class="text-[15px] text-brand"
      *ngIf="faIcon !== null; else legacyIcon"
      [icon]="faIcon"
    ></fa-icon>
    <!-- legacy icon -->
    <ng-template #legacyIcon>
      <img class="w-[15px] h-[15px] legacy-icon" [src]="legacyIconUrl" alt="icon"
    /></ng-template>
  </div>
  <div class="flex-1">
    <rk-form-field>
      <input
        *ngIf="pageNameControl"
        class="bg-[#f7f7f7] px-4 py-2 rounded-[5px]"
        [class.form-alert]="pageNameControl?.invalid && pageNameControl?.touched"
        [formControl]="pageNameControl"
        maxlength="45"
      />
    </rk-form-field>
  </div>

  <rk-icon-search
    #iconSearch
    *ngIf="iconSearchVisible"
    class="absolute left-[-8px] top-[24px] z-10 icon-search"
    (iconSelected)="onIconSelected($event)"
  ></rk-icon-search>
</div>
