import { Component, OnInit } from '@angular/core';
import { AuthState } from '@app/core/auth/states/auth.state';
import { App } from '@app/core/models/app.model';
import { AppState } from '@app/core/states/app.state';
import { environment } from '@env/environment';
import { Select } from '@ngxs/store';
import { Observable, Subscription, combineLatest, map } from 'rxjs';

@Component({
  selector: 'rk-main-menu-mobile',
  templateUrl: './main-menu-mobile.component.html',
  styleUrls: ['./main-menu-mobile.component.scss'],
})
export class MainMenuMobileComponent implements OnInit {
  @Select(AuthState.userRKDomain)
  userRKDomain$: Observable<string>;
  @Select(AppState.currentApp)
  currentApp$: Observable<App>;

  publicationRoute$: Observable<string>;
  configurationRoute$: Observable<string>;

  private readonly subscription: Subscription = new Subscription();

  ngOnInit() {
    this.configurationRoute$ = combineLatest([this.userRKDomain$, this.currentApp$]).pipe(
      map(([domain, app]) => `${domain}/update-app-${app?.appId}`),
    );
    this.publicationRoute$ = this.currentApp$.pipe(
      map(app => `${environment.urls.MOBILE_API}/publication/app/${app?.appId}`),
    );
  }
}
