<div class="flex justify-between items-center mb-2 px-[2px]">
  <label class="opacity-70">{{ parameter }}</label>
  <div class="flex gap-5">
    <!-- opacity form field -->
    <div class="flex items-center" *ngIf="opacityEnabled">
      <span class="text-base font-bold text-black mr-2">{{
        'color-selector.opacity' | translate
      }}</span>
      <rk-form-field class="relative w-[83px]">
        <input
          type="text"
          class="opacity-input text-right"
          [formControl]="opacityControl"
          (keydown)="validateOpacityInput($event)"
          [class.form-alert]="opacityControl.touched && opacityControl.invalid"
        />
        <span class="absolute right-4 opacity-40 top-1/2 transform -translate-y-1/2"
          >%</span
        >
      </rk-form-field>
    </div>
    <!-- hex form field -->
    <div class="flex items-center">
      <span class="text-base font-bold text-black mr-2">Hex</span>
      <rk-form-field class="relative w-[106px]" [class.large-field]="opacityEnabled">
        <input
          type="text"
          class="hex-input"
          [formControl]="hexColorControl"
          [class.form-alert]="!isHexValid && hexColorControl.touched"
          [maxlength]="opacityEnabled ? 8 : 6"
          (keydown)="validateHexInput($event)"
        />
        <span class="absolute left-4 opacity-40 top-1/2 transform -translate-y-1/2"
          >#</span
        >
      </rk-form-field>
    </div>
  </div>
</div>
<div class="flex w-full justify-between mb-2 px-[2px]">
  @if (supportsTransparentColor) {
    <!-- empty icon -->
    <fa-icon
      [icon]="'ban'"
      class="selector-button grey-border rounded flex justify-center items-center"
      (click)="resetColor()"
      [ngClass]="{
        'orange-border': hexColorControl.value === '',
        'orange-icon': hexColorControl.value === ''
      }"
    >
    </fa-icon>
  }
  <!-- colors -->
  <div *ngFor="let color of colors" class="relative">
    <div
      [ngStyle]="{ 'background-color': color, color: color }"
      class="selector-button rounded flex justify-center items-center"
      (click)="selectColor(color)"
      [ngClass]="{
        'orange-border': hexColorControl.value.toUpperCase() === color.replace('#', ''),
        'grey-border': color === '#FFFFFF'
      }"
    >
      <fa-icon
        *ngIf="hexColorControl.value.toUpperCase() === color.replace('#', '')"
        class="text-xl"
        [icon]="'check'"
        [ngClass]="{
          'white-icon': color !== '#FFFFFF',
          'orange-icon': color === '#FFFFFF' || color === '#252525'
        }"
      ></fa-icon>
    </div>
  </div>
  <!-- picker icon -->
  <div>
    <fa-icon
      [icon]="'sliders-h'"
      class="selector-button color-picker-button bg-white rounded flex-col justify-center items-center inline-flex"
      [ngClass]="{
        'black-icon':
          colorPickerCurrentColor === '#FFFFFF' ||
          colorPickerCurrentColor === 'transparent',
        'orange-icon': showColorPicker,
        'orange-border': colorPickerCurrentColor !== 'transparent',
        'white-icon': colorPickerCurrentColor !== 'transparent' && !showColorPicker
      }"
      (colorPickerChange)="onColorPickerChange($event)"
      (cpToggleChange)="toggleColorPicker()"
      [cpPosition]="'bottom-left'"
      [cpOutputFormat]="'hex'"
      [cpWidth]="'335px'"
      [cpHeight]="'248px'"
      [cpAlphaChannel]="opacityEnabled ? 'always' : 'disabled'"
      [colorPicker]="colorPickerCurrentColor"
      [style.background]="colorPickerCurrentColor"
    >
    </fa-icon>
  </div>

  <!-- see style.scss for color picker styles override -->
</div>
<!-- error message -->
<div *ngIf="!isHexValid">
  <span class="color-selector-error-message mt-2">{{
    'color-selector.hex-error' | translate
  }}</span>
</div>
<!-- Error message for opacity -->
<div *ngIf="opacityControl.touched && opacityControl.invalid" class="mt-2">
  <span class="color-selector-error-message">{{
    'color-selector.opacity-error' | translate
  }}</span>
</div>
