<div class="user-menu-dropdown" *ngIf="userName$ | async" #userMenuDropdown>
  <div>
    <button
      class="user-menu-dropbtn px-6 flex items-center text-base"
      (click)="toggleDropdown()"
    >
      {{ displayName$ | async }}
      <fa-icon
        [icon]="'chevron-down'"
        class="user-menu-arrow mx-3"
        [ngClass]="{ 'user-menu-arrow-active': dropdownActive }"
      ></fa-icon>
    </button>
    <div
      class="user-menu-dropdown-content"
      [ngClass]="{ 'user-menu-show': dropdownActive }"
    >
      <a [href]="profileLink$ | async" target="_blank">
        <div class="user-menu-dropdown-item user-card py-4 flex items-center w-full">
          <img
            [src]="(currentProfilePic$ | async) || '/assets/default-avatar.png'"
            alt=""
          />
          <div class="ml-4">
            <div class="user-card-name text-base font-bold">{{ userName$ | async }}</div>
            <div class="text-base opacity-70">{{ userEmail$ | async }}</div>
          </div>
        </div>
      </a>

      <a
        [href]="customerAreaLink$ | async"
        class="user-menu-dropdown-item text-base opacity-70"
        target="_blank"
        >{{ 'user-menu.customer' | translate }}
      </a>
      <a
        class="user-menu-dropdown-item text-base opacity-70"
        [href]="'header.help-link' | translate"
        target="_blank"
        >{{ 'user-menu.help' | translate }}
      </a>
      <a
        class="user-menu-dropdown-item text-base opacity-70"
        [href]="'user-menu.news-link' | translate"
        target="_blank"
        >{{ 'user-menu.news' | translate }}
      </a>
      <a
        class="user-menu-dropdown-item user-menu-logout text-base text-error"
        id="user-menu-logout"
        (click)="triggerLogout()"
        ><fa-icon [icon]="'right-from-bracket'" class="mr-3"></fa-icon>
        {{ 'user-menu.logout' | translate }}</a
      >
    </div>
  </div>
</div>
