<ng-container *ngIf="isLoading$ | async; else showContent">
  <div class="flex justify-center items-center w-full h-full">
    <mat-progress-spinner
      class="w-full h-full"
      mode="indeterminate"
      [strokeWidth]="5"
      [diameter]="50"
    ></mat-progress-spinner>
  </div>
</ng-container>

<ng-template #showContent>
  <!-- Container -->
  <div class="statistics-dashboard-container h-full flex w-full" [@fadeInOut]>
    <div class="flex-1 h-full overflow-hidden">
      <!-- Welcome message -->
      <div class="p-6 md:p-0">
        <div class="text-[32px] font-bold">
          {{ 'dashboard.hello' | translate }} {{ currentFirstname$ | async }}
        </div>
        <div class="font-medium md:mb-8 opacity-70">
          {{ 'dashboard.welcome' | translate }}
        </div>
      </div>
      <!-- Stats details -->
      <div
        class="statistics-details rounded-xl bg-white p-8 pb-52  flex-col w-full h-full overflow-auto md:pb-0"
      >
        <div class="flex-1 w-full mb-6 m">
          <!-- Dashboard title -->
          <div class="text-xl font-bold mb-6">
            {{ 'dashboard.title' | translate }}
          </div>
          <!-- Stats cards -->
          <div class="flex flex-col w-full gap-6 md:flex-row">
            <!-- Total card -->
            <div class="flex-1 stats-card">
              <div class="stats-card-title">
                {{ 'dashboard.subscribers-total' | translate }}
              </div>
              <div class="stats-big-number">
                {{ totalSubscribers$ | async | number : '1.0-0' : currentLocale }}
              </div>
            </div>
            <!-- Per platform card -->
            <div class="flex-1 stats-card">
              <div class="stats-card-title">
                {{ 'dashboard.subscriber-detail' | translate }}
              </div>
              <div class="flex justify-between">
                <!-- Per platform count -->
                <div class="stats-big-number">
                  {{ iphoneCount$ | async | number : '1.0-0' : currentLocale }}
                </div>
                <div class="stats-big-number">
                  {{ androidCount$ | async | number : '1.0-0' : currentLocale }}
                </div>
              </div>
              <!-- Per platform percentages -->
              <div
                class="flex justify-between items-center mb-1 text-black opacity-50 text-sm"
              >
                <div class="flex items-center gap-1">
                  <div>{{ iphonePercentage$ | async }}%</div>
                  <fa-icon [icon]="faApple"></fa-icon>
                </div>
                <div class="flex items-center gap-1">
                  <fa-icon [icon]="faAndroid"></fa-icon>
                  <div>{{ androidPercentage$ | async }}%</div>
                </div>
              </div>
              <!-- Percentage bar -->
              <div
                *ngIf="(totalSubscribers$ | async) !== 0"
                class="h-[24px] w-full flex platform-bar"
              >
                <div
                  *ngIf="(iphoneCount$ | async) !== 0"
                  [style.width.%]="iphonePercentage$ | async"
                  class="flex items-center p-2 platform-bar-apple"
                  [ngClass]="{ 'unique-platform': (androidPercentage$ | async) === 0 }"
                ></div>
                <div
                  *ngIf="(androidCount$ | async) !== 0"
                  class="flex justify-end items-center p-2 platform-bar-android"
                  [style.width.%]="androidPercentage$ | async"
                  [ngClass]="{ 'unique-platform': (iphonePercentage$ | async) === 0 }"
                ></div>
              </div>
            </div>
            <!-- Consumption card -->
            <div class="flex-1 stats-card">
              <div class="stats-card-title">
                {{ 'dashboard.consumption' | translate }}
              </div>
              <div class="stats-big-number">
                {{ (consumption$ | async)?.use_percent | number : '1.0-2' : currentLocale}}%
              </div>
              <div class="text-[#656565] font-medium mt-[15px]">
                {{
                  (consumption$ | async)?.consumption | number : '1.0-0' : currentLocale
                }}
                /
                {{ (consumption$ | async)?.limit | number : '1.0-0' : currentLocale }}
                notifications
              </div>
              <div class="bg-[#DAEADF] flex w-full h-[9px] rounded-[10px]">
                <div
                  [style.width.%]="(consumption$ | async)?.use_percent"
                  class="bg-valid rounded-[10px]"
                  [ngClass]="{ 'bg-error': (consumption$ | async)?.use_percent >= 100 }"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <!-- Stats charts -->
        <div class="h-[650px] hidden md:block">
          <rk-data-chart
            class="stats-card mb-6"
            [dataStream$]="newSubscribers$"
            [title]="'dashboard.new-subscribers' | translate"
            [unit]="'subscriber'"
          ></rk-data-chart>
          <rk-data-chart
            class="stats-card"
            [dataStream$]="notificationsSent$"
            [title]="'dashboard.notifications-sent' | translate"
            [unit]="'notification'"
            [lineColor]="'orangeGradient'"
          ></rk-data-chart>
        </div>
      </div>
    </div>
    <!-- Notification sidebar -->
    <div class="hidden xxl:block">
      <rk-notification-sidebar></rk-notification-sidebar>
    </div>
  </div>
</ng-template>
