<div class="notification-form-container rounded-xl h-full flex flex-col relative">
  <rk-notification-form-alert></rk-notification-form-alert>
  <!-- Mobile header -->
  <div class="md:hidden p-6">
    <div class="mb-1 flex gap-2 items-center">
      <rk-button
        [icon]="'chevron-left'"
        variant="white"
        [iconOnly]="true"
        (buttonClick)="closeForm()"
      ></rk-button>
      <span class="opacity-70 text-lg font-medium"
        >{{ 'notification-form.back' | translate }}
      </span>
    </div>
    <div class="font-bold text-2xl">{{ 'notification-form.new' | translate }}</div>
  </div>
  <!-- Header -->
  <div class="hidden rounded-xl bg-white py-4 px-6 z-10 w-full justify-between items-center md:flex">
    <div class="font-bold text-xl">{{ 'notification-form.new' | translate }}</div>
    <rk-button
      [icon]="'close'"
      variant="border-grey"
      [iconOnly]="true"
      (buttonClick)="closeForm()"
    ></rk-button>
  </div>
  <!-- form -->
  <form [formGroup]="notificationForm" class="w-full mt-2 bg-white px-6 pt-6 pb-[2px] md:mt-0 md:pt-0">
    <!-- message input -->
    <div>
      <div class="mb-3">
        <label for="message">{{ 'notification-form.message' | translate }}</label>
      </div>
      <textarea
        id="message"
        formControlName="message"
        placeholder="{{ 'notification-form.message-placeholder' | translate }}"
        (input)="removeEmojis($event)"
        [ngClass]="{
          'alert-border message-alert-textarea':
            notificationForm.get('message').invalid &&
            notificationForm.get('message').touched,
          'alert-text': notificationForm.get('message').value.length > 140
        }"
      >
      </textarea>
      <div class="flex justify-between mt-1 gap-3">
        <!-- error message -->
        <div
          class="form-alert alert-text"
          [ngClass]="{
            'show-form-alert':
              notificationForm.get('message').invalid &&
              notificationForm.get('message').touched
          }"
        >
          <fa-icon [icon]="'warning'"></fa-icon>
          {{ 'notification-form.message-error' | translate }}
        </div>
        <!-- counter -->
        <span
          class="counter opacity-70"
          [ngClass]="{
            'alert-text': notificationForm.get('message').value.length > 140
          }"
        >
          {{ notificationForm.get('message').value.length || 0 }} / 140
        </span>
      </div>
    </div>
    <!-- platform selection -->
    <div>
      <div class="mt-6 mb-3">
        <label>{{ 'notification-form.os' | translate }}</label>
      </div>
      <div
        class="flex w-full platform-buttons"
        [ngClass]="{
          'alert-border invalid-platform': notificationForm.get('platform').invalid
        }"
      >
        <!-- iOS button -->
        <button
          *ngIf="isIphoneReady; else notIphoneReady"
          [ngClass]="{
            'invalid-platform': notificationForm.get('platform').invalid,
            'selected-platform': (notificationForm.get('platform').value || []).includes(
              'iphone'
            )
          }"
          (click)="togglePlatform('iphone')"
        >
          iOS
        </button>
        <ng-template #notIphoneReady>
          <button
            rkTooltip
            [tooltipDescription]="this.iOSTooltipMessage | translate"
            [tooltipPosition]="'top'"
            [arrowPosition]="'right'"
            class="unavailable-platform"
          >
            <fa-icon [icon]="'lock'" class="text-sm mr-1"></fa-icon>iOS
          </button>
        </ng-template>
        <!-- Android button -->
        <button
          *ngIf="isAndroidReady; else notAndroidReady"
          [ngClass]="{
            'invalid-platform': notificationForm.get('platform').invalid,
            'selected-platform': (notificationForm.get('platform').value || []).includes(
              'android'
            )
          }"
          (click)="togglePlatform('android')"
        >
          Android
        </button>
        <ng-template #notAndroidReady>
          <button
            rkTooltip
            [tooltipDescription]="
              'notification-form.tooltip-unavailable-android' | translate
            "
            [tooltipPosition]="'top'"
            [arrowPosition]="'right'"
            class="unavailable-platform"
          >
            <fa-icon [icon]="'lock'" class="text-sm mr-1"></fa-icon>Android
          </button>
        </ng-template>
      </div>
      <!-- Error message -->
      <div
        class="form-alert alert-text mt-1"
        [ngClass]="{
          'show-form-alert': notificationForm.get('platform').invalid
        }"
      >
        <fa-icon [icon]="'warning'"></fa-icon>
        {{ 'notification-form.platform-error' | translate }}
      </div>
    </div>
    <!-- language selection -->
    <div>
      <div class="flex justify-between items-center mt-6 mb-3">
        <div>
          <label>{{ 'notification-form.language' | translate }}</label>
        </div>
        <rk-switch
          [isChecked]="showLanguagePicker"
          (toggle)="onLanguageToggle($event)"
        ></rk-switch>
      </div>
      <div [hidden]="!showLanguagePicker">
        <div class="language-selector-container">
          <rk-autocomplete-input
            [options]="languageList"
            [propertyExtractors]="propertyExtractors"
            (optionSelected)="onLanguageSelected($event)"
            class="w-full"
          ></rk-autocomplete-input>
        </div>
        <ul class="flex flex-wrap">
          <li *ngFor="let langControl of languages.controls; index as i">
            {{ langControl.value.name[1] }}
            <fa-icon [icon]="'close'" (click)="removeLanguage(i)"></fa-icon>
          </li>
        </ul>
      </div>
    </div>
    <!-- location selection -->
    <div>
      <div class="mt-6 mb-3">
        <div class="flex justify-between items-center">
          <div class="">
            <label>{{ 'notification-form.location' | translate }}</label>
          </div>

          <rk-switch
            [isChecked]="showLocationPicker"
            (toggle)="onLocationToggle($event)"
          ></rk-switch>
        </div>
        <div class="text-[#676767]">
          {{ 'notification-form.location-search' | translate }}
        </div>
      </div>

      <div
        class="mt-[15px]"
        [ngClass]="{ hide: !showLocationPicker }"
        *ngIf="apiLoaded$ | async"
      >
        <div class="relative flex items-center location-input">
          <fa-icon
            [icon]="'location-dot'"
            class="absolute left-[16px] text-[#CDCDCD]"
          ></fa-icon>
          <input
            matInput
            class="w-full"
            type="text"
            [formControl]="locationSearchControl"
            #locationSearch
            (keydown.enter)="$event.preventDefault()"
            placeholder="{{ 'notification-form.location-placeholder' | translate }}"
          />
        </div>

        <ul class="flex flex-wrap">
          <li *ngFor="let locationControl of locations.controls; index as i">
            {{ locationControl.value.name }}
            <fa-icon [icon]="'close'" (click)="removeLocation(i)"></fa-icon>
          </li>
        </ul>
      </div>
    </div>
    <!-- date and time selection -->
    <div>
      <div class="flex justify-between items-center mt-6 mb-3">
        <div class="label-wrapper">
          <label>{{ 'notification-form.schedule' | translate }}</label>
        </div>
        <rk-switch
          [isChecked]="showDatePicker"
          (toggle)="onSchedulingToggle($event)"
        ></rk-switch>
      </div>
      <div [ngClass]="{ hide: !showDatePicker }" class="date-time-selector-container">
        <rk-date-picker formControlName="send_at"></rk-date-picker>
      </div>
    </div>
  </form>

  <!-- submit buttons -->
  <div class="flex justify-end submit-buttons z-10 py-4 px-6 rounded-xl">
    <rk-button
      [variant]="'secondary'"
      [text]="'notification-form.cancel' | translate"
      (buttonClick)="closeForm()"
    ></rk-button>

    <rk-button
      type="submit"
      (buttonClick)="submitNotification()"
      [text]="
        showDatePicker
          ? ('notification-form.schedule' | translate)
          : ('notification-form.send' | translate)
      "
      [disabled]="
        notificationForm.invalid ||
        (isSubmitting$ | async) ||
        (currentApp$ | async) === null
      "
      [isLoading]="isSubmitting$ | async"
    >
    </rk-button>
  </div>
</div>
