import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'rk-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
})
export class AutocompleteInputComponent implements OnInit {
  @Input() options: any[];
  @Input() propertyExtractors: ((option: any) => string)[];
  @Output() readonly optionSelected = new EventEmitter<any>();

  optionsCtrl = new FormControl();
  filteredOptions$: Observable<any[]>;

  ngOnInit() {
    this.filteredOptions$ = this.optionsCtrl.valueChanges.pipe(
      // ensures that the Observable emits an initial value
      startWith(''),
      map(option => this._filterOptions(option)),
    );
  }

  private _filterOptions(value: string): string[] {
    if (!value) {
      return this.options || [];
    }
    const filterValue = value.toString().toLowerCase();

    return (
      this.options?.filter(option =>
        this.propertyExtractors.some(extractProperty =>
          extractProperty(option)?.toLowerCase().includes(filterValue),
        ),
      ) || []
    );
  }

  displayFn(option: any): string {
    return option && option.name && option.name[1] ? option.name[1] : '';
  }

  onSelectionChange(event: MatAutocompleteSelectedEvent) {
    this.optionSelected.emit(event.option.value);
  }
}
