import { Component } from '@angular/core';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'rk-page-name-error',
  templateUrl: './page-name-error.component.html',
  styleUrl: './page-name-error.component.scss',
})
export class PageNameErrorComponent {
  @Select(AppConfigurationState.pageNameError)
  pageNameError$: Observable<boolean>;

}
