import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AddConfigAsset,
  RemoveImage,
  UpdateAppConfig,
} from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import {
  ImageUploadComponent
} from '@app/ui/components/image-upload/image-upload.component';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, filter, first } from 'rxjs';

@Component({
  selector: 'rk-menu-design',
  templateUrl: './menu-design.component.html',
  styleUrls: ['./menu-design.component.scss'],
})
export class MenuDesignComponent implements OnInit, OnDestroy {
  @Select(AppConfigurationState.currentAppConfigMenuTextColor)
  currentAppConfigMenuTextColor$: Observable<string>;

  @Select(AppConfigurationState.currentAppConfigMenuBackgroundColor)
  currentAppConfigMenuBackgroundColor$: Observable<string>;

  @Select(AppConfigurationState.currentAppConfigMenuLogo)
  currentAppConfigMenuLogo$: Observable<string>;

  @ViewChild('backgroundImageUploadComponent')
  menuLogoUploadComponent: ImageUploadComponent;

  private readonly subscriptions: Subscription = new Subscription();

  showLogo = false;

  constructor(private readonly store: Store) {}

  ngOnInit() {
    this.subscriptions.add(
      this.currentAppConfigMenuLogo$
        .pipe(
          filter(logoUrl => logoUrl !== undefined),
          first(),
        )
        .subscribe((logoUrl: string) => {
          if (logoUrl) {
            this.showLogo = true;
          }
        }),
    );
  }

  onLogoToggle(event: boolean) {
    this.showLogo = event;
    if (!event) {
      this.removeImage()
    }
  }

  onMenuTextColorChange(color: string) {
    this.store.dispatch(new UpdateAppConfig(['menuColor'], color));
  }

  onMenuBackgroundColorChange(color: string) {
    this.store.dispatch(new UpdateAppConfig(['menuBackgroundColor'], color));
  }

  onImageSelected(event: any) {
    const image = event.file;
    const displayImageUrl = event.imageUrl;
    this.store.dispatch(new AddConfigAsset('logoappfield', image, displayImageUrl));
  }

  removeImage() {
    this.store.dispatch(new RemoveImage('logoappfield'));
    this.menuLogoUploadComponent?.resetFile();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
