import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { Actions, Select, Store, ofActionSuccessful } from '@ngxs/store';
import { Observable, Subscription, switchMap, tap, timer } from 'rxjs';
import { AdminApp } from '@app/core/models/app.model';
import {
  ArchiveUploadSuccess,
  DownloadAndroidArchive,
  DownloadAppleArchive,
  PostAndroidArchive,
  PostAppleArchive,
} from '../../states/admin.actions';
import { AdminState } from '../../states/admin.state';

@Component({
  selector: 'rk-app-generation-files',
  templateUrl: './app-generation-files.component.html',
  styleUrls: ['./app-generation-files.component.scss'],
})
export class AppGenerationFilesComponent implements OnDestroy {
  @Select(AdminState.isUploading)
  isUploading$: Observable<boolean>;

  isUploaded = false;
  platform: 'android' | 'apple';
  faAndroid = faAndroid;
  faApple = faApple;

  private readonly subscription: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<AppGenerationFilesComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { app: AdminApp; platform: 'android' | 'apple' },
    private readonly store: Store,
    private readonly actions$: Actions,
  ) {
    this.platform = data.platform;
    this.subscription.add(
      this.actions$
        .pipe(
          ofActionSuccessful(ArchiveUploadSuccess),
          tap(() => {
            this.isUploaded = true;
          }),
          switchMap(() => timer(3000)),
          tap(() => {
            this.isUploaded = false;
          }),
        )
        .subscribe(),
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onDownloadFiles(): void {
    if (this.platform === 'android') {
      this.store.dispatch(new DownloadAndroidArchive(this.data.app));
    } else {
      this.store.dispatch(new DownloadAppleArchive(this.data.app));
    }
  }

  handleFileInput(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      if (this.platform === 'android') {
        this.store.dispatch(new PostAndroidArchive(this.data.app.appId, file));
      } else {
        this.store.dispatch(new PostAppleArchive(this.data.app.appId, file));
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
