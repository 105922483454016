import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rk-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent {
  @Input() isChecked = false;
  @Input() isDisabled = false;
  @Output() readonly toggle = new EventEmitter<boolean>();

  onToggle() {
    if (this.isDisabled) {
      return;
    }
    this.isChecked = !this.isChecked;
    this.toggle.emit(this.isChecked);
  }
}
