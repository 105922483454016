import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthState } from '@app/core/auth/states/auth.state';
import { Select, Store } from '@ngxs/store';
import Cookies from 'js-cookie';
import { BehaviorSubject, Observable, take, throwError } from 'rxjs';
import { catchError, filter, finalize, mergeMap, switchMap } from 'rxjs/operators';
import { RedirectToLogin } from '../auth/states/auth.actions';
import { environment } from './../../../environments/environment';
import { AuthService, TokenItem } from './../auth/auth.service';

@Injectable()
export class BearerInterceptor implements HttpInterceptor {
  @Select(AuthState.getAccessToken)
  token$: Observable<string>;

  private isRefreshingToken = false;
  private readonly tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    null,
  );

  constructor(
    private readonly store: Store,
    private readonly injector: Injector,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (
      request.url &&
      (request.url.startsWith('assets/') ||
        request.url.indexOf('https://maps.googleapis') > -1)
    ) {
      return next.handle(request);
    }

    return this.token$.pipe(
      take(1),
      mergeMap(() => {
        const token = Cookies.get(environment.cookies.tokenKey) || '';
        if (!token) {
          return next.handle(request);
        }

        return next.handle(this.addToken(request, token));
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            if (
              !error.url.endsWith('oauth/refresh') &&
              !error.url.endsWith('oauth/logout')
            ) {
              return this.handle401Error(request, next);
            } else if (error.url.endsWith('oauth/logout')) {
              return this.store.dispatch(new RedirectToLogin());
            } else {
              const authService = this.injector.get(AuthService);

              return authService.logoutFromInterceptor();
            }
          }
        }

        return throwError(error);
      }),
    );
  }

  private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    if (req.url.endsWith('oauth/refresh')) {
      return req;
    }

    return req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }

  private handle401Error(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      const refreshToken = Cookies.get(environment.cookies.refreshTokenKey);
      const authService = this.injector.get(AuthService);

      // If we don't have a refresh token, we are in trouble so logout.
      if (!refreshToken) {
        this.isRefreshingToken = false;

        return authService.logoutFromInterceptor();
      }

      return authService.refreshTokenWithDispatch(refreshToken).pipe(
        switchMap((newToken: TokenItem) => {
          if (newToken && newToken.access_token) {
            this.tokenSubject.next(newToken.access_token);

            return next.handle(this.addToken(req, newToken.access_token));
          }

          // If we don't get a new token, we are in trouble so logout.
          return authService.logoutFromInterceptor();
        }),
        catchError(error => {
          // test if error is only 401
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 401:
                return authService
                  .logoutFromInterceptor()
                  .pipe(switchMap(() => throwError(new Error('Loggout'))));
            }
          }

          return throwError(error);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }),
      );
      /*
        .catch(error => {
          // If there is an exception calling 'refreshToken', bad news so logout.
          return this.authService.logoutFromInterceptor();
        })
        .finally(() => {
          this.isRefreshingToken = false;
        });
        */
    }

    return this.tokenSubject.pipe(
      filter(token => !!token),
      take(1),
      switchMap(token => next.handle(this.addToken(req, token))),
    );
  }
}
