<div class="dropdown-container" #dropdownContainer [class.full-width]="fullWidth">
  <div
    class="dropdown-button"
    [class.hover-enabled]="options && options.length > 1"
    (click)="toggleDropdown()"
    [ngClass]="{
      open: isOpen,
      disabled: isDisabled,
      'white-background': whiteBackground
    }"
  >
    <div class="label">{{ selectedOption?.label || placeholder || '' }}</div>
    <fa-icon
      *ngIf="options && options.length > 1"
      [icon]="'chevron-down'"
      class="arrow"
    ></fa-icon>
  </div>
  <div class="dropdown-options" [ngClass]="{ 'dropdown-options-show': isOpen }">
    <ng-container *ngFor="let option of options || []">
      <div
        class="dropdown-option"
        [ngClass]="{
          active: selectedOption?.value === option.value,
          disabled: option.disabled
        }"
        (click)="onOptionSelected(option)"
      >
        {{ option.label }}
      </div>
    </ng-container>
  </div>
</div>
