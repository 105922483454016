import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'rk-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormFieldComponent {

}
