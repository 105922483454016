import { trigger, transition, style, animate, state } from '@angular/animations';

export const fadeInOutAnimation = trigger('fadeInOut', [
  transition(':enter', [style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))]),
]);

export const quickFadeInOutAnimation = trigger('quickFadeInOut', [
  transition(':enter', [style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('0.15s', style({ opacity: 0 }))]),
]);


export const slideInOutAnimation = trigger('slideInOut', [
  state(
    'void',
    style({
      width: '0',
      overflow: 'hidden',
    }),
  ),
  state(
    '*',
    style({
      width: '400px',
    }),
  ),
  transition(':enter', [animate('0.4s 0.05s ease-in-out')]),
  transition(':leave', [animate('0.6s 0.05s ease-in-out')]),
]);
