export interface NotificationApiResponse {
  current_page: number;
  data: NotificationApi[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: Link[];
  next_page_url: string | null;
  path: string;
  per_page: string;
  prev_page_url: string | null;
  to: number;
  total: number;
}

export interface NotificationApi {
  id: number;
  idapplication: number;
  created_at: string;
  updated_at: string;
  message: string;
  send_at: string;
  enabled: boolean;
  waiting: boolean;
  processes: ProcessApi[];
  criterias: CriteriaApi[];
  subscriber_count: SubscriberCount;
}

export interface Notification {
  id: number;
  appId: number;
  createdAt: Date;
  updatedAt: Date;
  message: string;
  sendAt: Date;
  enabled: boolean;
  waiting: boolean;
  processes: Process[];
  criterias: Criteria[];
  subscriberCount: SubscriberCount;
}

export interface Process {
  idProcess: number;
  notificationId: number;
  createdAt: Date;
  endedAt: Date;
  status: string;
  platform: string;
  total: number;
  nbSent: number;
}

export interface Criteria {
  notificationId?: number;
  column: string;
  comparison: string;
  terms: string;
}

interface Link {
  url: string | null;
  label: string;
  active: boolean;
}

interface ProcessApi {
  idprocess: number;
  idnotification: number;
  created_at: string;
  ended_at: string;
  status: string;
  platform: string;
  total: number;
  nb_sent: number;
}

interface CriteriaApi {
  idnotification?: number;
  column: string;
  comparison: string;
  terms: string;
}

export interface SubscriberCount {
  iphone: number;
  android: number;
  total: number;
}

export const transformNotificationApi = (
  apiNotification: NotificationApi,
): Notification => ({
  id: apiNotification.id,
  appId: apiNotification.idapplication,
  createdAt: new Date(apiNotification.created_at),
  updatedAt: new Date(apiNotification.updated_at),
  message: apiNotification.message,
  sendAt: new Date(apiNotification.send_at),
  enabled: apiNotification.enabled,
  waiting: apiNotification.waiting,
  processes: apiNotification.processes.map(transformProcessApi),
  criterias: apiNotification.criterias.map(transformCriteriaApi),
  subscriberCount: apiNotification.subscriber_count,
});

const transformProcessApi = (process: ProcessApi): Process => ({
  idProcess: process.idprocess,
  notificationId: process.idnotification,
  createdAt: new Date(process.created_at),
  endedAt: new Date(process.ended_at),
  status: process.status,
  platform: process.platform,
  total: process.total,
  nbSent: process.nb_sent,
});

const transformCriteriaApi = (criteria: CriteriaApi): Criteria => ({
  notificationId: criteria.idnotification,
  column: criteria.column,
  comparison: criteria.comparison,
  terms: criteria.terms,
});

export const transformNotification = (
  notification: Partial<Notification>,
): Partial<NotificationApi> => ({
  id: notification.id,
  idapplication: notification.appId,
  created_at: notification.createdAt?.toISOString(),
  updated_at: notification.updatedAt?.toISOString(),
  message: notification.message,
  send_at: notification.sendAt?.toISOString(),
  enabled: notification.enabled,
  waiting: notification.waiting,
  processes: notification.processes?.map(transformProcess),
  criterias: notification.criterias?.map(transformCriteria),
  subscriber_count: notification.subscriberCount,
});

const transformProcess = (process: Process): ProcessApi => ({
  idprocess: process.idProcess,
  idnotification: process.notificationId,
  created_at: process.createdAt?.toISOString(),
  ended_at: process.endedAt?.toISOString(),
  status: process.status,
  platform: process.platform,
  total: process.total,
  nb_sent: process.nbSent,
});

const transformCriteria = (criteria: Criteria): CriteriaApi => ({
  idnotification: criteria.notificationId,
  column: criteria.column,
  comparison: criteria.comparison,
  terms: criteria.terms,
});
