<div class="mb-2 opacity-70">
  {{ 'general-configuration.rounded-borders' | translate }}
</div>
<div class="flex gap-4">

  <!-- square -->
  <div
    [class.selected]="(divRadius$ | async) === 0"
    class="design-option-container"
    (click)="onDivRadiusSelection(0)"
  >
    <div
      class="size-[20px] border-t-[3px] border-l-[3px] design-option-item border-radius-selector"
    ></div>
  </div>
  <!-- rounded square -->
  <div
    [class.selected]="(divRadius$ | async) === 5"
    class="design-option-container"
    (click)="onDivRadiusSelection(5)"
  >
    <div
      class="size-[20px] border-t-[3px] border-l-[3px] rounded-tl-[5px] design-option-item border-radius-selector"
    ></div>
  </div>
  <!-- rounded square 2 -->
  <div
    [class.selected]="(divRadius$ | async) === 13"
    class="design-option-container"
    (click)="onDivRadiusSelection(13)"
  >
    <div
      class="size-[20px] border-t-[3px] border-l-[3px] rounded-tl-[13px] design-option-item border-radius-selector"
    ></div>
  </div>
  <!-- rounded -->
  <div
    [class.selected]="(divRadius$ | async) === 35"
    class="design-option-container"
    (click)="onDivRadiusSelection(35)"
  >
    <div
      class="size-[20px] border-t-[3px] border-l-[3px] rounded-tl-[35px] design-option-item border-radius-selector"
    ></div>
  </div>
</div>
