import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  Notification,
  NotificationApiResponse,
  transformNotification,
} from '../models/notification.model';

const NOTIFICATION_URLS = {
  getNotifications: (id: number, page: number) =>
    `${environment.urls.MOBILE_API}/app/${id}/notification?limit=20&sort=-send_at&page=${page}`,
  sendNotification: (id: number) =>
    `${environment.urls.MOBILE_API}/app/${id}/notification`,
  updateNotification: (appId: number, notificationId: number) =>
    `${environment.urls.MOBILE_API}/app/${appId}/notification/${notificationId}`,
  deleteNotification: (appId: number, notificationId: number) =>
    `${environment.urls.MOBILE_API}/app/${appId}/notification/${notificationId}`,
};

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private readonly http: HttpClient, private readonly store: Store) {}

  getNotifications(id: number, page: number): Observable<NotificationApiResponse> {
    return this.http.get<NotificationApiResponse>(
      NOTIFICATION_URLS.getNotifications(id, page),
    );
  }

  sendNotification(notification: Partial<Notification>, id: number): Observable<any> {
    return this.http.post(
      NOTIFICATION_URLS.sendNotification(id),
      transformNotification(notification),
    );
  }

  updateNotification(
    appId: number,
    notificationId: number,
    notification: Partial<Notification>,
  ): Observable<any> {
    return this.http.put(
      NOTIFICATION_URLS.updateNotification(appId, notificationId),
      transformNotification(notification),
    );
  }

  deleteNotification(appId: number, notificationId: number): Observable<any> {
    return this.http.delete(NOTIFICATION_URLS.deleteNotification(appId, notificationId));
  }
}
