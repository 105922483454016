import { EventProperties, UserProperties } from './event-tracking.state';

const tag = 'Event tracking';

export class InitEventTracking {
  static readonly type = `${tag} init`;
  constructor(public userProperties: UserProperties) {}
}

export class LogEvent {
  static readonly type = `${tag} log event`;
  constructor(public event: string) {}
}

export class SetEventProperties {
  static readonly type = `${tag} set event properties`;
  constructor(public eventProperties: EventProperties) {}
}

export class LogUserProperty {
  static readonly type = `${tag} log user properties`;
  constructor(
    public property: string,
    public value: string,
  ) {}
}
