import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { AuthState } from '@app/core/auth/states/auth.state';
import { Select, Store } from '@ngxs/store';
import { whmcsCurrency } from '@app/core';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';

@Component({
  selector: 'rk-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
})
export class PlanComponent implements OnInit {
  @Select(AuthState.userCurrency)
  currency$: Observable<whmcsCurrency>;

  billing: string;

  constructor(private readonly store: Store) {}

  ngOnInit() {
    this.billing = 'Monthly';

    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: 'PageSimulatorPricing',
        component: 'Page',
      }),
    );
    this.store.dispatch(new LogEvent('Page Viewed'));
  }

  changeBilling(e: MatButtonToggleChange) {
    this.billing = e.value;

    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: 'ButtonPricing' + this.billing,
        component: 'Button',
      }),
    );
    this.store.dispatch(new LogEvent('Button Clicked'));
  }
}
