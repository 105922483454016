<div class="flex gap-7">
  <!-- image container -->
  <div
    [style.aspect-ratio]="containerAspectRatio ? containerAspectRatio : aspectRatio"
    [style.width]="width ? width : '100%'"
    class="flex justify-center items-center h-full temp-main-container relative"
  >
    <input
      type="file"
      hidden
      #fileInput
      (change)="onFileChange($event)"
      [accept]="accepts"
    />
    <!-- empty and uploading states -->
    <div
      *ngIf="!imageUrl && uploadProgress <= 100; else existingImage"
      class="h-full w-full"
      [style.aspect-ratio]="containerAspectRatio ? containerAspectRatio : aspectRatio"
    >
      <!-- empty -->
      <div
        *ngIf="uploadProgress === 0 && !errorMessage"
        class="file-input-container cursor-pointer"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
        (drop)="onDrop($event)"
        [ngClass]="{ 'dragging-over': draggingOver }"
        (click)="openFileInput($event)"
      >
        <label for="fileInput" class="upload-label cursor-pointer">
          @if (mockImageUrl) {
            <img [src]="mockImageUrl" class="mb-1" />
          } @else {
            <fa-icon [icon]="'file-image'" [style.font-size]="iconSize + 'px'"></fa-icon>
          }
          <div *ngIf="!draggingOver; else draggingOverState" class="upload-text">
            <!-- prettier-ignore -->
            <span class="whitespace-pre-wrap">{{ 'image-input.main-text' | translate }}</span>
            <!-- prettier-ignore -->
            <span class="load-file-link whitespace-pre-wrap" >{{ 'image-input.load' | translate }}</span>
          </div>
          <ng-template #draggingOverState>
            <span class="dragging-over-text">{{
              'image-input.dragging-over-text' | translate
            }}</span>
          </ng-template>
        </label>
      </div>
      <!-- error state -->
      <div
        *ngIf="uploadProgress === 0 && errorMessage"
        class="file-input-container input-error relative"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
        (drop)="onDrop($event)"
        [ngClass]="{ 'dragging-over': draggingOver }"
      >
        <label for="fileInput" class="flex flex-col justify-center items-center gap-2">
          <fa-icon
            *ngIf="!draggingOver"
            class="text-2xl"
            [icon]="'exclamation-triangle'"
          ></fa-icon>
          <div *ngIf="!draggingOver; else draggingOverState" class="upload-text">
            <!-- prettier-ignore -->
            <div class="whitespace-pre-wrap text-error">{{ errorMessage | translate }}</div>
            <!-- prettier-ignore -->
            <span class="load-file-link whitespace-pre-wrap absolute bottom-3" (click)="openFileInput($event)">{{ 'image-input.load' | translate }}</span>
          </div>
          <ng-template #draggingOverState>
            <span class="dragging-over-text">{{
              'image-input.dragging-over-text' | translate
            }}</span>
          </ng-template>
        </label>
      </div>
      <!-- uploading  -->
      <div
        class="upload-progress-container"
        *ngIf="uploadProgress > 0 && uploadProgress < 100"
      >
        <div class="upload-container">
          <circle-progress
            [percent]="uploadProgress"
            [radius]="radius"
            [showTitle]="false"
            [outerStrokeWidth]="strokeWidth"
            [outerStrokeColor]="'#fff'"
            [showInnerStroke]="false"
            [animation]="true"
            [animationDuration]="300"
            [showSubtitle]="false"
            [showUnits]="false"
            [showBackground]="false"
            [startFromZero]="false"
          ></circle-progress>
          <div class="progress-label">{{ uploadProgress }}%</div>
          <button *ngIf="!hideCancelButton" class="cancel-btn" (click)="cancelUpload()">
            {{ 'image-input.cancel' | translate }}
          </button>
        </div>
      </div>
    </div>
    <!-- extisting image state-->
    <ng-template #existingImage>
      <div
        class="image-container relative flex justify-center items-center"
        [class.compact-layout]="compactLayout"
        [style.aspect-ratio]="aspectRatio"
        [class.horizontal]="horizontal"
      >
        <img [src]="imageUrl" alt="Imported image" />
        <!-- buttons visible only if compactLayout is true -->
        <rk-button
          [variant]="'secondary'"
          *ngIf="compactLayout"
          [text]="'create-app-form.change-image' | translate"
          (buttonClick)="openFileInput($event)"
        ></rk-button>
        @if (deleteButton) {
          <span
            class="absolute bottom-4 text-white underline text-center cursor-pointer"
            (click)="removeImage()"
            >{{ 'image-upload.delete' | translate }}</span
          >
        }
      </div>
    </ng-template>
  </div>
  <!-- right section container -->
  <!-- description and technical requirements -->
  <div
    *ngIf="!compactLayout"
    class="flex flex-col justify-between flex-1"
    [class.pt-3]="title === null"
  >
    <div>
      @if (title) {
        <div class="font-bold mb-2">{{ title | translate }}</div>
      }
      @if (description) {
        <div class="mb-4">{{ description | translate }}</div>
      }
      <!-- technical requirements -->
      <label class="font-bold" [class.text-sm]="title !== null">{{
        'image-upload.constraints-title' | translate
      }}</label>
      <!-- prettier-ignore -->
      <p class="opacity-70 text-base constraints-body" [class.text-sm]="title !== null" [class.text-mt-1]="title !== null" >{{ technicalRequirementsText }}</p>
    </div>
    <!-- change and delete button -->
    <div class="edit-buttons mt-2" [class.visible]="uploadProgress >= 100 || imageUrl">
      <rk-button
        [variant]="'secondary'"
        [text]="'image-upload.change-image' | translate"
        (buttonClick)="openFileInput($event)"
      ></rk-button>
      @if (deleteButton) {
        <rk-button
          class="ml-4"
          [variant]="'danger'"
          [icon]="'trash'"
          [iconOnly]="true"
          (buttonClick)="removeImage()"
        ></rk-button>
      }
    </div>
  </div>
</div>
