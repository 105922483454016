import { Component, OnInit } from '@angular/core';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, filter } from 'rxjs';
import { UpdateAppConfig } from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { DropdownOption } from '@app/ui/components/dropdown/dropdown.model';

@Component({
  selector: 'rk-font-family-selector',
  templateUrl: './font-family-selector.component.html',
  styleUrl: './font-family-selector.component.scss',
})
export class FontFamilySelectorComponent implements OnInit {
  fontFamilyOptions: DropdownOption[] = [
    {
      value: 'Quicksand',
      label: 'Quicksand',
      disabled: false,
    },
    {
      value: 'Poppins',
      label: 'Poppins',
      disabled: false,
    },
    {
      value: 'DancingScript',
      label: 'Dancing Script',
      disabled: false,
    },
    {
      value: 'Solway',
      label: 'Solway',
      disabled: false,
    },
    {
      value: 'Oswald',
      label: 'Oswald',
      disabled: false,
    },
    {
      value: 'Raleway',
      label: 'Raleway',
      disabled: false,
    },
    {
      value: 'Roboto',
      label: 'Roboto',
      disabled: false,
    },
    {
      value: 'RobotoMono',
      label: 'Roboto Mono',
      disabled: false,
    },
    {
      value: 'PlayfairDisplay',
      label: 'Playfair Display',
      disabled: false,
    },
    {
      value: 'JosefinSans',
      label: 'Josefin Sans',
      disabled: false,
    },
    {
      value: 'EBGaramond',
      label: 'EB Garamond',
      disabled: false,
    },
    {
      value: 'Comfortaa',
      label: 'Comfortaa',
      disabled: false,
    },
    {
      value: 'Fedroka',
      label: 'Fedroka',
      disabled: false,
    },
  ];

  @Select(AppConfigurationState.fontFamily)
  fontFamily$: Observable<string>;

  selectedFont: DropdownOption;

  private readonly subscriptions = new Subscription();
  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.fontFamily$
        .pipe(filter(font => !!font))
        .subscribe(
          font =>
            (this.selectedFont = this.fontFamilyOptions.find(
              option => option.value === font,
            )),
        ),
    );
  }

  onFontSelectionChange(value: string) {
    this.store.dispatch(new UpdateAppConfig(['onglets', '0', 'fontFamily'], value));
  }
}
