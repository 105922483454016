import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import { TooltipArrowPosition, TooltipPosition } from './tooltip.model';

@Directive({
  selector: '[rkTooltip]',
})
export class TooltipDirective implements OnDestroy {
  @Input() tooltipTitle = '';
  @Input() tooltipDescription = '';
  @Input() tooltipPosition: TooltipPosition = 'top';
  @Input() arrowPosition: TooltipArrowPosition = 'left';
  @Input() customStyle: object = {};
  @Input() tooltipDisabled = false;
  tooltipRef: ComponentRef<TooltipComponent> | null = null;

  constructor(private readonly elRef: ElementRef, private readonly appRef: ApplicationRef) {}

  ngOnDestroy() {
    this.onMouseLeave();
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.tooltipDisabled) {
      return;
    }
    const mTooltip = TooltipComponent;
    this.tooltipRef = createComponent(mTooltip, {
      environmentInjector: this.appRef.injector,
    });
    const { fixedTooltipPosition, fixedArrowPosition } = this.getTooltipScreenPosition();
    this.tooltipRef.instance.setTooltipProperties({
      position: fixedTooltipPosition,
      arrowPosition: fixedArrowPosition,
      title: this.tooltipTitle,
      description: this.tooltipDescription,
      style: {
        ...this.getTooltipStyle(fixedTooltipPosition, fixedArrowPosition),
        ...this.customStyle,
      },
    });

    document.body.appendChild(this.tooltipRef.location.nativeElement);
    this.appRef.attachView(this.tooltipRef.hostView);
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.tooltipRef?.instance.destroyTooltip();
    setTimeout(() => {
      if (this.tooltipRef !== null) {
        this.appRef.detachView(this.tooltipRef.hostView);
        this.tooltipRef.destroy();
        this.tooltipRef = null;
      }
    }, 250);
  }

  getTooltipScreenPosition() {
    const el = this.elRef.nativeElement.getBoundingClientRect();
    let fixedTooltipPosition = this.tooltipPosition;
    let fixedArrowPosition = this.arrowPosition;
    if (this.tooltipPosition === 'top' && el.top < 90) {
      fixedTooltipPosition = 'bottom';
    }
    if (
      this.tooltipPosition === 'bottom' &&
      el.top + el.height + 90 > window.innerHeight
    ) {
      fixedTooltipPosition = 'top';
    }
    if (
      this.arrowPosition === 'left' &&
      window.innerWidth - el.left - el.width / 2 < 400 &&
      window.innerWidth / 2 < el.left + el.width / 2
    ) {
      fixedArrowPosition = 'right';
    }
    if (this.arrowPosition === 'right' && el.left + el.width / 2 < 400) {
      fixedArrowPosition = 'left';
    }

    return { fixedTooltipPosition, fixedArrowPosition };
  }

  getTooltipStyle(
    fixedTooltipPosition: TooltipPosition,
    fixedArrowPosition: TooltipArrowPosition,
  ): object {
    const el = this.elRef.nativeElement.getBoundingClientRect();
    let tooltipStyle: { [k: string]: string } = {};
    if (fixedTooltipPosition === 'bottom' || fixedTooltipPosition === 'top') {
      if (fixedArrowPosition === 'left') {
        tooltipStyle['left'] = `${el.left + el.width / 2 - 14}px`;
      } else {
        tooltipStyle['left'] = `${el.left + el.width / 2}px`;
      }
      if (fixedTooltipPosition === 'top') {
        tooltipStyle['top'] = `${el.top - 14}px`;
      } else if (fixedTooltipPosition === 'bottom') {
        tooltipStyle['top'] = `${el.top + el.height + 14}px`;
      }
    }

    if (fixedTooltipPosition === 'right') {
      tooltipStyle = {
        top: `${el.top + el.height / 2 - 14}px`,
        left: `${el.left + el.width + 10}px`,
      };
    } else if (fixedTooltipPosition === 'left') {
      tooltipStyle = {
        top: `${el.top + el.height / 2 - 14}px`,
        left: `${el.left - 10}px`,
      };
    }

    return tooltipStyle;
  }
}
