import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { whmcsCurrency } from '@app/core';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import { UpdatePlanRequest } from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { planNames } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

export interface Prices {
  [key: string]: {
    [key: string]: {
      [key: number]: string;
    };
  };
}

const prices: Prices = {
  Monthly: {
    EUR: {
      1: '29€',
      2: '54€',
      3: '69€',
    },
    GBP: {
      1: '£24',
      2: '£49',
      3: '£64',
    },
    USD: {
      1: '$35',
      2: '$59',
      3: '$74',
    },
  },
  Annually: {
    EUR: {
      1: '24€',
      2: '44€',
      3: '59€',
    },
    GBP: {
      1: '£20',
      2: '£39',
      3: '£54',
    },
    USD: {
      1: '$29',
      2: '$49',
      3: '$64',
    },
  },
  annualPrices: {
    EUR: {
      1: '288€',
      2: '528€',
      3: '708€',
    },
    GBP: {
      1: '£240',
      2: '£468',
      3: '£648',
    },
    USD: {
      1: '$348',
      2: '$588',
      3: '$768',
    },
  },
};

@Component({
  selector: 'rk-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferComponent {
  @Input() plan: number;

  @Input() planName: string;

  @Input() pushNotifications: string;

  @Input() streamCount: number;

  @Input() currency: whmcsCurrency;

  @Input() monthlyPricing: string;

  @Input() annualPricing: string;

  @Input() billing: string;

  @HostBinding('class.recommended')
  @Input()
  recommended = false;

  @Input() focused$: Observable<void>;

  @Output() readonly clickButton = new EventEmitter();

  constructor(private readonly store: Store) {}

  choosePlan() {
    this.store.dispatch(new UpdatePlanRequest(this.plan, this.billing));
    this.clickButton.emit();
    const planName = planNames.find(plan => plan.id === this.plan).name;

    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: 'ButtonPricingStart' + planName,
        component: 'Button',
      }),
    );
    this.store.dispatch(new LogEvent('Button Clicked'));
  }

  getPricing() {
    if (this.currency) {
      return prices[this.billing][this.currency][this.plan];
    }

    return null;
  }

  getMonthlyPricing() {
    return prices['Monthly'][this.currency][this.plan];
  }
}
