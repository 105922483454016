<div class="p-6">
  <mat-expansion-panel [expanded]="true" hideToggle>
    <mat-expansion-panel-header>
      <div class="flex justify-between items-center w-full" (click)="toggleContent()">
        <div class="flex items-center">
          @if(icon) {
            <fa-icon [icon]="icon" class="text-[15px] mr-3 pb-[2px]"></fa-icon>
          }
          <div class="setting-name">{{ name }}</div>
        </div>

        <fa-icon
          class="text-sm opacity-50"
          [icon]="'chevron-down'"
          [class.rotated]="isContentVisible"
        ></fa-icon>
      </div>
    </mat-expansion-panel-header>
    <ng-content></ng-content>
  </mat-expansion-panel>
</div>
