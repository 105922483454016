<div>
  <rk-form-field class="mt-3 relative">
    <input
      type="text"
      [(ngModel)]="appName"
      (ngModelChange)="validateAppName()"
      (input)="onNameInput($event)"
      #appNameInput="ngModel"
      required
      (blur)="logEvent()"
      maxlength="30"
      placeholder="{{ 'create-app-form.name-placeholder' | translate }}"
      [ngClass]="{ 'form-alert': appNameInput.invalid && appNameInput.touched }"
    />
    <!-- error message -->
    <div class="absolute top-[62px]">
      <div
        #errorDiv
        class="form-error-message"
        [class.visible]="appNameInput.errors?.['required'] && appNameInput.touched"
      >
        {{ 'create-app-form.missing-name' | translate }}
      </div>
      <div
        #errorDiv
        class="form-error-message"
        [class.visible]="
          appNameInput.errors?.['lengthAfterTrim'] &&
          !appNameInput.errors?.['required'] &&
          appNameInput.touched
        "
      >
        {{ 'create-app-form.min-length' | translate }}
      </div>
      <div
        #errorDiv
        class="form-error-message"
        [class.visible]="appNameInput.errors?.['maxlength']"
      >
        {{ 'create-app-form.max-length' | translate }}
      </div>
      <div
        #errorDiv
        class="form-error-message"
        [class.visible]="
          appNameInput.errors?.['noEmoji'] && appNameInput?.value?.length > 2
        "
      >
        {{ 'create-app-form.no-emojis' | translate }}
      </div>
      <div
        #errorDiv
        class="form-error-message"
        [class.visible]="
          appNameInput.errors?.['noSpaceOnly'] && appNameInput?.value?.length > 2
        "
      >
        {{ 'create-app-form.no-spaces-only' | translate }}.
      </div>
      <div
        #errorDiv
        class="form-error-message"
        [class.visible]="
          appNameInput?.value?.length > 2 && (typedAppNameIsAvailable$ | async) === false
        "
      >
        {{ 'create-app-form.unavailable-name' | translate }}
      </div>
    </div>
  </rk-form-field>
</div>
