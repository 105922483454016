import { Component } from '@angular/core';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'rk-page-configuration-container',
  templateUrl: './page-configuration-container.component.html',
  styleUrl: './page-configuration-container.component.scss',
})
export class PageConfigurationContainerComponent {
  @Select(AppConfigurationState.activePageIndex)
  activePageIndex$: Observable<number>;

  selectedSubMenu = 'content';

  onSelectSubmenu(event: string) {
    this.selectedSubMenu = event;
  }
}
