import { Component, OnDestroy, OnInit } from '@angular/core';
import { RadioTabModel } from '@app/features/app-creation-and-configuration/models/app-tabs.model';
import { FormService } from '@app/features/app-creation-and-configuration/services/form.service';
import {
  AddConfigAsset,
  RemoveImage,
  UpdateAppConfig,
} from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Selectedimage } from '@app/ui/components/image-upload/image-upload.component';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, filter, map, pipe } from 'rxjs';

@Component({
  selector: 'rk-player-design',
  templateUrl: './player-design.component.html',
  styleUrl: './player-design.component.scss',
})
export class PlayerDesignComponent implements OnInit, OnDestroy {
  @Select(AppConfigurationState.currentAppConfigPlayerTab)
  currentAppConfigPlayerTab$: Observable<RadioTabModel>;

  @Select(AppConfigurationState.currentAppConfigDefaultCover)
  currentAppConfigDefaultCover$: Observable<RadioTabModel>;

  titlingBackgroundColor$: Observable<string>;
  titleColor$: Observable<string>;
  artistColor$: Observable<string>;
  coverBorderColor$: Observable<string>;
  alwaysDefaultCover: boolean;
  coverType: string;
  showCoverBorder: boolean;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly formService: FormService,
  ) {}

  ngOnInit(): void {
    // set artist color
    this.artistColor$ = this.currentAppConfigPlayerTab$.pipe(
      filter(tab => !!tab),
      map(tab => (tab.artistLabelColor ? tab.artistLabelColor : null)),
    );

    // set title color
    this.titleColor$ = this.currentAppConfigPlayerTab$.pipe(
      filter(tab => !!tab),
      map(tab => (tab.titleLabelColor ? tab.titleLabelColor : null)),
    );

    // set titling background color
    this.titlingBackgroundColor$ = this.currentAppConfigPlayerTab$.pipe(
      filter(tab => !!tab),
      map(tab => (tab.bottomColor ? tab.bottomColor : null)),
    );

    // set cover border color
    this.coverBorderColor$ = this.currentAppConfigPlayerTab$.pipe(
      filter(tab => !!tab),
      map(tab => (tab.coverBorderColor ? tab.coverBorderColor : null)),
    );

    // set cover options
    this.subscriptions.add(
      this.currentAppConfigPlayerTab$
        .pipe(pipe(filter(config => !!config)))
        .subscribe(config => {
          this.alwaysDefaultCover = this.formService.setToggleState(
            config.alwaysDefaultCover,
          );
          this.showCoverBorder = this.formService.setToggleState(config.showCoverBorder);
          this.coverType = config.coverType;
        }),
    );
  }

  onImageSelected(event: Selectedimage) {
    const image = event.file;
    const displayImageUrl = event.imageUrl;
    this.store.dispatch(
      new AddConfigAsset('pochettedefaut', image, displayImageUrl.toString()),
    );
  }

  onRemoveImage() {
    this.store.dispatch(new RemoveImage('pochettedefaut'));
  }

  onTitlingBackgroundColorChange(color: string) {
    this.store.dispatch(new UpdateAppConfig(['onglets', '0', 'bottomColor'], color));
    this.store.dispatch(new UpdateAppConfig(['onglets', '0', 'fondTitrage'], 'YES'));
  }

  onTitleColorChange(color: string) {
    this.store.dispatch(new UpdateAppConfig(['onglets', '0', 'titleLabelColor'], color));
  }

  onArtistColorChange(color: string) {
    this.store.dispatch(new UpdateAppConfig(['onglets', '0', 'artistLabelColor'], color));
  }

  onCoverBorderColorChange(color: string) {
    this.store.dispatch(new UpdateAppConfig(['onglets', '0', 'coverBorderColor'], color));
  }

  onAlwaysShowDefaultCoverChange(event: boolean) {
    const configPath = ['onglets', '0', 'alwaysDefaultCover'];
    this.formService.onToggleChange(event, configPath);
    this.alwaysDefaultCover = event;
  }

  onShowCoverBorderChange(event: boolean) {
    const configPath = ['onglets', '0', 'showCoverBorder'];
    this.formService.onToggleChange(event, configPath);
    this.showCoverBorder = event;
  }

  selectCovertype(type: string) {
    this.store.dispatch(new UpdateAppConfig(['onglets', '0', 'coverType'], type));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
