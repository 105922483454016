import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'rk-color-selector',
  templateUrl: './color-selector.component.html',
  styleUrls: ['./color-selector.component.scss'],
})
export class ColorSelectorComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  parameter?: string;

  @Input()
  opacityEnabled = false;

  @Input()
  initialColor: string;

  @Input()
  supportsTransparentColor = false;

  @Output()
  readonly colorChange = new EventEmitter<string>();

  colors = ['#FFFFFF', '#252525', '#FF9150', '#FF4757', '#2ED573', '#1E90FF'];
  showColorPicker = false;
  allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', 'Enter'];

  private readonly subscriptions: Subscription = new Subscription();

  toggleColorPicker() {
    this.showColorPicker = !this.showColorPicker;
  }

  hexColorControl = new FormControl('');
  opacityControl = new FormControl('');

  get isHexValid(): boolean {
    return this.hexColorControl.valid;
  }

  ngOnInit(): void {
    // adds # character and emits the value to the parent
    this.subscriptions.add(
      this.hexColorControl.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(value => {
          let color = value;
          if (value !== '') {
            color = '#' + value;
          }
          this.colorChange.emit(color);
        }),
    );

    // validators
    if (this.opacityEnabled) {
      this.hexColorControl.setValidators([
        Validators.pattern(/^([0-9a-fA-F]{6}([0-9a-fA-F]{2})?)?$/),
      ]);
      this.opacityControl.setValidators([
        Validators.pattern(/^\d+$/),
        Validators.min(0),
        Validators.max(100),
      ]);
    } else {
      this.hexColorControl.setValidators([
        Validators.required,
        Validators.pattern(/^[0-9a-fA-F]{6}$/), // 6-digit hex pattern for no opacity
      ]);
    }

    // sets initial color
    if (this.initialColor === '#00000000') {
      this.opacityControl.setValue('');
      this.hexColorControl.setValue('');
    } else {
      if (this.opacityEnabled && this.initialColor.length === 9) {
        this.hexColorControl.setValue(this.initialColor.replace('#', ''));
        const hexOpacity = this.initialColor.substring(7, 9);
        const opacityPercentage = this.hexToPercentage(hexOpacity);
        this.opacityControl.setValue(opacityPercentage);
      } else if (this.opacityEnabled && this.initialColor.length < 9) {
        this.hexColorControl.setValue(this.initialColor.replace('#', ''));
        this.opacityControl.setValue('100');
      } else {
        this.hexColorControl.setValue(this.initialColor.replace('#', ''));
      }
    }

    // reacts to opacity changes
    this.subscriptions.add(
      this.opacityControl.valueChanges
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe(opacityPercentage => {
          const opacityHex = this.percentageToHex(opacityPercentage);
          const currentHex = this.hexColorControl.value;
          if (this.opacityControl.valid) {
            if (currentHex && currentHex.length === 6 && opacityHex) {
              this.hexColorControl.setValue(currentHex + opacityHex);
            } else if (currentHex && currentHex.length === 8) {
              this.hexColorControl.setValue(currentHex.substring(0, 6) + opacityHex);
            }
          }
        }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['initialColor']) {
      if (this.opacityEnabled && changes['initialColor'].currentValue.length === 9) {
        this.hexColorControl.setValue(
          changes['initialColor'].currentValue.replace('#', ''),
        );
        const hexOpacity = changes['initialColor'].currentValue.substring(7, 9);
        const opacityPercentage = this.hexToPercentage(hexOpacity);
        this.opacityControl.setValue(opacityPercentage);
      } else {
        this.hexColorControl.setValue(
          changes['initialColor'].currentValue.replace('#', ''),
        );
      }
    }
  }

  selectColor(color: string): void {
    this.hexColorControl.setValue(color?.replace('#', ''));
  }

  onColorPickerChange(color: string): void {
    this.hexColorControl.setValue(color?.replace('#', ''));
  }

  resetColor(): void {
    this.hexColorControl.setValue('');
    this.opacityControl.setValue('')
  }

  get colorPickerCurrentColor(): string {
    const currentColor = '#' + this.hexColorControl.value.toUpperCase();

    // checks if the currentColor is not included in the predefined colors or empty
    if (!this.colors.includes(currentColor) && this.hexColorControl.valid && currentColor !== '#') {
      return currentColor;
    }

    return 'transparent';
  }

  validateOpacityInput(event: KeyboardEvent): void {
    const numericPattern = /^[0-9]$/;

    if (!this.allowedKeys.includes(event.key) && !numericPattern.test(event.key)) {
      event.preventDefault();
    }
  }

  validateHexInput(event: KeyboardEvent): void {
    const hexPattern = /^[0-9a-fA-F]+$/;

    if (!this.allowedKeys.includes(event.key) && !hexPattern.test(event.key)) {
      event.preventDefault();
    }
  }

  private percentageToHex(opacityPercentage: string): string {
    const percentage = Number(opacityPercentage);
    const hexValue = Math.round((percentage / 100) * 255);

    return hexValue.toString(16).padStart(2, '0');
  }

  private hexToPercentage(hexOpacity: string): string {
    const opacity = parseInt(hexOpacity, 16);

    return isNaN(opacity) ? '' : String(Math.round((opacity / 255) * 100));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
