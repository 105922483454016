<div *ngIf="alertVisible" [@alertOverlay] class="overlay rounded">
  <div [@alertContent] *ngIf="containerVisible" class="container flex justify-center items-center p-4">
    <fa-icon
      *ngIf="alertPhase === 'icon'"
      [icon]="alertIcon"
      [@alertContent]
    ></fa-icon>
    <div
      class="flex flex-col justify-center items-center"
      *ngIf="alertPhase === 'message'"
      [@alertContent]
    >
      <fa-icon [icon]="'check'"></fa-icon>
      <p class="text-center">{{ alertText }}</p>
    </div>
  </div>
</div>
