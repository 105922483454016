import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ImageUploadComponent } from '@app/ui/components/image-upload/image-upload.component';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, combineLatest, filter, first } from 'rxjs';
import { DropdownOption } from '@app/ui/components/dropdown/dropdown.model';
import {
  AddConfigAsset,
  RemoveImage,
  SetBackgroundType,
  UpdateAppConfig,
} from '../../../states/app-configuration.actions';
import { AppConfigurationState } from '../../../states/app-configuration.state';

@Component({
  selector: 'rk-general-configuration',
  templateUrl: './general-configuration.component.html',
  styleUrls: ['./general-configuration.component.scss'],
})
export class GeneralConfigurationComponent implements OnInit, OnDestroy {
  @Select(AppConfigurationState.currentAppConfigLanguage)
  currentAppConfigLanguage$: Observable<string>;

  @Select(AppConfigurationState.currentAppConfigHeaderTextColor)
  currentAppConfigHeaderTextColor$: Observable<string>;

  @Select(AppConfigurationState.currentAppConfigBackgroundImage)
  currentAppConfigBackgroundImage$: Observable<string>;

  @Select(AppConfigurationState.currentAppConfigBackgroundImageLong)
  currentAppConfigBackgroundImageLong$: Observable<string>;

  @Select(AppConfigurationState.currentAppConfigShowBlurredCover)
  currentAppConfigShowBlurredCover$: Observable<boolean>;

  @Select(AppConfigurationState.appBackgroundIsBlurred)
  appBackgroundIsBlurred$: Observable<boolean>;

  @Select(AppConfigurationState.backgroundType)
  backgroundType$: Observable<string>;


  private readonly subscriptions: Subscription = new Subscription();

  @ViewChild('backgroundImageUploadComponent')
  backgroundImageUploadComponent: ImageUploadComponent;

  @ViewChild('backgroundImageLongUploadComponent')
  backgroundImageLongUploadComponent: ImageUploadComponent;

  selectedLanguage: DropdownOption;
  backgroundColor$: Observable<string>;
  backgroundType = 'color';
  showImageUpload = true;

  constructor(private readonly store: Store) {}

  languageOptions: DropdownOption[] = [
    {
      value: 'en',
      label: '🇬🇧 English',
      disabled: false,
    },
    {
      value: 'fr',
      label: '🇫🇷 Français',
      disabled: false,
    },
    {
      value: 'de',
      label: '🇩🇪 Deutsch',
      disabled: false,
    },
    {
      value: 'es',
      label: '🇪🇸 Español',
      disabled: false,
    },
    {
      value: 'it',
      label: '🇮🇹 Italiano',
      disabled: false,
    },
    {
      value: 'pt',
      label: '🇵🇹 Português',
      disabled: false,
    },
  ];

  ngOnInit() {
    // sets current language
    this.subscriptions.add(
      this.currentAppConfigLanguage$.subscribe((language: string) => {
        if (language) {
          this.selectedLanguage =
            this.languageOptions.find(option => option.value === language) ||
            this.languageOptions[0];
        }
      }),
    );

    // sets background type selector
    this.subscriptions.add(
      combineLatest([
        this.currentAppConfigShowBlurredCover$,
        this.currentAppConfigBackgroundImage$,
        this.currentAppConfigBackgroundImageLong$,
      ])
        .pipe(
          filter(
            ([showBlurredCover, backgroundImage, backgroundImageLong]) =>
              showBlurredCover !== undefined &&
              backgroundImage !== undefined &&
              backgroundImageLong !== undefined,
          ),
          first(),
        )
        .subscribe(([showBlurredCover, backgroundImage, backgroundImageLong]) => {
          if (showBlurredCover) {
            this.store.dispatch(new SetBackgroundType('cover'));
          } else if (backgroundImage || backgroundImageLong) {
            this.store.dispatch(new SetBackgroundType('image'));
          } else {
            this.store.dispatch(new SetBackgroundType('color'));
          }
        }),
    );
  }

  onBackgroundTypeChange(event: MatButtonToggleChange) {
    this.store.dispatch(new SetBackgroundType(event.value));
    if (event.value === 'cover') {
      this.store.dispatch(
        new UpdateAppConfig(['onglets', '0', 'showBlurredCover'], 'YES'),
      );
      this.resetImages();
    } else if (event.value === 'image') {
      this.store.dispatch(
        new UpdateAppConfig(['onglets', '0', 'showBlurredCover'], 'NO'),
      );
    } else {
      this.store.dispatch(
        new UpdateAppConfig(['onglets', '0', 'showBlurredCover'], 'NO'),
      );
      this.resetImages();
    }
  }

  onLanguageSelectionChange(selectedLanguage: string) {
    this.store.dispatch(new UpdateAppConfig(['lang'], selectedLanguage));
  }

  onColorChange(color: string, key: string) {
    this.store.dispatch(new UpdateAppConfig(['onglets', '0', key], color));
  }

  onBackgroundImageSelected(event: any) {
    const image = event.file;
    const displayImageUrl = event.imageUrl;
    this.store.dispatch(new AddConfigAsset('backgroundappfield', image, displayImageUrl));
  }

  onBackgroundImageLongSelected(event: any) {
    const image = event.file;
    const displayImageUrl = event.imageUrl;
    this.store.dispatch(
      new AddConfigAsset('backgroundiphoneXappfield', image, displayImageUrl),
    );
  }

  onRemoveBackgroundImage(name: string) {
    this.store.dispatch(new RemoveImage(name));
  }

  onBlurToggle(event: boolean) {
    if (event) {
      this.store.dispatch(
        new UpdateAppConfig(['onglets', '0', 'blurredBackground'], 'YES'),
      );
    } else {
      this.store.dispatch(
        new UpdateAppConfig(['onglets', '0', 'blurredBackground'], 'NO'),
      );
    }
  }

  resetImages() {
    this.store.dispatch(new RemoveImage('backgroundappfield'));
    this.store.dispatch(new RemoveImage('backgroundiphoneXappfield'));
    this.backgroundImageUploadComponent?.resetFile();
    this.backgroundImageLongUploadComponent?.resetFile();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
