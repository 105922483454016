import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { emailValidator, phoneNumberValidator } from '@app/core/utils';
import { RadioTabModel } from '@app/features/app-creation-and-configuration/models/app-tabs.model';
import { FormService } from '@app/features/app-creation-and-configuration/services/form.service';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, filter } from 'rxjs';

@Component({
  selector: 'rk-player-buttons',
  templateUrl: './player-buttons.component.html',
  styleUrl: './player-buttons.component.scss',
})
export class PlayerButtonsComponent implements OnInit, OnDestroy {
  @Select(AppConfigurationState.currentAppConfigPlayerTab)
  currentAppConfigPlayerTab$: Observable<RadioTabModel>;

  private readonly subscriptions = new Subscription();

  // form controls
  phoneNumberControl: FormControl;
  smsNumberControl: FormControl;
  emailControl: FormControl;
  appleMusicControl: FormControl;

  // toggle states
  phoneNumberIsActive = false;
  smsNumberIsActive = false;
  sharingIsActive = false;
  emailIsActive = false;
  appleMusicIsActive = false;
  voteIsActive = false;
  autoPlayIsActive = false;
  sleepTimerIsActive = false;

  constructor(
    private readonly store: Store,
    private readonly formService: FormService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.currentAppConfigPlayerTab$
        .pipe(filter(config => !!config))
        .subscribe(config => {
          this.initializeFormControls(config);
        }),
    );
  }

  initializeFormControls(config: RadioTabModel): void {
    // set form controls
    this.phoneNumberControl = this.formService.createFormControl(config.phoneNumber, {
      configPath: ['onglets', '0', 'phoneNumber'],
      validators: [phoneNumberValidator],
    });

    this.smsNumberControl = this.formService.createFormControl(config.smsNumber, {
      configPath: ['onglets', '0', 'smsNumber'],
      validators: [phoneNumberValidator],
    });

    this.emailControl = this.formService.createFormControl(config.emailAddress, {
      configPath: ['onglets', '0', 'emailAddress'],
      validators: [emailValidator],
    });

    this.appleMusicControl = this.formService.createFormControl(config.itunesId, {
      configPath: ['onglets', '0', 'itunesId'],
    });

    // set toggle states
    this.phoneNumberIsActive = this.formService.setToggleState(config.callOption);
    this.smsNumberIsActive = this.formService.setToggleState(config.smsOption);
    this.sharingIsActive = this.formService.setToggleState(config.partage);
    this.emailIsActive = this.formService.setToggleState(config.emailOption);
    this.appleMusicIsActive = this.formService.setToggleState(config.achatOption);
    this.voteIsActive = this.formService.setToggleState(config.voteOption);
    this.autoPlayIsActive = this.formService.setToggleState(config.autoPlay);
    this.sleepTimerIsActive = this.formService.setToggleState(config.sleeptimerOption);
  }

  onToggleChange(event: boolean, toggleName: string, configKey: string): void {
    const configPath = ['onglets', '0', configKey];
    this.formService.onToggleChange(event, configPath);
    this[toggleName] = event;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
