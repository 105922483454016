import { Component } from '@angular/core';
import { StreamApi } from '@app/features/app-creation-and-configuration/models/app-config.model';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'rk-stream-list',
  templateUrl: './stream-list.component.html',
  styleUrl: './stream-list.component.scss'
})
export class StreamListComponent {

  @Select(AppConfigurationState.currentAppConfigStreamList)
  currentAppConfigStreamList$: Observable<StreamApi[]>;

}
