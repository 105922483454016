import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { App } from '@app/core/models/app.model';
import { SetCurrentApp } from '@app/core/states/app.actions';
import { AppState } from '@app/core/states/app.state';
import { CloseNotificationForm } from '@app/features/notifications/states/notifications.actions';
import { DropdownOption } from '@app/ui/components/dropdown/dropdown.model';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, combineLatest } from 'rxjs';

@Component({
  selector: 'rk-list',
  templateUrl: './apps-list.component.html',
  styleUrls: ['./apps-list.component.scss'],
})
export class AppsListComponent implements OnInit, OnDestroy {
  @Select(AppState.apps)
  apps$: Observable<App[]>;
  @Select(AppState.isLoading)
  isLoading$: Observable<boolean>;
  @Select(AppState.currentAppId)
  currentAppId$: Observable<number>;
  @Select(AppState.currentAppIsNotOwnedByUser)
  currentAppIsNotOwnedByUser$: Observable<boolean>;

  appsList: App[];
  appsListDropdown: DropdownOption[];
  selectedApp: DropdownOption;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      combineLatest([this.apps$, this.currentAppId$]).subscribe(
        ([apps, currentAppId]) => {
          this.appsList = apps;
          // fills dropdown with user app list
          this.appsListDropdown =
            apps?.map(app => ({
              value: app.appId.toString(),
              label: app.name,
              disabled: false,
            })) || [];
          // sets selected app in dropdown
          if (currentAppId) {
            this.selectedApp = this.appsListDropdown.find(
              app => app.value === currentAppId.toString(),
            );
          }
        },
      ),
    );
  }

  onDropdownSelectionChange(appId: string): void {
    this.updateRouteIfNeeded(appId);
    this.store.dispatch(new SetCurrentApp(Number(appId)));
    this.store.dispatch(new CloseNotificationForm());
  }

  private updateRouteIfNeeded(newAppId: string): void {
    const currentUrl = this.router.url;
    const regex = /\/(simulator|update)\/\d+/;

    if (regex.test(currentUrl)) {
      const baseRoute = currentUrl.match(/\/(simulator|update)/)[1];
      this.router.navigate([`/${baseRoute}`, newAppId]);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
