import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LANGUAGES } from '@app/core/constants/languages';
import { App } from '@app/core/models/app.model';
import { AppState } from '@app/core/states/app.state';
import { AutocompleteInputComponent } from '@app/ui/components/auto-complete-input/autocomplete-input.component';
import { ConfirmationDialogComponent } from '@app/ui/components/confirmation-dialog/confirmation-dialog.component';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Actions, Select, Store, ofActionErrored, ofActionSuccessful } from '@ngxs/store';
import * as moment from 'moment';
import { Observable, Subscription, catchError, map, of } from 'rxjs';
import { Notification } from '../../models/notification.model';
import {
  CloseNotificationForm,
  ResetCurrentNotification,
  SubmitNotificationError,
  SubmitNotificationRequest,
  SubmitNotificationSuccess,
} from '../../states/notifications.actions';
import { NotificationsState } from '../../states/notifications.state';

interface Language {
  code: string;
  name: string[];
}

interface Location {
  name: string;
  location: string;
}

interface PlaceResult {
  place_id?: string;
  formatted_address?: string;
  geometry?: {
    viewport?: {
      getNorthEast(): { lat(): number; lng(): number };
      getSouthWest(): { lat(): number; lng(): number };
    };
  };
}

@Component({
  selector: 'rk-notification-form',
  templateUrl: './notification-form.component.html',
  styleUrls: ['./notification-form.component.scss'],
})
export class NotificationFormComponent implements OnInit, OnDestroy {
  @Select(NotificationsState.isFormVisible)
  isFormVisible$: Observable<boolean>;
  @Select(NotificationsState.isSubmitting)
  isSubmitting$: Observable<boolean>;
  @Select(AppState.currentApp)
  currentApp$: Observable<App>;

  @ViewChild(AutocompleteInputComponent)
  autocompleteComponent: AutocompleteInputComponent;
  @ViewChild('locationSearch')
  locationSearch: ElementRef;

  showDatePicker = false;
  showLocationPicker = false;
  showLanguagePicker = false;
  languageList = LANGUAGES;
  isIphoneReady = false;
  isAndroidReady = false;
  iOSTooltipMessage = '';

  locationSearchControl = new FormControl();

  //  An array of functions used to extract the properties that will be used
  // for filtering in the autocomplete component.
  propertyExtractors = [
    (option: any) => option.code,
    (option: any) => option.name[0],
    (option: any) => option.name[1],
  ];

  notificationForm = this.formBuilder.group({
    message: [
      '',
      [Validators.required, Validators.minLength(3), Validators.maxLength(140)],
    ],
    send_at: [null],
    enabled: [true],
    platform: [['android', 'iphone'], [Validators.required]],
    locations: this.formBuilder.array([]),
    languages: this.formBuilder.array([]),
  });

  apiLoaded$: Observable<boolean>;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly httpClient: HttpClient,
    private readonly store: Store,
    private readonly formBuilder: FormBuilder,
    private readonly cd: ChangeDetectorRef,
    private readonly actions$: Actions,
    private readonly translateService: TranslateService,
    private readonly matDialog: MatDialog,
  ) {
    if (!window.google) {
      this.apiLoaded$ = httpClient
        .jsonp(
          `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsKey}&libraries=places`,
          'callback',
        )
        .pipe(
          map(() => {
            setTimeout(() => this.initMapsAutocomplete(), 500);

            return true;
          }),
          catchError(error => {
            console.error('Error loading Google Maps API:', error);

            return of(false);
          }),
        );
    } else {
      this.apiLoaded$ = of(true);
      setTimeout(() => this.initMapsAutocomplete(), 500);
    }
  }

  ngOnInit() {
    this.subscriptions.add(
      this.currentApp$.subscribe(app => {
        this.isIphoneReady = app.iPhoneReady;
        this.isAndroidReady = app.androidReady;
        this.iOSTooltipMessage = app.support.includes['iphone']
          ? marker('notification-form.tooltip-unavailable-ios')
          : marker('notification-form.tooltip-support-ios');
        this.setDefaultPlatforms();
      }),
    );

    this.subscriptions.add(
      this.store
        .select(NotificationsState.currentNotification)
        .subscribe(notification => {
          if (notification) {
            this.populateForm(notification);
          }
        }),
    );

    this.subscriptions.add(
      this.actions$
        .pipe(ofActionSuccessful(SubmitNotificationSuccess))
        .subscribe(() => this.resetForm()),
    );

    this.subscriptions.add(
      this.actions$.pipe(ofActionErrored(SubmitNotificationError)).subscribe(() => {}),
    );
  }

  get selectedPlatforms() {
    return this.notificationForm.get('platform').value;
  }

  get languages(): FormArray {
    return this.notificationForm.get('languages') as FormArray;
  }

  get locations(): FormArray {
    return this.notificationForm.get('locations') as FormArray;
  }

  // TODO improve Regex - the € sign is removed
  removeEmojis(event: Event): void {
    const textarea: HTMLTextAreaElement = event.target as HTMLTextAreaElement;
    const noEmoji = textarea.value.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      '',
    );
    textarea.value = noEmoji;
  }

  setDefaultPlatforms() {
    const platforms = [];
    if (this.isAndroidReady) {
      platforms.push('android');
    }
    if (this.isIphoneReady) {
      platforms.push('iphone');
    }
    this.notificationForm
      .get('platform')
      .setValue(platforms.length > 0 ? platforms : null);
  }

  private initMapsAutocomplete(): void {
    if (!google?.maps?.places?.Autocomplete) {
      console.error('Google Maps API or Autocomplete is not loaded');

      return;
    }

    const autocomplete = new google.maps.places.Autocomplete(
      this.locationSearch.nativeElement,
    );

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.place_id) {
        const placeCoordinates = this.getPlaceCoordinates(place);
        // Check for duplicate before pushing
        if (!this.isLocationDuplicate(placeCoordinates)) {
          // new location is added at the beggining of the list
          this.locations.insert(0, this.formBuilder.control(placeCoordinates));
          this.locationSearchControl.reset();
          this.cd.detectChanges();
        }
      }
    });
  }

  private isLocationDuplicate(location: Location): boolean {
    return this.locations.value.some(
      (existingLocation: Location) =>
        existingLocation.name === location.name &&
        existingLocation.location === location.location,
    );
  }

  getPlaceCoordinates(place: PlaceResult) {
    return {
      name: place.formatted_address,
      location: `${place.geometry.viewport.getNorthEast().lat()},${place.geometry.viewport
        .getNorthEast()
        .lng()},${place.geometry.viewport.getSouthWest().lat()},${place.geometry.viewport
        .getSouthWest()
        .lng()}`,
    };
  }

  populateForm(notification: Notification) {
    let platformTerms: string[] = [];
    let locationTerms: any[] = [];
    let languageTerms: string[] = [];

    for (const criteria of notification.criterias) {
      if (criteria.column === 'platform') {
        platformTerms = criteria.terms.split(';');
      } else if (criteria.column === 'location') {
        locationTerms = JSON.parse(criteria.terms);
      } else if (criteria.column === 'lang') {
        languageTerms = criteria.terms.split(';');
      }
    }

    // Convert send_at to a Date object
    const sendAtDate = new Date(notification.sendAt);

    this.notificationForm.patchValue({
      message: notification.message,
      send_at: sendAtDate > new Date() ? sendAtDate : null,
      enabled: notification.enabled,
      platform: platformTerms,
    });

    this.languages.clear();
    for (const languageCode of languageTerms) {
      const matchedLanguage = this.languageList.find(lang => lang.code === languageCode);
      if (matchedLanguage) {
        this.languages.push(this.formBuilder.control(matchedLanguage));
      }
    }

    if (this.languages.length > 0) {
      this.showLanguagePicker = true;
    }

    this.locations.clear();
    for (const location of locationTerms) {
      this.locations.push(this.formBuilder.control(location));
    }

    if (this.locations.length > 0) {
      this.showLocationPicker = true;
    }

    if (this.notificationForm.value.send_at) {
      this.showDatePicker = true;
    }

    this.cd.detectChanges();
  }

  togglePlatform(platform: string): void {
    let currentPlatforms = this.notificationForm.get('platform').value || [];
    if (currentPlatforms.includes(platform)) {
      currentPlatforms = currentPlatforms.filter(p => p !== platform);
    } else {
      currentPlatforms.push(platform);
    }
    this.notificationForm
      .get('platform')
      .setValue(currentPlatforms.length > 0 ? currentPlatforms : null);
  }

  onLanguageSelected(option: Language) {
    if (!this.isLanguageDuplicate(option)) {
      // new language is added at the beginning of the list
      this.languages.insert(0, this.formBuilder.control(option));
    }
    this.autocompleteComponent.optionsCtrl.reset();
  }

  private isLanguageDuplicate(language: Language): boolean {
    return this.languages.value.some(
      (existingLanguage: Language) => existingLanguage.code === language.code,
    );
  }

  removeLanguage(index: number) {
    this.languages.removeAt(index);
  }

  removeLocation(index: number) {
    this.locations.removeAt(index);
  }

  onLanguageToggle(event: boolean) {
    this.showLanguagePicker = event;
    if (!this.showLanguagePicker) {
      this.languages.clear();
    }
  }

  onSchedulingToggle(event: boolean) {
    this.showDatePicker = event;
    if (!this.showDatePicker) {
      // resets the datepicker field to the current date
      // in case user reopens it staright after
      this.notificationForm.patchValue({
        send_at: new Date(),
      });
      this.notificationForm.patchValue({
        send_at: null,
      });
    }
  }

  onLocationToggle(event: boolean) {
    this.showLocationPicker = event;
    if (!this.showLocationPicker) {
      this.locations.clear();
    }
  }

  saveDraft() {
    if (this.notificationForm.valid) {
      this.notificationForm.patchValue({
        enabled: false,
      });
      this.onSubmit();
    }
  }

  submitNotification() {
    if (this.notificationForm.valid) {
      this.notificationForm.patchValue({
        enabled: true,
      });
      this.onSubmit();
    }
  }

  onSubmit() {
    if (this.notificationForm.valid) {
      const sendAt: Date = this.calculateGMTDate(this.notificationForm.value.send_at);

      const criterias = [
        {
          column: 'platform',
          comparison: 'equals',
          terms: this.notificationForm.get('platform').value.join(';'),
        },
      ];

      if (this.locations.length > 0) {
        criterias.push({
          column: 'location',
          comparison: 'contains',
          terms: JSON.stringify(this.locations.value.map((location: any) => location)),
        });
      }

      if (this.languages.length > 0) {
        criterias.push({
          column: 'lang',
          comparison: 'equals',
          terms: this.languages.value.map((lang: any) => lang.code).join(';'),
        });
      }

      const payload: Partial<Notification> = {
        message: this.notificationForm.value.message,
        sendAt,
        enabled: this.notificationForm.value.enabled,
        criterias,
      };

      this.store.dispatch(new SubmitNotificationRequest(payload));
    }
  }

  resetForm() {
    this.notificationForm.reset({
      message: '',
      send_at: null,
      enabled: true,
      platform: ['android', 'iphone'],
    });
    this.languages.clear();
    this.locations.clear();
  }

  closeForm() {
    if (this.notificationForm.dirty) {
      const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
        data: { isFormValid: this.notificationForm.valid },
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'saveDraft') {
          this.saveDraft();
        } else if (result) {
          this.resetForm();
        }
        this.store.dispatch(new CloseNotificationForm());
        this.store.dispatch(new ResetCurrentNotification());
      });
    } else {
      this.store.dispatch(new CloseNotificationForm());
      this.store.dispatch(new ResetCurrentNotification());
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  // when changing the day (and not the hour) in dateTimePicker component, date is not GMT anymore
  // this function offsets the date when necessary
  private calculateGMTDate(inputDate: moment.Moment): Date | null {
    if (!inputDate) {
      return null;
    }
    const utcOffset = inputDate.utcOffset();
    let gmtDate = null;
    if (utcOffset === 0) {
      gmtDate = inputDate.subtract(moment().utcOffset(), 'minute');
    } else {
      gmtDate = inputDate;
    }
    const now = new Date();

    return gmtDate && gmtDate.toDate() > now ? gmtDate.toDate() : null;
  }
}
