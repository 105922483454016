import { Env } from './environment.model';
export const environment: Env = {
  production: true,
  disableLogs: false,
  auth: {
    isLocalLoginEnabled: true,
    // TODO redirect URL
    redirectUrl:
      'https://www.dev.radioking.xyz/login?redirect=https:%2F%2Fmanager.dev.radioking.xyz',
  },
  urls: {
    MAIN_API: 'https://api.dev.radioking.xyz',
    MOBILE_API: 'https://www.dev.radioking.xyz/mobile/api',
    RADIOKING_DOMAIN: 'dev.radioking.xyz',
    SIMULATOR: 'https://mobile-simulator.dev.radioking.xyz',
  },
  whmcs: {
    url: 'https://www.dev.radioking.xyz/on',
    start: {
      customFieldId: '137',
      planId: '53',
      publicationAddonId: '17',
    },
    pro: {
      customFieldId: '7',
      planId: '7',
      publicationAddonId: '17',
    },
    business: {
      customFieldId: '139',
      planId: '55',
      publicationAddonId: '19',
    },
  },
  googleMapsKey: 'AIzaSyA1BNLwxFxrbnVeH5Er5387UFI0G2xeJjQ',
  gtm: 'GTM-TVKWT72',
  amplitudeKey: 'bc0f31db4cfd1f69b68566b87199d043',
  cookies: {
    config: {
      domain: '.dev.radioking.xyz',
      expires: 365,
      secure: true,
      path: '/',
    },
    refreshTokenKey: 'refresh_token',
    tokenKey: 'token',
  },
};
