import { Component, ElementRef, Renderer2 } from '@angular/core';
import { UpdateAppConfig } from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Select, Store } from '@ngxs/store';
import { Observable, combineLatest, first } from 'rxjs';

@Component({
  selector: 'rk-background-color-settings',
  templateUrl: './background-color-settings.component.html',
  styleUrl: './background-color-settings.component.scss',
})
export class BackgroundColorSettingsComponent {
  @Select(AppConfigurationState.currentAppConfigBackgroundColorStyle)
  currentAppConfigBackgroundColorStyle$: Observable<string>;

  @Select(AppConfigurationState.currentAppConfigBackgroundGradientDirection)
  currentAppConfigBackgroundGradientDirection$: Observable<string>;

  @Select(AppConfigurationState.currentAppConfigBackgroundColor)
  currentAppConfigBackgroundColor$: Observable<string>;

  @Select(AppConfigurationState.currentAppConfigBackgroundColorSecondary)
  currentAppConfigBackgroundColorSecondary$: Observable<string>;

  tooltipFitContentStyle = { width: 'max-content', 'min-width': 'auto' };
  isSwapped = false;

  constructor(
    private readonly store: Store,
    private readonly renderer: Renderer2,
    private readonly elRef: ElementRef,
  ) {}

  onColorChange(color: string, key: string) {
    this.store.dispatch(new UpdateAppConfig(['onglets', '0', key], color));
  }

  selectBackgroundStyle(style: string) {
    this.store.dispatch(new UpdateAppConfig(['onglets', '0', 'backgroundType'], style));
  }

  selectGradientDirection(direction: string) {
    this.store.dispatch(
      new UpdateAppConfig(['onglets', '0', 'gradientDirection'], direction),
    );
  }

  swapColors() {
    this.applyAnimation(true);

    this.isSwapped = true;

    setTimeout(() => {
      combineLatest([
        this.currentAppConfigBackgroundColor$,
        this.currentAppConfigBackgroundColorSecondary$,
      ])
        .pipe(first())
        .subscribe(([primaryColor, secondaryColor]) => {
          this.store.dispatch(
            new UpdateAppConfig(['onglets', '0', 'backgroundColor'], secondaryColor),
          );
          this.store.dispatch(
            new UpdateAppConfig(
              ['onglets', '0', 'backgroundColorSecondary'],
              primaryColor,
            ),
          );
        });
    }, 200);

    setTimeout(() => {
      this.isSwapped = false;
      this.applyAnimation(false);
    }, 600);
  }

  private applyAnimation(enable: boolean) {
    const colorSelectors = this.elRef.nativeElement.querySelectorAll('.color-selector');
    if (enable) {
      colorSelectors.forEach((selector: Element, index: number) => {
        this.renderer.addClass(
          selector,
          this.isSwapped
            ? index === 0
              ? 'moving-up'
              : 'moving-down'
            : index === 0
              ? 'moving-down'
              : 'moving-up',
        );
      });
    } else {
      colorSelectors.forEach((selector: Element) => {
        this.renderer.removeClass(selector, 'moving-up');
        this.renderer.removeClass(selector, 'moving-down');
      });
    }
  }
}
