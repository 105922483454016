import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs';
import { AdminApp } from '@app/core/models/app.model';
import { AdminService } from '../services/admin.service';
import {
  ArchiveUploadSuccess,
  DecrementPage,
  DownloadAndroidArchive,
  DownloadAppleArchive,
  FetchAdminAppListsError,
  FetchAdminAppListsRequest,
  FetchAdminAppListsSuccess,
  IncrementPage,
  PostAndroidApiKeyError,
  PostAndroidApiKeyRequest,
  PostAndroidApiKeySuccess,
  PostAndroidArchive,
  PostAndroidArchiveError,
  PostAndroidArchiveSuccess,
  PostAppleArchive,
  PostAppleArchiveError,
  PostAppleArchiveSuccess,
  PostIosKeyError,
  PostIosKeyRequest,
  PostIosKeySuccess,
  PostP8CertError,
  PostP8CertRequest,
  PostP8CertSuccess,
} from './admin.actions';

export class AdminStateModel {
  adminAppList: AdminApp[];
  isLoading: boolean;
  isUploading: boolean;
  currentPage: number;
}

@State<AdminStateModel>({
  name: 'admin',
  defaults: {
    adminAppList: null,
    isLoading: true,
    isUploading: false,
    currentPage: 1,
  },
})
@Injectable()
export class AdminState {
  constructor(private readonly adminService: AdminService) {}
  @Selector()
  static isLoading(state: AdminStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  static adminAppList(state: AdminStateModel): AdminApp[] {
    return state.adminAppList;
  }

  @Selector()
  static currentPage(state: AdminStateModel): number {
    return state.currentPage;
  }

  @Selector()
  static isUploading(state: AdminStateModel): boolean {
    return state.isUploading;
  }

  @Action(FetchAdminAppListsRequest)
  fetchAdminAppsListRequest(
    ctx: StateContext<AdminStateModel>,
    { query }: FetchAdminAppListsRequest,
  ) {
    ctx.patchState({
      isLoading: true,
    });
    const currentPage = ctx.getState().currentPage;

    return this.adminService.getApps(currentPage, query).pipe(
      tap(apps => {
        ctx.dispatch(new FetchAdminAppListsSuccess(apps));
      }),
      catchError(() => ctx.dispatch(new FetchAdminAppListsError())),
    );
  }

  @Action(FetchAdminAppListsSuccess)
  FetchAppsSuccess(
    ctx: StateContext<AdminStateModel>,
    { adminAppList }: FetchAdminAppListsSuccess,
  ) {
    ctx.patchState({
      adminAppList,
      isLoading: false,
    });
  }

  @Action(FetchAdminAppListsError)
  toggleLoadingState(ctx: StateContext<AdminStateModel>) {
    ctx.patchState({
      isLoading: false,
    });
  }

  @Action(IncrementPage)
  incrementPage(ctx: StateContext<AdminStateModel>) {
    const currentPage = ctx.getState().currentPage;
    ctx.patchState({
      currentPage: currentPage + 1,
    });
  }

  @Action(DecrementPage)
  decrementPage(ctx: StateContext<AdminStateModel>) {
    const currentPage = ctx.getState().currentPage;
    ctx.patchState({
      currentPage: currentPage > 1 ? currentPage - 1 : currentPage,
    });
  }

  @Action(PostIosKeyRequest)
  postIosKeyRequest(
    ctx: StateContext<AdminStateModel>,
    { appId, teamId, keyId }: PostIosKeyRequest,
  ) {
    return this.adminService.postIosKey(appId, teamId, keyId).pipe(
      tap(() => {
        ctx.dispatch(new PostIosKeySuccess());
      }),
      catchError(() => ctx.dispatch(new FetchAdminAppListsError())),
    );
  }

  @Action(PostIosKeySuccess)
  postIosKeySuccess(ctx: StateContext<AdminStateModel>) {
    const currentPage = ctx.getState().currentPage;

    ctx.dispatch(new FetchAdminAppListsRequest(currentPage, ''));
  }

  @Action(PostIosKeyError)
  postIosKeyError() {}

  @Action(PostP8CertRequest)
  postP8CertRequest(
    ctx: StateContext<AdminStateModel>,
    { appId, cert }: PostP8CertRequest,
  ) {
    return this.adminService.postP8Certificate(appId, cert).pipe(
      tap(() => {
        ctx.dispatch(new PostP8CertSuccess());
      }),
      catchError(error => ctx.dispatch(new PostP8CertError(error))),
    );
  }

  @Action(PostAndroidApiKeyRequest)
  postAndroidApiKeyRequest(
    ctx: StateContext<AdminStateModel>,
    { appId, androidApiKey }: PostAndroidApiKeyRequest,
  ) {
    return this.adminService.postAndroidApiKey(appId, androidApiKey).pipe(
      tap(() => {
        ctx.dispatch(new PostAndroidApiKeySuccess());
      }),
      catchError(error => ctx.dispatch(new PostAndroidApiKeyError(error))),
    );
  }

  @Action(PostAndroidApiKeySuccess)
  postAndroidApiKeySuccess(ctx: StateContext<AdminStateModel>) {
    const currentPage = ctx.getState().currentPage;
    ctx.dispatch(new FetchAdminAppListsRequest(currentPage, ''));
  }

  @Action(PostAndroidArchive)
  postAndroidArchive(
    ctx: StateContext<AdminStateModel>,
    { appId, archive }: PostAndroidArchive,
  ) {
    ctx.patchState({
      isUploading: true,
    });

    return this.adminService.postAndroidArchive(appId, archive).pipe(
      tap(() => {
        ctx.dispatch(new PostAndroidArchiveSuccess());
      }),
      catchError(error => ctx.dispatch(new PostAndroidArchiveError(error))),
    );
  }

  @Action(PostAndroidArchiveSuccess)
  postAndroidArchiveSuccess(ctx: StateContext<AdminStateModel>) {
    ctx.patchState({
      isUploading: false,
    });
    ctx.dispatch(new ArchiveUploadSuccess());
  }

  @Action(PostAndroidArchiveError)
  postAndroidArchiveError(ctx: StateContext<AdminStateModel>) {
    ctx.patchState({
      isUploading: false,
    });
  }

  @Action(DownloadAndroidArchive)
  downloadAndroidArchive(
    ctx: StateContext<AdminStateModel>,
    { app }: DownloadAndroidArchive,
  ) {
    this.adminService.downloadAndroidArchive(app.appId).subscribe(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download =
        (app.bundleIdAndroid ? app.bundleIdAndroid : app.bundleId) + '_android.zip';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  }

  @Action(PostAppleArchive)
  postAppleArchive(
    ctx: StateContext<AdminStateModel>,
    { appId, archive }: PostAppleArchive,
  ) {
    ctx.patchState({
      isUploading: true,
    });

    return this.adminService.postAppleArchive(appId, archive).pipe(
      tap(() => {
        ctx.dispatch(new PostAppleArchiveSuccess());
      }),
      catchError(error => ctx.dispatch(new PostAppleArchiveError(error))),
    );
  }

  @Action(PostAppleArchiveSuccess)
  postAppleArchiveSuccess(ctx: StateContext<AdminStateModel>) {
    ctx.patchState({
      isUploading: false,
    });
    ctx.dispatch(new ArchiveUploadSuccess());
  }

  @Action(PostAppleArchiveError)
  postAppleArchiveError(ctx: StateContext<AdminStateModel>) {
    ctx.patchState({
      isUploading: false,
    });
  }

  @Action(DownloadAppleArchive)
  downloadAppleArchive(
    ctx: StateContext<AdminStateModel>,
    { app }: DownloadAppleArchive,
  ) {
    this.adminService.downloadAppleArchive(app.appId).subscribe(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = (app.bundleId ? app.bundleId : app.bundleId) + '_ios.zip';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  }
}
