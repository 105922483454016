import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faAndroid } from '@fortawesome/free-brands-svg-icons';
import { Store } from '@ngxs/store';
import { AdminApp, AndroidKey } from '@app/core/models/app.model';
import { PostAndroidApiKeyRequest } from '../../states/admin.actions';

@Component({
  selector: 'rk-android-notification-form',
  templateUrl: './android-notification-form.component.html',
  styleUrls: ['./android-notification-form.component.scss'],
})
export class AndroidNotificationFormComponent implements OnInit {
  public form: FormGroup;
  faAndroid = faAndroid;

  constructor(
    public dialogRef: MatDialogRef<AndroidNotificationFormComponent>,
    @Inject(MAT_DIALOG_DATA) public app: AdminApp,
    private readonly formBuilder: FormBuilder,
    private readonly store: Store,
  ) {
    this.form = this.formBuilder.group({
      androidApiKey: [''],
    });
  }

  ngOnInit(): void {
    const androidKey: AndroidKey | null = this.app.androidApiKey;
    if (androidKey) {
      this.form.patchValue({
        androidApiKey: androidKey.apiKey,
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.valid) {
      const { androidApiKey } = this.form.value;
      this.store.dispatch(new PostAndroidApiKeyRequest(this.app.appId, androidApiKey));
      this.dialogRef.close();
    }
  }
}
