<div class="w-[309px] p-5 icon-search-container bg-white">
  <rk-form-field>
    <div class="flex items-center">
      <input
      type="text"
      class=""
      [(ngModel)]="searchText"
      (ngModelChange)="searchText$.next($event)"
      placeholder="{{ 'icon-search.icon-name' | translate }}"
      (click)="$event.stopPropagation()"
    />
    <fa-icon class=" absolute left-9 opacity-30 text-sm" [icon]="'magnifying-glass'"></fa-icon>
    </div>


  </rk-form-field>
  <cdk-virtual-scroll-viewport itemSize="20" class="icon-grid-viewport mt-4">
    <div *cdkVirtualFor="let row of itemSet" class="flex items-center h-5 mb-2 w-full">
      <div
        *ngFor="let icon of row"
        class="flex items-center justify-center w-full cursor-pointer icon-item"
        (click)="selectIcon(icon)"
      >
        <fa-icon [icon]="[icon.category, icon.name]"></fa-icon>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
