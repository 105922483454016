import { Component } from '@angular/core';
import { RadioTabModel } from '@app/features/app-creation-and-configuration/models/app-tabs.model';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'rk-player-content',
  templateUrl: './player-content.component.html',
  styleUrl: './player-content.component.scss'
})
export class PlayerContentComponent  {
  @Select(AppConfigurationState.currentAppConfigPlayerTab)
  currentAppConfigPlayerTab$: Observable<RadioTabModel>;


}
