import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DragDropService } from '@app/core/services/drag-drop.service';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import { capitalizeFirstLetter } from '@app/core/utils';
import { AppConfigTabs } from '@app/features/app-creation-and-configuration/models/app-config.model';
import { MockTabsService } from '@app/features/app-creation-and-configuration/services/mock-tabs.service';
import {
  AddNewTab,
  DeleteTab,
  UpdateActivePage,
  UpdateActiveSettingsCategory,
  UpdateAppConfig,
} from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { convertToIconFormat } from '@app/ui/components/icon-search/icon-list';
import { environment } from '@env/environment';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'rk-pages-list',
  templateUrl: './pages-list.component.html',
  styleUrls: ['./pages-list.component.scss'],
})
export class PagesListComponent implements OnInit {
  @Select(AppConfigurationState.currentAppConfigPageList)
  currentAppConfigPageList$: Observable<AppConfigTabs>;

  @Select(AppConfigurationState.activePageIndex)
  activePageIndex$: Observable<number>;

  @ViewChild('editInput')
  editInput: ElementRef;

  pageList: AppConfigTabs;
  isEditing: boolean[] = [];

  radiokingDomain = `https://www.${environment.urls.RADIOKING_DOMAIN}`;

  constructor(
    private readonly store: Store,
    private readonly hostElement: ElementRef,
    private readonly mockTabs: MockTabsService,
    private readonly dragDropService: DragDropService,
  ) {}

  ngOnInit(): void {
    this.currentAppConfigPageList$.subscribe(pageList => {
      if (this.pageList && pageList.length > this.pageList.length) {
        this.scrollToBottom();
      }
      this.pageList = pageList;
    });
  }

  scrollToBottom(): void {
    // setTimeout with a delay of 0 is used to ensure that the DOM updates are complete before attempting to scroll
    setTimeout(() => {
      this.hostElement.nativeElement.scrollTo({
        top: this.hostElement.nativeElement.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  }

  setActivePage(index: number): void {
    this.store.dispatch(new UpdateActiveSettingsCategory('pages'));
    this.store.dispatch(new UpdateActivePage(index));

    // event tracking
    const pageType = this.pageList[index].type;
    this.store.dispatch(
      new SetEventProperties({
        name: 'NavPages' + capitalizeFirstLetter(pageType),
        component: 'Navigation',
      }),
    );
    this.store.dispatch(new LogEvent('Navigation Clicked'));
  }

  drop(event: CdkDragDrop<string>) {
    if (event.currentIndex !== 0) {
      // if a new page is added by dragging from the "add-a-new-page" component
      if (event.previousContainer.id === 'add-new-page') {
        const pageType = event.item.data.type;
        const newTabs = this.mockTabs.getTabs();
        const targetIndex = event.currentIndex;
        this.store.dispatch(new AddNewTab(newTabs[pageType], targetIndex));

        // if the page list is being reordered by dragging inside the page-list component
      } else {
        const updatedPageList = [...this.pageList];
        moveItemInArray(updatedPageList, event.previousIndex, event.currentIndex);

        this.store.dispatch(
          new UpdateAppConfig(['onglets'], updatedPageList as AppConfigTabs),
        );
      }
    }
    this.store.dispatch(new UpdateActivePage(event.currentIndex));
    this.dragDropService.stopDragging();
  }

  dragStart(): void {
    this.dragDropService.startDragging();
  }

  getFaIcon(iconName: string) {
    return convertToIconFormat(iconName);
  }

  deletePage(event: Event, index: number) {
    event.stopPropagation();
    this.store.dispatch(new DeleteTab(index));
  }

  startEditing(index: number, event: Event): void {
    event.stopPropagation();
    this.isEditing.fill(false);
    this.isEditing[index] = true;
    setTimeout(() => {
      const inputElement = this.editInput.nativeElement as HTMLInputElement;
      inputElement.focus();
      inputElement.setSelectionRange(
        inputElement.value.length,
        inputElement.value.length,
      );
    }, 0);
  }

  finishEditing(index: number, event: Event): void {
    this.isEditing[index] = false;
    const inputElement = event.target as HTMLInputElement;
    const newName = inputElement.value;
    if (newName.trim() !== '') {
      this.store.dispatch(
        new UpdateAppConfig(['onglets', index.toString(), 'titre'], newName),
      );
    }
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }
}
