import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { Icon, iconList } from './icon-list';

@Component({
  selector: 'rk-icon-search',
  templateUrl: './icon-search.component.html',
  styleUrls: ['./icon-search.component.scss'],
})
export class IconSearchComponent implements OnInit {
  @Output() readonly iconSelected = new EventEmitter<Icon>();
  isIconSearchOpen = false;
  searchText = '';
  allIcons = iconList;
  itemSet: Icon[][];

  searchText$ = new BehaviorSubject<string>('');

  ngOnInit() {
    this.searchText$.pipe(debounceTime(300)).subscribe(searchText => {
      this.filterIcons(searchText);
    });
    this.itemSet = this.generateDataChunk(this.allIcons);
  }

  generateDataChunk(data: Icon[], chunkSize = 6): Icon[][] {
    const dataChunk: Icon[][] = [];
    for (let index = 0; index < data.length; index += chunkSize) {
      dataChunk.push(data.slice(index, index + chunkSize));
    }

    return dataChunk;
  }

  filterIcons(searchText: string) {
    const filteredData = this.allIcons.filter(icon =>
      icon.name.toLowerCase().includes(searchText.toLowerCase()),
    );
    this.itemSet = this.generateDataChunk(filteredData);
  }

  selectIcon(icon: Icon) {
    this.iconSelected.emit(icon);
  }
}
