import { Component, EventEmitter, Output } from '@angular/core';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import { capitalizeFirstLetter } from '@app/core/utils';
import { Store } from '@ngxs/store';

@Component({
  selector: 'rk-app-configuration-submenu',
  templateUrl: './app-configuration-submenu.component.html',
  styleUrls: ['./app-configuration-submenu.component.scss'],
})
export class AppConfigurationSubmenuComponent {
  selectedSubmenu = 'content';

  @Output() readonly submenuSelected = new EventEmitter<string>();


  constructor(private readonly store: Store) {}


  selectSubmenu(submenu: string): void {
    this.selectedSubmenu = submenu;
    this.submenuSelected.emit(this.selectedSubmenu);

    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: 'Tab' + capitalizeFirstLetter(submenu),
        component: 'Tab',
      }),
    );
    this.store.dispatch(new LogEvent('Tab Clicked'));
  }
}
