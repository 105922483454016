<ng-container *ngIf="isLoading$ | async; else showContent">
  <div class="flex justify-center items-center w-full h-full">
    <mat-progress-spinner
      mode="indeterminate"
      [strokeWidth]="3"
      [diameter]="20"
    ></mat-progress-spinner>
  </div>
</ng-container>

<ng-template #showContent>
  <!-- See styles overrides in styles.scss -->
  @if((currentAppIsNotOwnedByUser$ | async) === false) {
  <rk-dropdown
    class="app-list-dropdown"
    [options]="appsListDropdown"
    [selectedOption]="selectedApp"
    [whiteBackground]="true"
    (selectionChange)="onDropdownSelectionChange($event)"
  >
  </rk-dropdown>
  }
</ng-template>
