import { Component } from '@angular/core';

@Component({
  selector: 'rk-setting-divider',
  templateUrl: './setting-divider.component.html',
  styleUrls: ['./setting-divider.component.scss']
})
export class SettingDividerComponent {

}
