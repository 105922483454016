import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { AppConfigApi } from '@app/features/app-creation-and-configuration/models/app-config.model';
import { FetchAppConfigRequest } from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Select, Store } from '@ngxs/store';

import { Observable, Subscription } from 'rxjs';

@Injectable()
export class DraftGuard  {
  constructor(private readonly store: Store) {}

  private readonly subscription: Subscription = new Subscription();

  @Select(AppConfigurationState.currentAppConfig)
  currentAppConfig$: Observable<AppConfigApi>;

  canActivate(routeSnap: ActivatedRouteSnapshot): Observable<boolean> {
    return new Observable<boolean>(subject => {
      const appId = routeSnap.params['appId'];
      if (appId) {
        this.store.dispatch(new FetchAppConfigRequest(appId));
      }
      this.subscription.add(
        this.currentAppConfig$.subscribe(config => {
          if (config?.status) {
            if(config?.status === 'brouillon') {
              subject.next(true);
            }
            subject.next(false);
          }
        }),
      );
    });
  }
}
