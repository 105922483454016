<div class="flex justify-between w-full items-center h-full date-picker-container">
  <div
    class="w-[38px] flex h-full items-center cursor-pointer datepicker-icon-container"
    (click)="picker.open()"
  >
    <fa-icon class="h-full text-[#CDCDCD]" [icon]="'calendar'"></fa-icon>
  </div>
  <input
    matInput
    [ngxMatDatetimePicker]="picker"
    [formControl]="dateControl"
    (dateChange)="handleDateChange($event)"
    placeholder="{{ 'notification-form.date-placeholder' | translate }}"
    (click)="picker.open()"
    [min]="minDate"
  />
  <ngx-mat-datetime-picker #picker> </ngx-mat-datetime-picker>
</div>
