import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { AlertProps, AlertVariants } from './alert.model';

@Component({
  selector: 'rk-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [
    trigger('alertAnimation', [
      transition(':enter', [
        style({
          opacity: '0',
          transform: 'translateY(-1rem)',
        }),
        animate('200ms ease-out', style({ opacity: '1', transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        animate(
          '200ms ease-out',
          style({ opacity: '0', transform: 'translateY(-1rem)' }),
        ),
      ]),
    ]),
  ],
})
export class AlertComponent {
  @Input()
  opened = true;

  @HostBinding('class')
  @Input()
  variant: AlertVariants = 'info';

  @Input()
  title?: string;

  @Input()
  description?: string;

  @Input()
  cta?: string;

  @Input()
  close?: boolean;

  @Input()
  autoHide = 4000;

  @Input()
  trigger: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  readonly alertClose: EventEmitter<void> = new EventEmitter<void>();

  public init(alertProps: AlertProps) {
    Object.assign(this, alertProps);
    setTimeout(() => {
      this.closeAlert();
    }, this.autoHide);
  }

  get hostClass(): string {
    return this.variant;
  }

  closeAlert() {
    this.opened = false;
    this.alertClose.emit();
  }
}
