import { Component, Input, OnInit } from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rk-image-size-guide',
  templateUrl: './image-size-guide.component.html',
  styleUrls: ['./image-size-guide.component.scss'],
})
export class ImageSizeGuideComponent implements OnInit {
  @Input()
  width: number;

  @Input()
  height: number;

  @Input()
  lineBreakOption: 'first' | 'second' | 'both' | 'none' = 'none';

  formattedSizeGuide: string;

  constructor(private readonly translate: TranslateService) {}

  ngOnInit() {
    this.formatSizeGuide();
  }

  // the default value of the "image-size-guide.size" translation key includes two line breaks,
  // which may not always visually suit its placement within the component. To address this,
  // this function dynamically adjusts the number of line breaks based on the 'lineBreakOption' property,
  // allowing for more flexible display options including one line break, none, or the default two.

  formatSizeGuide() {
    const translationKey = marker('image-size-guide.size');
    const params = { width: this.width, height: this.height };

    this.translate.get(translationKey, params).subscribe((res: string) => {
      const lines = res.split('\n');
      switch (this.lineBreakOption) {
        case 'first':
          this.formattedSizeGuide = `${lines[0]}\n${lines[1]} ${lines[2]}`;
          break;
        case 'second':
          this.formattedSizeGuide = `${lines[0]} ${lines[1]}\n${lines[2]}`;
          break;
        case 'none':
          this.formattedSizeGuide = lines.join(' ');
          break;
        case 'both':
        default:
          this.formattedSizeGuide = res;
      }
    });
  }
}
