import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DragDropService } from '@app/core/services/drag-drop.service';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import { capitalizeFirstLetter } from '@app/core/utils';
import { MockTabsService } from '@app/features/app-creation-and-configuration/services/mock-tabs.service';
import { AddNewTab } from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import {
  AppConfigurationState,
  UserRadio,
} from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, filter } from 'rxjs';

interface PageType {
  type: string;
  name: string;
  icon: IconProp;
}

@Component({
  selector: 'rk-add-new-page',
  templateUrl: './add-new-page.component.html',
  styleUrls: ['./add-new-page.component.scss'],
})
export class AddNewPageComponent implements OnInit, OnDestroy {
  @Select(AppConfigurationState.userRadios)
  userRadios$: Observable<UserRadio[]>;

  constructor(
    private readonly store: Store,
    private readonly translate: TranslateService,
    private readonly mockTabs: MockTabsService,
    private readonly renderer: Renderer2,
    private readonly dragDropService: DragDropService,
  ) {}

  pageTypes: PageType[] = [
    { type: 'dedicaces', name: marker('add-new-page.dedications'), icon: 'quote-right' },
    {
      type: 'dedicacesaudio',
      name: marker('add-new-page.audio-dedications'),
      icon: 'microphone',
    },
    { type: 'site', name: marker('add-new-page.site'), icon: 'globe-americas' },
    { type: 'livefeed', name: marker('add-new-page.livefeed'), icon: 'comments' },
    { type: 'rss', name: marker('add-new-page.rss'), icon: 'rss' },
    { type: 'html', name: marker('add-new-page.html'), icon: 'code' },
    { type: 'reveil', name: marker('add-new-page.reveil'), icon: 'clock' },
  ];

  isContentVisible = true;
  private readonly subscriptions = new Subscription();
  bodyElement: HTMLElement = document.body;

  ngOnInit(): void {
    this.subscriptions.add(
      this.userRadios$.pipe(filter(radios => !!radios)).subscribe(radios => {
        this.mockTabs.getTabs().dedicacesaudio.idradio = radios[0].idradio;
      }),
    );
  }

  toggleContent(): void {
    this.isContentVisible = !this.isContentVisible;
  }

  addNewPage(tabType: string) {
    const newTabs = this.mockTabs.getTabs();
    this.store.dispatch(new AddNewTab(newTabs[tabType]));

    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: 'ButtonAddPage' + capitalizeFirstLetter(tabType),
        component: 'Button',
      }),
    );
    this.store.dispatch(new LogEvent('Button Clicked'));
  }

  dragStart(): void {
    this.dragDropService.startDragging();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
