import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import { Store } from '@ngxs/store';
import { AdminApp, IosKey } from '@app/core/models/app.model';
import { PostIosKeyRequest, PostP8CertRequest } from '../../states/admin.actions';

@Component({
  selector: 'rk-apple-notification-form',
  templateUrl: './apple-notification-form.component.html',
  styleUrls: ['./apple-notification-form.component.scss'],
})
export class AppleNotificationFormComponent implements OnInit {
  public form: FormGroup;
  faApple = faApple;
  p8FileName = ''

  constructor(
    public dialogRef: MatDialogRef<AppleNotificationFormComponent>,
    @Inject(MAT_DIALOG_DATA) public app: AdminApp,
    private readonly formBuilder: FormBuilder,
    private readonly store: Store,
  ) {
    this.form = this.formBuilder.group({
      teamId: [''],
      keyId: [''],
      p8Cert: [null],
    });
  }

  ngOnInit(): void {
    // Pre-populate the form with existing data
    const iosKey: IosKey | null = this.app.iosKey;
    if (iosKey) {
      this.form.patchValue({
        teamId: iosKey.teamId,
        keyId: iosKey.keyId,
      });
      this.p8FileName = iosKey.filename
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.valid) {
      const { teamId, keyId, p8Cert } = this.form.value;
      this.store.dispatch(new PostIosKeyRequest(this.app.appId, teamId, keyId));
      this.store.dispatch(new PostP8CertRequest(this.app.appId, p8Cert));
      this.dialogRef.close();
    }
  }

  handleFileInput(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      this.form.get('p8Cert').setValue(file);
    }
  }
}
