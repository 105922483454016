<div class="px-5 h-full text-[14px] admin-dashboard">
  <div class="h-full flex flex-col bg-white rounded-xl p-2">
    <div class="m-5 w-1/6">
      <input
        placeholder="Rechercher une app"
        (input)="onSearchInputChange($event)"
        class="p-2 rounded border"
      />
    </div>
    <div class="flex flex-row px-5 rounded items-start">
      <div class="flex-1 p-3 min-w-0">Logo</div>
      <div class="flex-1 p-3 min-w-0">ID</div>
      <div class="flex-3 p-3 min-w-0">Name</div>
      <div class="flex-1 p-3 min-w-0 text-center">Platforms</div>
      <div class="flex-2 p-3 min-w-0 text-center">
        Notifications <fa-icon [icon]="faApple"></fa-icon>
      </div>
      <div class="flex-2 p-3 min-w-0 text-center">
        Notifications <fa-icon [icon]="faAndroid"></fa-icon>
      </div>
      <div class="flex-2 p-3 min-w-0 text-center">
        Generation <fa-icon [icon]="faApple"></fa-icon>
      </div>
      <div class="flex-2 p-3 min-w-0 text-center">
        Generation <fa-icon [icon]="faAndroid"></fa-icon>
      </div>
      <div class="flex-1 p-3 min-w-0">Gérer</div>
    </div>

    <div class="overflow-auto flex-grow">
      <div
        class="flex flex-col px-5 admin-table-container rounded"
        *ngIf="(isLoading$ | async) === false; else loading"
      >
        <div
          class="flex border-t border-b rounded"
          *ngFor="let app of adminAppList$ | async"
        >
          <div class="flex-1 p-3 min-w-0 flex items-center">
            <a
              [href]="generateWhmcsUrl(app.userId, app.serviceId)"
              target="_blank"
            >
              <img
                class="rounded"
                [src]="app.logo"
                alt="{{ app.name }} logo"
                width="50"
                height="50"
                (error)="setDefaultImage($event)"
              />
            </a>
          </div>
          <div class="flex-1 p-3 min-w-0 flex items-center">
            {{ app.appId }}
          </div>
          <div class="flex-3 p-3 min-w-0 flex items-center">
            <a [href]="generateWhmcsUrl(app.userId, app.serviceId)" target="_blank">
              {{ app.name }}
            </a>
          </div>

          <div class="flex-1 p-3 min-w-0 gap-1 flex items-center justify-center">
            <fa-icon
              *ngIf="app.support?.includes('android')"
              [icon]="faAndroid"
            ></fa-icon>
            <fa-icon *ngIf="app.support?.includes('iphone')" [icon]="faApple"></fa-icon>
          </div>
          <div
            class="flex-2 p-3 min-w-0 flex items-center justify-center relative hover-container"
          >
            <!-- Is valid only if keyId, teamId and p8 cert are present -->
            <fa-icon
              *ngIf="
                app.iosKey &&
                  app.iosKey?.keyId.length > 0 &&
                  app.iosKey?.teamId.length > 0 &&
                  app.iosKey?.filename.length > 0;
                else noKey
              "
              [icon]="'circle-check'"
              class="text-valid"
            ></fa-icon>
            <ng-template #noKey>
              <fa-icon [icon]="'circle-xmark'" class="text-error"></fa-icon>
            </ng-template>
            <rk-button
              class="hover-button"
              [variant]="'secondary'"
              [iconOnly]="true"
              [icon]="'pencil'"
              (buttonClick)="openAppleNotificationForm(app)"
            ></rk-button>
          </div>
          <div
            class="flex-2 p-3 min-w-0 flex items-center justify-center relative hover-container"
          >
            <fa-icon
              *ngIf="app.androidApiKey; else noKey"
              [icon]="'circle-check'"
              class="text-valid"
            ></fa-icon>
            <ng-template #noKey>
              <fa-icon [icon]="'circle-xmark'" class="text-error"></fa-icon>
            </ng-template>
            <rk-button
              class="hover-button"
              [variant]="'secondary'"
              [iconOnly]="true"
              [icon]="'pencil'"
              (buttonClick)="openAndroidNotificationForm(app)"
            ></rk-button>
          </div>
          <div
            class="flex-2 p-3 min-w-0 flex items-center justify-center relative hover-container"
          >
            <fa-icon
              *ngIf="app.generationStatus?.iosStatus === 'in_progress'"
              [icon]="'clock'"
              class="text-[#FFA502]"
            ></fa-icon>
            <fa-icon
              *ngIf="app.generationStatus?.iosStatus === 'complete'"
              [icon]="'circle-check'"
              class="text-valid"
            ></fa-icon>
            <rk-button
              *ngIf="
                app.generationStatus?.iosStatus === 'complete' ||
                app.generationStatus?.iosStatus === 'in_progress'
              "
              class="hover-button"
              [variant]="'secondary'"
              [iconOnly]="true"
              [icon]="'pencil'"
              (buttonClick)="openAppGenerationFiles(app, 'apple')"
            ></rk-button>
          </div>
          <div
            class="flex-2 p-3 min-w-0 flex items-center justify-center relative hover-container"
          >
            <fa-icon
              *ngIf="app.generationStatus?.androidStatus === 'in_progress'"
              [icon]="'clock'"
              class="text-[#FFA502]"
            ></fa-icon>
            <fa-icon
              *ngIf="app.generationStatus?.androidStatus === 'complete'"
              [icon]="'circle-check'"
              class="text-valid"
            ></fa-icon>
            <rk-button
              *ngIf="
                app.generationStatus?.androidStatus === 'complete' ||
                app.generationStatus?.androidStatus === 'in_progress'
              "
              class="hover-button"
              [variant]="'secondary'"
              [iconOnly]="true"
              [icon]="'pencil'"
              (buttonClick)="openAppGenerationFiles(app, 'android')"
            ></rk-button>
          </div>
          <div class="flex-1 p-3 min-w-0 flex items-center">
            <rk-button
              [variant]="'secondary'"
              [iconOnly]="true"
              [icon]="'wrench'"
              (buttonClick)="manageApp(app)"
            ></rk-button>
          </div>
        </div>

        <div class="flex flex-row items-center justify-center my-4">
          <rk-button
            class="mx-2"
            [variant]="'secondary'"
            [text]="'Previous'"
            [disabled]="(currentPage$ | async) === 1"
            (buttonClick)="decrement()"
          ></rk-button>
          <div class="mx-2">Page: {{ currentPage$ | async }}</div>
          <rk-button
            class="mx-2"
            [variant]="'secondary'"
            [text]="'Next'"
            (buttonClick)="increment()"
          ></rk-button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="flex justify-center items-center w-full h-full">
    <mat-progress-spinner
      class="w-full h-full"
      mode="indeterminate"
      [strokeWidth]="5"
      [diameter]="50"
    ></mat-progress-spinner>
  </div>
</ng-template>
