<rk-setting-container [name]="'player-buttons.title' | translate">
  <!-- phone number -->
  <div class="flex justify-between items-center mb-1 mt-4">
    <span>{{ 'player-buttons.phone' | translate }}</span>
    <rk-switch
      [isChecked]="phoneNumberIsActive"
      (toggle)="onToggleChange($event, 'phoneNumberIsActive', 'callOption')"
    ></rk-switch>
  </div>
  @if (phoneNumberIsActive) {
    <rk-form-field>
      <input
        type="text"
        [formControl]="phoneNumberControl"
        [placeholder]="'player-buttons.phone-placeholder' | translate"
      />
      <!-- phone number error -->
      <div
        class="alert-container absolute"
        [class.visible]="phoneNumberControl.hasError('invalidPhoneNumber') && phoneNumberControl.touched"
      >
        <div class="form-error-message">
          {{ 'player-buttons.phone-error' | translate }}
        </div>
      </div>
    </rk-form-field>
  }
  <!-- sms number -->
  <div class="flex justify-between items-center mb-1 mt-7">
    <span>{{ 'player-buttons.sms' | translate }}</span>
    <rk-switch
      [isChecked]="smsNumberIsActive"
      (toggle)="onToggleChange($event, 'smsNumberIsActive', 'smsOption')"
    ></rk-switch>
  </div>
  @if (smsNumberIsActive) {
    <rk-form-field>
      <input
        type="text"
        [formControl]="smsNumberControl"
        [placeholder]="'player-buttons.sms-placeholder' | translate"
      />
       <!-- sms number error -->
      <div
        class="alert-container absolute"
        [class.visible]="smsNumberControl.hasError('invalidPhoneNumber') && smsNumberControl.touched"
      >
        <div class="form-error-message">
          {{ 'player-buttons.phone-error' | translate }}
        </div>
      </div>
    </rk-form-field>
  }
  <!-- share -->
  <div class="flex justify-between items-center mb-1 mt-7">
    <span>{{ 'player-buttons.share' | translate }}</span>
    <rk-switch
      [isChecked]="sharingIsActive"
      (toggle)="onToggleChange($event, 'sharingIsActive', 'partage')"
    ></rk-switch>
  </div>
  <!-- email -->
  <div class="flex justify-between items-center mb-1 mt-7">
    <span>{{ 'player-buttons.email' | translate }}</span>
    <rk-switch
      [isChecked]="emailIsActive"
      (toggle)="onToggleChange($event, 'emailIsActive', 'emailOption')"
    ></rk-switch>
  </div>
  @if (emailIsActive) {
    <rk-form-field>
      <input
        type="text"
        [formControl]="emailControl"
        [placeholder]="'player-buttons.mail-placeholder' | translate"
      />
      <!-- email error -->
      <div
        class="alert-container absolute"
        [class.visible]="emailControl.hasError('invalidEmail') && emailControl.touched"
      >
        <div class="form-error-message">
          {{ 'player-buttons.email-error' | translate }}
        </div>
      </div>
    </rk-form-field>
  }
  <!-- buying option -->
  <div class="flex justify-between items-center mb-1 mt-7">
    <span>{{ 'player-buttons.apple' | translate }}</span>
    <rk-switch
      [isChecked]="appleMusicIsActive"
      (toggle)="onToggleChange($event, 'appleMusicIsActive', 'achatOption')"
    ></rk-switch>
  </div>
  @if (appleMusicIsActive) {
    <rk-form-field>
      <input
        type="text"
        [formControl]="appleMusicControl"
        [placeholder]="'player-buttons.apple-music-placeholder' | translate"
        class="mb-4"
      />
    </rk-form-field>
    <!-- infobox -->
    <rk-infobox>
      <p [innerHTML]="'player-buttons.infobox-itunes' | translate"></p>
      <br />
      <a
        [href]="'player-buttons.apple-afiliate-help-link' | translate"
        target="_blank"
        class="font-bold"
        >{{ 'player-buttons.infobox-itunes-cta' | translate }}
      </a>
    </rk-infobox>
  }
</rk-setting-container>
<rk-setting-divider></rk-setting-divider>
<rk-setting-container [name]="'player-buttons.other-actions' | translate">
  <!-- vote -->
  <div class="flex justify-between items-center mb-1 mt-7">
    <span class="font-bold">{{ 'player-buttons.vote' | translate }}</span>
    <rk-switch
      [isChecked]="voteIsActive"
      (toggle)="onToggleChange($event, 'voteIsActive', 'voteOption')"
    ></rk-switch>
  </div>
  <p class="mb-2">{{ 'player-buttons.vote-explained' | translate }}</p>
  <!-- infobox -->
  <rk-infobox>
    {{ 'player-buttons.infobox-vote' | translate }}
  </rk-infobox>
  <!-- auto play -->
  <div class="flex justify-between items-center mb-1 mt-7">
    <span class="font-bold">{{ 'player-buttons.auto-play' | translate }}</span>
    <rk-switch
      [isChecked]="autoPlayIsActive"
      (toggle)="onToggleChange($event, 'autoPlayIsActive', 'autoPlay')"
    ></rk-switch>
  </div>
  <!-- auto play -->
  <div class="flex justify-between items-center mb-1 mt-7">
    <span class="font-bold">{{ 'player-buttons.sleep-timer' | translate }}</span>
    <rk-switch
      [isChecked]="sleepTimerIsActive"
      (toggle)="onToggleChange($event, 'sleepTimerIsActive', 'sleeptimerOption')"
    ></rk-switch>
  </div>
</rk-setting-container>
