<div class="mb-2  opacity-70">
  {{ 'general-configuration.text-align' | translate }}
</div>
<div class="flex gap-4">
  <!-- Text align options -->
  <div
    *ngFor="let align of alignments"
    class="design-option-container"
    [class.selected]="(textAlign$ | async) === align"
    (click)="selectTextAlign(align)"
  >
    <fa-icon [icon]="iconMap[align]"></fa-icon>
  </div>
</div>
