import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fadeInOutAnimation } from '@app/core/animations';
import { AppState } from '@app/core/states/app.state';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import { Observable, Subscription, distinctUntilChanged, filter } from 'rxjs';
import { Notification } from '../../models/notification.model';
import {
  FetchNotificationsRequest,
  OpenNotificationFormFromSidebar,
  ResetPagination,
} from '../../states/notifications.actions';
import { NotificationsState } from '../../states/notifications.state';

@Component({
  selector: 'rk-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss'],
  animations: [fadeInOutAnimation],
})
export class NotificationSidebarComponent implements OnInit, OnDestroy {
  @HostListener('scroll', ['$event'])
  @Select(NotificationsState.notifications)
  notifications$: Observable<Notification[]>;

  @Select(AppState.currentAppId)
  currentAppId$: Observable<number>;

  private readonly dataSubscription = new Subscription();

  hideAfterContent = false;

  constructor(private readonly store: Store, private readonly router: Router) {}

  ngOnInit(): void {
    this.store.dispatch(new ResetPagination());
    this.dataSubscription.add(
      this.currentAppId$
        .pipe(
          filter(id => !!id),
          distinctUntilChanged(),
        )
        .subscribe(() => {
          this.store.dispatch(new FetchNotificationsRequest(1));
        }),
    );
  }

  getTotalSent(notification: Notification): number {
    return notification.processes.reduce((total, process) => total + process.nbSent, 0);
  }

  openForm() {
    this.store.dispatch(new OpenNotificationFormFromSidebar());
    setTimeout(() => {
      this.router.navigateByUrl('/notifications');
    }, 200);
  }

  formatDate(date: Date): string {
    return moment(date).format('DD/MM/YY - HH:mm');
  }

  onScroll(event: any) {
    const maxScroll = event.target.scrollHeight - event.target.offsetHeight;
    if (event.target.scrollTop >= maxScroll - 100) {
      this.hideAfterContent = true;
    } else {
      this.hideAfterContent = false;
    }
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
  }
}
