<div class="relative w-full menu-elements-drop-boundary">
  <div class="pt-6 px-6 font-bold">{{ 'menu.pages' | translate }}</div>
  <div
    cdkDropList
    (cdkDropListDropped)="drop($event)"
    class="w-full drop-list px-3 mt-2"
    cdkDropBoundary=".menu-elements-drop-boundary"
  >
    <!-- player menu element is locked - can't be dragged-->
    <!-- other menu elements can be dragged and dropped-->
    <div
      *ngFor="let page of pageList; let i = index"
      class="flex items-center w-full py-3 px-3 drag-item draggable-menu-element"
      cdkDrag
      [cdkDragDisabled]="i === 0"
      (cdkDragStarted)="dragStart()"
    >
      <div class="drag-placeholder" *cdkDragPlaceholder></div>
      <!-- grip icon -->
      <fa-icon
        class="text-[15px] opacity-10 w-6 flex cursor-grab"
        [class.cursor-grab]="i !== 0"
        [icon]="i === 0 ? 'lock' : 'grip-vertical'"
      ></fa-icon>
      <!-- icon and name editor -->
      <rk-page-icon-and-name-editor
        class="w-full"
        [pageIndex]="i"
      ></rk-page-icon-and-name-editor>
    </div>
  </div>
  <rk-page-name-error></rk-page-name-error>
</div>
