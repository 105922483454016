<rk-setting-container
  class="add-new-page-container"
  [name]="'add-new-page.title' | translate"
>
  <div
    class="grid grid-cols-2 text-center place-items-center w-full mt-1"
    [cdkDropListConnectedTo]="['page-list']"
    cdkDropList
    cdkDropListSortingDisabled
    id="add-new-page"
  >
    <div
      *ngFor="let pageType of pageTypes"
      class=" px-4 py-2 flex items-center gap-1 cursor-pointer rounded page-card w-full"
      (click)="addNewPage(pageType.type)"
      cdkDrag
      [cdkDragData]="pageType"
      (cdkDragStarted)="dragStart()"
    >
      <div class="drag-placeholder" *cdkDragPlaceholder></div>
      <fa-icon class="mr-1" [icon]="pageType.icon"></fa-icon>
      <span class="text-sm">{{ pageType.name | translate }}</span>
    </div>
  </div>
</rk-setting-container>
