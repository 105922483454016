import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { UnsavedChangesDialogComponent } from '@app/ui/components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { Select } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UnsavedChangesService {
  private isConfigUpdated = false;

  @Select(AppConfigurationState.isConfigUpdated)
  isConfigUpdated$: Observable<boolean>;

  constructor(private readonly dialog: MatDialog) {
    this.isConfigUpdated$.subscribe(updated => {
      this.isConfigUpdated = updated;
    });
  }

  confirmDiscardChanges(): Observable<boolean> {
    if (!this.isConfigUpdated) {
      return of(true);
    } else {
      const dialogRef = this.dialog.open(UnsavedChangesDialogComponent, {
        width: '500px',
      });

      return dialogRef.afterClosed().pipe(map(result => !!result));
    }
  }

  checkForUnsavedChanges(): boolean {
    return this.isConfigUpdated;
  }
}
