import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, map } from 'rxjs';
import { App, AppApi, transformApp } from '../models/app.model';

@Injectable({
  providedIn: 'root',
})
export class AppsListService {
  constructor(private readonly http: HttpClient) {}

  getApps(): Observable<App[]> {
    const url = `${environment.urls.MOBILE_API}/user/me/app`;

    return this.http
      .get<AppApi[]>(url)
      .pipe(map((appsApi: AppApi[]) => appsApi.map(transformApp)));
  }
}
