import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DragDropService {
  private readonly bodyElement: HTMLElement = document.body;
  private readonly renderer: Renderer2;

  constructor(private readonly rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  startDragging() {
    this.bodyElement.classList.add('inheritCursors');
    this.bodyElement.style.cursor = 'grabbing';
    this.addNoHover();
  }

  stopDragging() {
    this.bodyElement.classList.remove('inheritCursors');
    this.bodyElement.style.cursor = 'unset';
    this.removeNoHover();
  }

  addNoHover() {
    const pageTitles = this.bodyElement.querySelectorAll('.page-title');
    pageTitles.forEach(el => {
      this.renderer.addClass(el as HTMLElement, 'no-hover');
    });
  }

  removeNoHover() {
    const pageTitles = this.bodyElement.querySelectorAll('.page-title');
    pageTitles.forEach(el => {
      this.renderer.removeClass(el as HTMLElement, 'no-hover');
    });
  }
}
