<div class="p-6">
  <!-- <div class="mb-10">
    <rk-color-selector
      [parameter]="'page-config.header-color' | translate"
      (colorChange)="onHeaderColorChange($event)"
      [initialColor]="headerColor$ | async"
    ></rk-color-selector>
  </div> -->
  <rk-color-selector
    [parameter]="'page-config.header-text-color' | translate"
    (colorChange)="onHeaderTextColorChange($event)"
    [initialColor]="headerTextColor$ | async"
  ></rk-color-selector>
</div>
