import {
  animate,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TranslateService } from '@ngx-translate/core';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { mergeMap, of } from 'rxjs';
import {
  CloseNotificationForm,
  ScheduleNotificationSuccess,
  SubmitNotificationSuccess,
  UpdateNotificationSuccess,
} from '../../states/notifications.actions';

interface NotificationFormAlertMessage {
  action: any;
  text: string;
  icon: IconProp;
}

@Component({
  selector: 'rk-notification-form-alert',
  templateUrl: './notification-form-alert.component.html',
  styleUrls: ['./notification-form-alert.component.scss'],
  animations: [
    trigger('alertOverlay', [
      transition(':enter', [
        style({ backgroundColor: 'transparent' }),
        animate('0.3s', style({ backgroundColor: 'rgba(255, 255, 255, 0.5)' })),
      ]),
      transition(':leave', [animate('0.3s', style({ backgroundColor: 'transparent' }))]),
    ]),
    trigger('alertContent', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.3s', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.3s', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class NotificationFormAlertComponent implements OnInit {
  alertVisible = false;
  containerVisible = false;
  messageVisible = false;
  alertIcon: IconProp | null;
  alertText: string;
  alertPhase = 'icon';
  private readonly notificationFormAlerts: NotificationFormAlertMessage[] = [
    {
      action: SubmitNotificationSuccess,
      text: marker('toast.notification-sent'),
      icon: null,
    },
    {
      action: UpdateNotificationSuccess,
      text: marker('toast.notification-updated'),
      icon: 'pen',
    },
    {
      action: ScheduleNotificationSuccess,
      text: marker('toast.notification-scheduled'),
      icon: 'calendar-days',
    },
  ];

  constructor(
    private readonly actions$: Actions,
    private readonly translate: TranslateService,
    private readonly store: Store,
  ) {}

  ngOnInit() {
    const actions = this.notificationFormAlerts.map(message => message.action);
    this.actions$
      .pipe(
        ofActionDispatched(...actions),
        mergeMap(action => {
          const message = this.notificationFormAlerts.find(
            m => m.action === action.constructor,
          );
          if (message) {
            return this.translate.get(message.text).pipe(
              mergeMap(translation =>
                of({
                  translation,
                  icon: message.icon,
                }),
              ),
            );
          }

          return of(null);
        }),
      )
      .subscribe(({ translation, icon }) => {
        if (translation) {
          this.showFormAlert({ text: translation, icon });
        }
      });
  }

  showFormAlert(message: Partial<NotificationFormAlertMessage>) {
    this.alertText = message.text;
    this.alertIcon = message.icon;
    this.alertVisible = true;

    setTimeout(() => {
      this.containerVisible = true;
    }, 300);

    setTimeout(() => {
      this.alertPhase = 'empty';
    }, 1000);

    setTimeout(() => {
      this.alertPhase = 'message';
    }, 1500);

    setTimeout(() => {
      this.alertVisible = false;
      this.store.dispatch(new CloseNotificationForm());
    }, 3800);
  }
}
