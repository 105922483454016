import { Component } from '@angular/core';
import { AddNewStream } from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'rk-add-new-stream',
  templateUrl: './add-new-stream.component.html',
  styleUrl: './add-new-stream.component.scss'
})
export class AddNewStreamComponent {
  constructor(private readonly store: Store) {}
  addNewStream(){
    this.store.dispatch(new AddNewStream())
  }
}
