<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  class="p-5 rounded apple-notification-form admin-form"
>
  <h2 class="m-8 font-bold">Edit Notifications <fa-icon [icon]="faApple"></fa-icon></h2>
  <div class="flex flex-col m-8">
    <label> Team ID </label>
    <input type="text" formControlName="teamId" />
  </div>

  <div class="flex flex-col m-8">
    <label> Key ID </label>
    <input type="text" formControlName="keyId" />
  </div>

  <div class="flex flex-col m-8">
    <label> P8 Certificate </label>
    <input type="file" (change)="handleFileInput($event)" />
    <div class="px-3 py-4 opacity-70 ">
      <fa-icon *ngIf="p8FileName !== ''" [icon]="'file'" ></fa-icon>
      <span class="ml-1"> {{ p8FileName }} </span>
    </div>
 
  </div>

  <div class="flex gap-2 m-8">
    <rk-button
      [variant]="'secondary'"
      [text]="'Cancel'"
      (buttonClick)="onNoClick()"
    ></rk-button>
    <rk-button [variant]="'primary'" [text]="'Save'" type="submit" (buttonClick)="onSubmit()"></rk-button>
  </div>
</form>
