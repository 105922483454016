import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthState } from '@app/core/auth/states/auth.state';
import { AppConfigApi } from '@app/features/app-creation-and-configuration/models/app-config.model';
import {
  RssTabModel,
  WebsiteTabModel,
} from '@app/features/app-creation-and-configuration/models/app-tabs.model';
import { UpdateAppConfig } from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import {
  Observable,
  Subject,
  Subscription,
  combineLatest,
  debounceTime,
  filter,
  map,
} from 'rxjs';

@Component({
  selector: 'rk-other-pages-configuration',
  templateUrl: './other-pages-configuration.component.html',
  styleUrl: './other-pages-configuration.component.scss',
})
export class OtherPagesConfigurationComponent implements OnInit, OnDestroy {
  @Input()
  type = 'rss';

  @Select(AppConfigurationState.activePageIndex)
  activePageIndex$: Observable<string>;

  @Select(AppConfigurationState.currentAppConfig)
  currentAppConfig$: Observable<AppConfigApi>;

  @Select(AuthState.userRKDomain)
  userRKDomain$: Observable<string>;

  private readonly subscriptions = new Subscription();
  private readonly rssLinkChanged = new Subject<string>();
  private readonly rkRadioSiteUrlChanged = new Subject<string>();
  private readonly websiteUrlChanged = new Subject<string>();
  createWebsiteLink$: Observable<string>;
  pageIndex: number;
  rssLink: string;
  rkRadioSiteUrl: string;
  websiteUrl: string;

  constructor(
    private readonly store: Store,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.activePageIndex$
        .pipe(filter(index => !!index))
        .subscribe(id => (this.pageIndex = Number(id))),
    );

    this.subscriptions.add(
      combineLatest([
        this.activePageIndex$.pipe(filter(index => !!index)),
        this.currentAppConfig$.pipe(filter(config => !!config)),
      ]).subscribe(([index, config]) => {
        if (this.type === 'rss') {
          const rssTab = config.onglets[index] as RssTabModel;
          this.rssLink = rssTab.urlFlux;
        } else if (this.type === 'site') {
          const websiteTab = config.onglets[index] as WebsiteTabModel;
          this.websiteUrl = websiteTab.urlSite;
        }
        this.rkRadioSiteUrl = config.urlSiteRadio;
      }),
    );

    // creates link to maketing page depending on user language
    this.createWebsiteLink$ = this.userRKDomain$.pipe(
      map(
        domain =>
          `${domain}${this.translate.instant('other-config.rk-website-create-link')}`,
      ),
    );

    // rss link changes
    this.subscriptions.add(
      this.rssLinkChanged.pipe(debounceTime(500)).subscribe(newValue => {
        this.store.dispatch(
          new UpdateAppConfig(
            ['onglets', this.pageIndex.toString(), 'urlFlux'],
            newValue,
          ),
        );
      }),
    );

    // rk radio site link changes
    this.subscriptions.add(
      this.rkRadioSiteUrlChanged.pipe(debounceTime(500)).subscribe(newValue => {
        this.store.dispatch(new UpdateAppConfig(['urlSiteRadio'], newValue));
      }),
    );

    // website link changes
    this.subscriptions.add(
      this.websiteUrlChanged.pipe(debounceTime(500)).subscribe(newValue => {
        this.store.dispatch(
          new UpdateAppConfig(
            ['onglets', this.pageIndex.toString(), 'urlSite'],
            newValue,
          ),
        );
      }),
    );
  }

  onRssLinkChanged(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.rssLinkChanged.next(target.value);
  }

  onRkRadioSiteUrlChanged(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.rkRadioSiteUrlChanged.next(target.value);
  }

  onWebsiteUrlChanged(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.websiteUrlChanged.next(target.value);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
