<div class="container w-full bg-white px-6 py-3">
  <a
    [routerLink]="'/apps'"
    class="menu-item"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <fa-icon [icon]="'gauge-high'"></fa-icon>
    <span>
      {{ 'menu.dashboard' | translate }}
    </span>
  </a>
  <a
    [routerLink]="'/notifications'"
    class="menu-item"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <fa-icon [icon]="'comment'"></fa-icon>
    <span>
      {{ 'menu.notifications' | translate }}
    </span>
  </a>
  <a [href]="configurationRoute$ | async" class="menu-item" target="_blank">
    <fa-icon [icon]="'palette'"></fa-icon>
    <span>
      {{ 'menu.configuration' | translate }}
    </span>
  </a>
  <a [href]="publicationRoute$ | async" class="menu-item" target="_blank">
    <fa-icon [icon]="'share-nodes'"></fa-icon>
    <span>
      {{ 'menu.publication' | translate }}
    </span>
  </a>
</div>
