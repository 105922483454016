import { Component } from '@angular/core';
import { UpdateAppConfig } from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'rk-icon-style-selector',
  templateUrl: './icon-style-selector.component.html',
  styleUrl: './icon-style-selector.component.scss',
})
export class IconStyleSelectorComponent {
  @Select(AppConfigurationState.iconStyle)
  iconStyle$: Observable<string>;

  constructor(private readonly store: Store) {}

  onIconStyleSelection(style: string) {
    this.store.dispatch(new UpdateAppConfig(['onglets', '0', 'iconStyle'], style));
  }
}
