<button [disabled]="disabled" [class]="getButtonClasses()" (click)="onClick($event)">
  <div class="content-container">
      <fa-icon *ngIf="icon" [icon]="icon" [class.text-hidden]="isLoading"></fa-icon>
      <span [class.text-hidden]="isLoading">{{ text }}</span>
     <!-- TODO spinner color optimized for primary button only -->
      <mat-progress-spinner
      *ngIf="isLoading"
        class="spinner"
        mode="indeterminate"
        [strokeWidth]="2"
        [diameter]="20"
      ></mat-progress-spinner>
  </div>
</button>
