import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IconPrefix } from '@fortawesome/free-brands-svg-icons';

export interface Icon {
  name: string;
  category: string;
}

export const iconCategoryMapping = {
  fab: 'fa-brands',
  far: 'fa-regular',
  fas: 'fa-solid',
};

export function convertToIconFormat(iconString: string): IconProp {
  const reverseStyleMap = Object.fromEntries(
    Object.entries(iconCategoryMapping).map(([key, value]) => [value, key]),
  );
  const parts = iconString.split(' ');
  const style = reverseStyleMap[parts[0]] as IconPrefix;
  const iconName = parts[1].slice(3);

  return [style, iconName] as IconProp;
}

export const rawIconList = {
  far: [
    'trash-can',
    'message',
    'file-lines',
    'calendar-days',
    'hand-point-right',
    'face-smile-beam',
    'face-grin-stars',
    'address-book',
    'comments',
    'paste',
    'face-grin-tongue-squint',
    'face-flushed',
    'square-caret-right',
    'square-minus',
    'compass',
    'square-caret-down',
    'face-kiss-beam',
    'lightbulb',
    'flag',
    'square-check',
    'circle-dot',
    'face-dizzy',
    'futbol',
    'pen-to-square',
    'hourglass-half',
    'eye-slash',
    'hand',
    'hand-spock',
    'face-kiss',
    'face-grin-tongue',
    'chess-bishop',
    'face-grin-wink',
    'face-grin-wide',
    'face-frown-open',
    'hand-point-up',
    'bookmark',
    'hand-point-down',
    'folder',
    'user',
    'square-caret-left',
    'star',
    'chess-knight',
    'face-laugh-squint',
    'face-laugh',
    'folder-open',
    'clipboard',
    'chess-queen',
    'hand-back-fist',
    'square-caret-up',
    'chart-bar',
    'window-restore',
    'square-plus',
    'image',
    'folder-closed',
    'lemon',
    'handshake',
    'gem',
    'circle-play',
    'circle-check',
    'circle-stop',
    'id-badge',
    'face-laugh-beam',
    'registered',
    'address-card',
    'face-tired',
    'font-awesome',
    'face-smile-wink',
    'file-word',
    'file-powerpoint',
    'envelope-open',
    'file-zipper',
    'square',
    'snowflake',
    'newspaper',
    'face-kiss-wink-heart',
    'star-half-stroke',
    'file-excel',
    'face-grin-beam',
    'object-ungroup',
    'circle-right',
    'face-rolling-eyes',
    'object-group',
    'heart',
    'face-surprise',
    'circle-pause',
    'circle',
    'circle-up',
    'file-audio',
    'file-image',
    'circle-question',
    'face-meh-blank',
    'eye',
    'face-sad-cry',
    'file-code',
    'window-maximize',
    'face-frown',
    'floppy-disk',
    'comment-dots',
    'face-grin-squint',
    'hand-pointer',
    'hand-scissors',
    'face-grin-tears',
    'calendar-xmark',
    'file-video',
    'file-pdf',
    'comment',
    'envelope',
    'hourglass',
    'calendar-check',
    'hard-drive',
    'face-grin-squint-tears',
    'rectangle-list',
    'calendar-plus',
    'circle-left',
    'money-bill-1',
    'clock',
    'keyboard',
    'closed-captioning',
    'images',
    'face-grin',
    'face-meh',
    'id-card',
    'sun',
    'face-laugh-wink',
    'circle-down',
    'thumbs-down',
    'chess-pawn',
    'credit-card',
    'bell',
    'file',
    'hospital',
    'chess-rook',
    'star-half',
    'chess-king',
    'circle-user',
    'copy',
    'share-from-square',
    'copyright',
    'map',
    'bell-slash',
    'hand-lizard',
    'face-smile',
    'hand-peace',
    'face-grin-hearts',
    'building',
    'face-grin-beam-sweat',
    'moon',
    'calendar',
    'face-grin-tongue-wink',
    'clone',
    'face-angry',
    'rectangle-xmark',
    'paper-plane',
    'life-ring',
    'face-grimace',
    'calendar-minus',
    'circle-xmark',
    'thumbs-up',
    'window-minimize',
    'square-full',
    'note-sticky',
    'face-sad-tear',
    'hand-point-left',
  ],
  fas: [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'fill-drip',
    'arrows-to-circle',
    'circle-chevron-right',
    'at',
    'trash-can',
    'text-height',
    'user-xmark',
    'stethoscope',
    'message',
    'info',
    'down-left-and-up-right-to-center',
    'explosion',
    'file-lines',
    'wave-square',
    'ring',
    'building-un',
    'dice-three',
    'calendar-days',
    'anchor-circle-check',
    'building-circle-arrow-right',
    'volleyball',
    'arrows-up-to-line',
    'sort-down',
    'circle-minus',
    'door-open',
    'right-from-bracket',
    'atom',
    'soap',
    'icons',
    'microphone-lines-slash',
    'bridge-circle-check',
    'pump-medical',
    'fingerprint',
    'hand-point-right',
    'magnifying-glass-location',
    'forward-step',
    'face-smile-beam',
    'flag-checkered',
    'football',
    'school-circle-exclamation',
    'crop',
    'angles-down',
    'users-rectangle',
    'people-roof',
    'people-line',
    'beer-mug-empty',
    'diagram-predecessor',
    'arrow-up-long',
    'fire-flame-simple',
    'person',
    'laptop',
    'file-csv',
    'menorah',
    'truck-plane',
    'record-vinyl',
    'face-grin-stars',
    'bong',
    'spaghetti-monster-flying',
    'arrow-down-up-across-line',
    'spoon',
    'jar-wheat',
    'envelopes-bulk',
    'file-circle-exclamation',
    'circle-h',
    'pager',
    'address-book',
    'strikethrough',
    'k',
    'landmark-flag',
    'pencil',
    'backward',
    'caret-right',
    'comments',
    'paste',
    'code-pull-request',
    'clipboard-list',
    'truck-ramp-box',
    'user-check',
    'vial-virus',
    'sheet-plastic',
    'blog',
    'user-ninja',
    'person-arrow-up-from-line',
    'scroll-torah',
    'broom-ball',
    'toggle-off',
    'box-archive',
    'person-drowning',
    'arrow-down-9-1',
    'face-grin-tongue-squint',
    'spray-can',
    'truck-monster',
    'w',
    'earth-africa',
    'rainbow',
    'circle-notch',
    'tablet-screen-button',
    'paw',
    'cloud',
    'trowel-bricks',
    'face-flushed',
    'hospital-user',
    'tent-arrow-left-right',
    'gavel',
    'binoculars',
    'microphone-slash',
    'box-tissue',
    'motorcycle',
    'bell-concierge',
    'pen-ruler',
    'people-arrows',
    'mars-and-venus-burst',
    'square-caret-right',
    'scissors',
    'sun-plant-wilt',
    'toilets-portable',
    'hockey-puck',
    'table',
    'magnifying-glass-arrow-right',
    'tachograph-digital',
    'users-slash',
    'clover',
    'reply',
    'star-and-crescent',
    'house-fire',
    'square-minus',
    'helicopter',
    'compass',
    'square-caret-down',
    'file-circle-question',
    'laptop-code',
    'swatchbook',
    'prescription-bottle',
    'bars',
    'people-group',
    'hourglass-end',
    'heart-crack',
    'square-up-right',
    'face-kiss-beam',
    'film',
    'ruler-horizontal',
    'people-robbery',
    'lightbulb',
    'caret-left',
    'circle-exclamation',
    'school-circle-xmark',
    'arrow-right-from-bracket',
    'circle-chevron-down',
    'unlock-keyhole',
    'cloud-showers-heavy',
    'headphones-simple',
    'sitemap',
    'circle-dollar-to-slot',
    'memory',
    'road-spikes',
    'fire-burner',
    'flag',
    'hanukiah',
    'feather',
    'volume-low',
    'comment-slash',
    'cloud-sun-rain',
    'compress',
    'wheat-awn',
    'ankh',
    'hands-holding-child',
    'asterisk',
    'square-check',
    'peseta-sign',
    'heading',
    'ghost',
    'list',
    'square-phone-flip',
    'cart-plus',
    'gamepad',
    'circle-dot',
    'face-dizzy',
    'egg',
    'house-medical-circle-xmark',
    'campground',
    'folder-plus',
    'futbol',
    'paintbrush',
    'lock',
    'gas-pump',
    'hot-tub-person',
    'map-location',
    'house-flood-water',
    'tree',
    'bridge-lock',
    'sack-dollar',
    'pen-to-square',
    'car-side',
    'share-nodes',
    'heart-circle-minus',
    'hourglass-half',
    'microscope',
    'sink',
    'bag-shopping',
    'arrow-down-z-a',
    'mitten',
    'person-rays',
    'users',
    'eye-slash',
    'flask-vial',
    'hand',
    'om',
    'worm',
    'house-circle-xmark',
    'plug',
    'chevron-up',
    'hand-spock',
    'stopwatch',
    'face-kiss',
    'bridge-circle-xmark',
    'face-grin-tongue',
    'chess-bishop',
    'face-grin-wink',
    'ear-deaf',
    'road-circle-check',
    'dice-five',
    'square-rss',
    'land-mine-on',
    'i-cursor',
    'stamp',
    'stairs',
    'i',
    'hryvnia-sign',
    'pills',
    'face-grin-wide',
    'tooth',
    'v',
    'bangladeshi-taka-sign',
    'bicycle',
    'staff-snake',
    'head-side-cough-slash',
    'truck-medical',
    'wheat-awn-circle-exclamation',
    'snowman',
    'mortar-pestle',
    'road-barrier',
    'school',
    'igloo',
    'joint',
    'angle-right',
    'horse',
    'q',
    'g',
    'notes-medical',
    'temperature-half',
    'dong-sign',
    'capsules',
    'poo-storm',
    'face-frown-open',
    'hand-point-up',
    'money-bill',
    'bookmark',
    'align-justify',
    'umbrella-beach',
    'helmet-un',
    'bullseye',
    'bacon',
    'hand-point-down',
    'arrow-up-from-bracket',
    'folder',
    'file-waveform',
    'radiation',
    'chart-simple',
    'mars-stroke',
    'vial',
    'gauge',
    'wand-magic-sparkles',
    'e',
    'pen-clip',
    'bridge-circle-exclamation',
    'user',
    'school-circle-check',
    'dumpster',
    'van-shuttle',
    'building-user',
    'square-caret-left',
    'highlighter',
    'key',
    'bullhorn',
    'globe',
    'synagogue',
    'person-half-dress',
    'road-bridge',
    'location-arrow',
    'c',
    'tablet-button',
    'building-lock',
    'pizza-slice',
    'money-bill-wave',
    'chart-area',
    'house-flag',
    'person-circle-minus',
    'ban',
    'camera-rotate',
    'spray-can-sparkles',
    'star',
    'repeat',
    'cross',
    'box',
    'venus-mars',
    'arrow-pointer',
    'maximize',
    'charging-station',
    'shapes',
    'shuffle',
    'person-running',
    'mobile-retro',
    'grip-lines-vertical',
    'spider',
    'hands-bound',
    'file-invoice-dollar',
    'plane-circle-exclamation',
    'x-ray',
    'spell-check',
    'slash',
    'computer-mouse',
    'arrow-right-to-bracket',
    'shop-slash',
    'server',
    'virus-covid-slash',
    'shop-lock',
    'hourglass-start',
    'blender-phone',
    'building-wheat',
    'person-breastfeeding',
    'right-to-bracket',
    'venus',
    'passport',
    'heart-pulse',
    'people-carry-box',
    'temperature-high',
    'microchip',
    'crown',
    'weight-hanging',
    'xmarks-lines',
    'file-prescription',
    'weight-scale',
    'user-group',
    'arrow-up-a-z',
    'chess-knight',
    'face-laugh-squint',
    'wheelchair',
    'circle-arrow-up',
    'toggle-on',
    'person-walking',
    'l',
    'fire',
    'bed-pulse',
    'shuttle-space',
    'face-laugh',
    'folder-open',
    'heart-circle-plus',
    'code-fork',
    'city',
    'microphone-lines',
    'pepper-hot',
    'unlock',
    'colon-sign',
    'headset',
    'store-slash',
    'road-circle-xmark',
    'user-minus',
    'mars-stroke-up',
    'champagne-glasses',
    'clipboard',
    'house-circle-exclamation',
    'file-arrow-up',
    'wifi',
    'bath',
    'underline',
    'user-pen',
    'signature',
    'stroopwafel',
    'bold',
    'anchor-lock',
    'building-ngo',
    'manat-sign',
    'not-equal',
    'border-top-left',
    'map-location-dot',
    'jedi',
    'square-poll-vertical',
    'mug-hot',
    'car-battery',
    'gift',
    'dice-two',
    'chess-queen',
    'glasses',
    'chess-board',
    'building-circle-check',
    'person-chalkboard',
    'mars-stroke-right',
    'hand-back-fist',
    'square-caret-up',
    'cloud-showers-water',
    'chart-bar',
    'hands-bubbles',
    'less-than-equal',
    'train',
    'eye-low-vision',
    'crow',
    'sailboat',
    'window-restore',
    'square-plus',
    'torii-gate',
    'frog',
    'bucket',
    'image',
    'microphone',
    'cow',
    'caret-up',
    'screwdriver',
    'folder-closed',
    'house-tsunami',
    'square-nfi',
    'arrow-up-from-ground-water',
    'martini-glass',
    'rotate-left',
    'table-columns',
    'lemon',
    'head-side-mask',
    'handshake',
    'gem',
    'dolly',
    'smoking',
    'minimize',
    'monument',
    'snowplow',
    'angles-right',
    'cannabis',
    'circle-play',
    'tablets',
    'ethernet',
    'euro-sign',
    'chair',
    'circle-check',
    'circle-stop',
    'compass-drafting',
    'plate-wheat',
    'icicles',
    'person-shelter',
    'neuter',
    'id-badge',
    'marker',
    'face-laugh-beam',
    'helicopter-symbol',
    'universal-access',
    'circle-chevron-up',
    'lari-sign',
    'volcano',
    'person-walking-dashed-line-arrow-right',
    'sterling-sign',
    'viruses',
    'square-person-confined',
    'user-tie',
    'arrow-down-long',
    'tent-arrow-down-to-line',
    'certificate',
    'reply-all',
    'suitcase',
    'person-skating',
    'filter-circle-dollar',
    'camera-retro',
    'circle-arrow-down',
    'file-import',
    'square-arrow-up-right',
    'box-open',
    'scroll',
    'spa',
    'location-pin-lock',
    'pause',
    'hill-avalanche',
    'temperature-empty',
    'bomb',
    'registered',
    'address-card',
    'scale-unbalanced-flip',
    'subscript',
    'diamond-turn-right',
    'burst',
    'house-laptop',
    'face-tired',
    'money-bills',
    'smog',
    'crutch',
    'font-awesome',
    'cloud-arrow-up',
    'palette',
    'arrows-turn-right',
    'vest',
    'ferry',
    'arrows-down-to-people',
    'seedling',
    'left-right',
    'boxes-packing',
    'circle-arrow-left',
    'group-arrows-rotate',
    'bowl-food',
    'candy-cane',
    'arrow-down-wide-short',
    'cloud-bolt',
    'text-slash',
    'face-smile-wink',
    'file-word',
    'file-powerpoint',
    'arrows-left-right',
    'house-lock',
    'cloud-arrow-down',
    'children',
    'chalkboard',
    'user-large-slash',
    'envelope-open',
    'handshake-simple-slash',
    'mattress-pillow',
    'guarani-sign',
    'arrows-rotate',
    'fire-extinguisher',
    'cruzeiro-sign',
    'greater-than-equal',
    'shield-halved',
    'book-atlas',
    'virus',
    'envelope-circle-check',
    'layer-group',
    'arrows-to-dot',
    'archway',
    'heart-circle-check',
    'house-chimney-crack',
    'file-zipper',
    'square',
    'martini-glass-empty',
    'couch',
    'cedi-sign',
    'italic',
    'church',
    'comments-dollar',
    'democrat',
    'z',
    'person-skiing',
    'road-lock',
    'a',
    'temperature-arrow-down',
    'feather-pointed',
    'p',
    'snowflake',
    'newspaper',
    'rectangle-ad',
    'circle-arrow-right',
    'filter-circle-xmark',
    'locust',
    'sort',
    'list-ol',
    'person-dress-burst',
    'money-check-dollar',
    'vector-square',
    'bread-slice',
    'language',
    'face-kiss-wink-heart',
    'filter',
    'question',
    'file-signature',
    'up-down-left-right',
    'house-chimney-user',
    'hand-holding-heart',
    'puzzle-piece',
    'money-check',
    'star-half-stroke',
    'code',
    'whiskey-glass',
    'building-circle-exclamation',
    'magnifying-glass-chart',
    'arrow-up-right-from-square',
    'cubes-stacked',
    'won-sign',
    'virus-covid',
    'austral-sign',
    'f',
    'leaf',
    'road',
    'taxi',
    'person-circle-plus',
    'chart-pie',
    'bolt-lightning',
    'sack-xmark',
    'file-excel',
    'file-contract',
    'fish-fins',
    'building-flag',
    'face-grin-beam',
    'object-ungroup',
    'poop',
    'location-pin',
    'kaaba',
    'toilet-paper',
    'helmet-safety',
    'eject',
    'circle-right',
    'plane-circle-check',
    'face-rolling-eyes',
    'object-group',
    'chart-line',
    'mask-ventilator',
    'arrow-right',
    'signs-post',
    'cash-register',
    'person-circle-question',
    'h',
    'tarp',
    'screwdriver-wrench',
    'arrows-to-eye',
    'plug-circle-bolt',
    'heart',
    'mars-and-venus',
    'house-user',
    'dumpster-fire',
    'house-crack',
    'martini-glass-citrus',
    'face-surprise',
    'bottle-water',
    'circle-pause',
    'toilet-paper-slash',
    'apple-whole',
    'kitchen-set',
    'r',
    'temperature-quarter',
    'cube',
    'bitcoin-sign',
    'shield-dog',
    'solar-panel',
    'lock-open',
    'elevator',
    'money-bill-transfer',
    'money-bill-trend-up',
    'house-flood-water-circle-arrow-right',
    'square-poll-horizontal',
    'circle',
    'backward-fast',
    'recycle',
    'user-astronaut',
    'plane-slash',
    'trademark',
    'basketball',
    'satellite-dish',
    'circle-up',
    'mobile-screen-button',
    'volume-high',
    'users-rays',
    'wallet',
    'clipboard-check',
    'file-audio',
    'burger',
    'wrench',
    'bugs',
    'rupee-sign',
    'file-image',
    'circle-question',
    'plane-departure',
    'handshake-slash',
    'book-bookmark',
    'code-branch',
    'hat-cowboy',
    'bridge',
    'phone-flip',
    'truck-front',
    'cat',
    'anchor-circle-exclamation',
    'truck-field',
    'route',
    'clipboard-question',
    'panorama',
    'comment-medical',
    'teeth-open',
    'file-circle-minus',
    'tags',
    'wine-glass',
    'forward-fast',
    'face-meh-blank',
    'square-parking',
    'house-signal',
    'bars-progress',
    'faucet-drip',
    'cart-flatbed',
    'ban-smoking',
    'terminal',
    'mobile-button',
    'house-medical-flag',
    'basket-shopping',
    'tape',
    'bus-simple',
    'eye',
    'face-sad-cry',
    'audio-description',
    'person-military-to-person',
    'file-shield',
    'user-slash',
    'pen',
    'tower-observation',
    'file-code',
    'signal',
    'bus',
    'heart-circle-xmark',
    'house-chimney',
    'window-maximize',
    'face-frown',
    'prescription',
    'shop',
    'floppy-disk',
    'vihara',
    'scale-unbalanced',
    'sort-up',
    'comment-dots',
    'plant-wilt',
    'diamond',
    'face-grin-squint',
    'hand-holding-dollar',
    'bacterium',
    'hand-pointer',
    'drum-steelpan',
    'hand-scissors',
    'hands-praying',
    'arrow-rotate-right',
    'biohazard',
    'location-crosshairs',
    'mars-double',
    'child-dress',
    'users-between-lines',
    'lungs-virus',
    'face-grin-tears',
    'phone',
    'calendar-xmark',
    'child-reaching',
    'head-side-virus',
    'user-gear',
    'arrow-up-1-9',
    'door-closed',
    'shield-virus',
    'dice-six',
    'mosquito-net',
    'bridge-water',
    'person-booth',
    'text-width',
    'hat-wizard',
    'pen-fancy',
    'person-digging',
    'trash',
    'gauge-simple',
    'book-medical',
    'poo',
    'quote-right',
    'shirt',
    'cubes',
    'divide',
    'tenge-sign',
    'headphones',
    'hands-holding',
    'hands-clapping',
    'republican',
    'arrow-left',
    'person-circle-xmark',
    'ruler',
    'align-left',
    'dice-d6',
    'restroom',
    'j',
    'users-viewfinder',
    'file-video',
    'up-right-from-square',
    'table-cells',
    'file-pdf',
    'book-bible',
    'o',
    'suitcase-medical',
    'user-secret',
    'otter',
    'person-dress',
    'comment-dollar',
    'business-time',
    'table-cells-large',
    'book-tanakh',
    'phone-volume',
    'hat-cowboy-side',
    'clipboard-user',
    'child',
    'lira-sign',
    'satellite',
    'plane-lock',
    'tag',
    'comment',
    'cake-candles',
    'envelope',
    'angles-up',
    'paperclip',
    'arrow-right-to-city',
    'ribbon',
    'lungs',
    'arrow-up-9-1',
    'litecoin-sign',
    'border-none',
    'circle-nodes',
    'parachute-box',
    'indent',
    'truck-field-un',
    'hourglass',
    'mountain',
    'user-doctor',
    'circle-info',
    'cloud-meatball',
    'camera',
    'square-virus',
    'meteor',
    'car-on',
    'sleigh',
    'arrow-down-1-9',
    'hand-holding-droplet',
    'water',
    'calendar-check',
    'braille',
    'prescription-bottle-medical',
    'landmark',
    'truck',
    'crosshairs',
    'person-cane',
    'tent',
    'vest-patches',
    'check-double',
    'arrow-down-a-z',
    'money-bill-wheat',
    'cookie',
    'arrow-rotate-left',
    'hard-drive',
    'face-grin-squint-tears',
    'dumbbell',
    'rectangle-list',
    'tarp-droplet',
    'house-medical-circle-check',
    'person-skiing-nordic',
    'calendar-plus',
    'plane-arrival',
    'circle-left',
    'train-subway',
    'chart-gantt',
    'indian-rupee-sign',
    'crop-simple',
    'money-bill-1',
    'left-long',
    'dna',
    'virus-slash',
    'minus',
    'chess',
    'arrow-left-long',
    'plug-circle-check',
    'street-view',
    'franc-sign',
    'volume-off',
    'hands-asl-interpreting',
    'gear',
    'droplet-slash',
    'mosque',
    'mosquito',
    'star-of-david',
    'person-military-rifle',
    'cart-shopping',
    'vials',
    'plug-circle-plus',
    'place-of-worship',
    'grip-vertical',
    'arrow-turn-up',
    'u',
    'square-root-variable',
    'clock',
    'backward-step',
    'pallet',
    'faucet',
    'baseball-bat-ball',
    's',
    'timeline',
    'keyboard',
    'caret-down',
    'house-chimney-medical',
    'temperature-three-quarters',
    'mobile-screen',
    'plane-up',
    'piggy-bank',
    'battery-half',
    'mountain-city',
    'coins',
    'khanda',
    'sliders',
    'folder-tree',
    'network-wired',
    'map-pin',
    'hamsa',
    'cent-sign',
    'flask',
    'person-pregnant',
    'wand-sparkles',
    'ellipsis-vertical',
    'ticket',
    'power-off',
    'right-long',
    'flag-usa',
    'laptop-file',
    'tty',
    'diagram-next',
    'person-rifle',
    'house-medical-circle-exclamation',
    'closed-captioning',
    'person-hiking',
    'venus-double',
    'images',
    'calculator',
    'people-pulling',
    'n',
    'cable-car',
    'cloud-rain',
    'building-circle-xmark',
    'ship',
    'arrows-down-to-line',
    'download',
    'face-grin',
    'delete-left',
    'eye-dropper',
    'file-circle-check',
    'forward',
    'mobile',
    'face-meh',
    'align-center',
    'book-skull',
    'id-card',
    'outdent',
    'heart-circle-exclamation',
    'house',
    'calendar-week',
    'laptop-medical',
    'b',
    'file-medical',
    'dice-one',
    'kiwi-bird',
    'arrow-right-arrow-left',
    'rotate-right',
    'utensils',
    'arrow-up-wide-short',
    'mill-sign',
    'bowl-rice',
    'skull',
    'tower-broadcast',
    'truck-pickup',
    'up-long',
    'stop',
    'code-merge',
    'upload',
    'hurricane',
    'mound',
    'toilet-portable',
    'compact-disc',
    'file-arrow-down',
    'caravan',
    'shield-cat',
    'bolt',
    'glass-water',
    'oil-well',
    'vault',
    'mars',
    'toilet',
    'plane-circle-xmark',
    'yen-sign',
    'ruble-sign',
    'sun',
    'guitar',
    'face-laugh-wink',
    'horse-head',
    'bore-hole',
    'industry',
    'circle-down',
    'arrows-turn-to-dots',
    'florin-sign',
    'arrow-down-short-wide',
    'less-than',
    'angle-down',
    'car-tunnel',
    'head-side-cough',
    'grip-lines',
    'thumbs-down',
    'user-lock',
    'arrow-right-long',
    'anchor-circle-xmark',
    'ellipsis',
    'chess-pawn',
    'kit-medical',
    'person-through-window',
    'toolbox',
    'hands-holding-circle',
    'bug',
    'credit-card',
    'car',
    'hand-holding-hand',
    'book-open-reader',
    'mountain-sun',
    'arrows-left-right-to-line',
    'dice-d20',
    'truck-droplet',
    'file-circle-xmark',
    'temperature-arrow-up',
    'medal',
    'bed',
    'square-h',
    'podcast',
    'temperature-full',
    'bell',
    'superscript',
    'plug-circle-xmark',
    'star-of-life',
    'phone-slash',
    'paint-roller',
    'handshake-angle',
    'location-dot',
    'file',
    'greater-than',
    'person-swimming',
    'arrow-down',
    'droplet',
    'eraser',
    'earth-americas',
    'person-burst',
    'dove',
    'battery-empty',
    'socks',
    'inbox',
    'section',
    'gauge-high',
    'envelope-open-text',
    'hospital',
    'wine-bottle',
    'chess-rook',
    'bars-staggered',
    'dharmachakra',
    'hotdog',
    'person-walking-with-cane',
    'drum',
    'ice-cream',
    'heart-circle-bolt',
    'fax',
    'paragraph',
    'check-to-slot',
    'star-half',
    'boxes-stacked',
    'link',
    'ear-listen',
    'tree-city',
    'play',
    'font',
    'rupiah-sign',
    'magnifying-glass',
    'table-tennis-paddle-ball',
    'person-dots-from-line',
    'trash-can-arrow-up',
    'naira-sign',
    'cart-arrow-down',
    'walkie-talkie',
    'file-pen',
    'receipt',
    'square-pen',
    'suitcase-rolling',
    'person-circle-exclamation',
    'chevron-down',
    'battery-full',
    'skull-crossbones',
    'code-compare',
    'list-ul',
    'school-lock',
    'tower-cell',
    'down-long',
    'ranking-star',
    'chess-king',
    'person-harassing',
    'brazilian-real-sign',
    'landmark-dome',
    'arrow-up',
    'tv',
    'shrimp',
    'list-check',
    'jug-detergent',
    'circle-user',
    'user-shield',
    'wind',
    'car-burst',
    'y',
    'person-snowboarding',
    'truck-fast',
    'fish',
    'user-graduate',
    'circle-half-stroke',
    'clapperboard',
    'circle-radiation',
    'baseball',
    'jet-fighter-up',
    'diagram-project',
    'copy',
    'volume-xmark',
    'hand-sparkles',
    'grip',
    'share-from-square',
    'child-combatant',
    'gun',
    'square-phone',
    'plus',
    'expand',
    'computer',
    'xmark',
    'arrows-up-down-left-right',
    'chalkboard-user',
    'peso-sign',
    'building-shield',
    'baby',
    'users-line',
    'quote-left',
    'tractor',
    'trash-arrow-up',
    'arrow-down-up-lock',
    'lines-leaning',
    'ruler-combined',
    'copyright',
    'equals',
    'blender',
    'teeth',
    'shekel-sign',
    'map',
    'rocket',
    'photo-film',
    'folder-minus',
    'store',
    'arrow-trend-up',
    'plug-circle-minus',
    'sign-hanging',
    'bezier-curve',
    'bell-slash',
    'tablet',
    'school-flag',
    'fill',
    'angle-up',
    'drumstick-bite',
    'holly-berry',
    'chevron-left',
    'bacteria',
    'hand-lizard',
    'notdef',
    'disease',
    'briefcase-medical',
    'genderless',
    'chevron-right',
    'retweet',
    'car-rear',
    'pump-soap',
    'video-slash',
    'battery-quarter',
    'radio',
    'baby-carriage',
    'traffic-light',
    'thermometer',
    'vr-cardboard',
    'hand-middle-finger',
    'percent',
    'truck-moving',
    'glass-water-droplet',
    'display',
    'face-smile',
    'thumbtack',
    'trophy',
    'person-praying',
    'hammer',
    'hand-peace',
    'rotate',
    'spinner',
    'robot',
    'peace',
    'gears',
    'warehouse',
    'arrow-up-right-dots',
    'splotch',
    'face-grin-hearts',
    'dice-four',
    'sim-card',
    'transgender',
    'mercury',
    'arrow-turn-down',
    'person-falling-burst',
    'award',
    'ticket-simple',
    'building',
    'angles-left',
    'qrcode',
    'clock-rotate-left',
    'face-grin-beam-sweat',
    'file-export',
    'shield',
    'arrow-up-short-wide',
    'house-medical',
    'golf-ball-tee',
    'circle-chevron-left',
    'house-chimney-window',
    'pen-nib',
    'tent-arrow-turn-left',
    'tents',
    'wand-magic',
    'dog',
    'carrot',
    'moon',
    'wine-glass-empty',
    'cheese',
    'yin-yang',
    'music',
    'code-commit',
    'temperature-low',
    'person-biking',
    'broom',
    'shield-heart',
    'gopuram',
    'earth-oceania',
    'square-xmark',
    'hashtag',
    'up-right-and-down-left-from-center',
    'oil-can',
    't',
    'hippo',
    'chart-column',
    'infinity',
    'vial-circle-check',
    'person-arrow-down-to-line',
    'voicemail',
    'fan',
    'person-walking-luggage',
    'up-down',
    'cloud-moon-rain',
    'calendar',
    'trailer',
    'bahai',
    'sd-card',
    'dragon',
    'shoe-prints',
    'circle-plus',
    'face-grin-tongue-wink',
    'hand-holding',
    'plug-circle-exclamation',
    'link-slash',
    'clone',
    'person-walking-arrow-loop-left',
    'arrow-up-z-a',
    'fire-flame-curved',
    'tornado',
    'file-circle-plus',
    'book-quran',
    'anchor',
    'border-all',
    'face-angry',
    'cookie-bite',
    'arrow-trend-down',
    'rss',
    'draw-polygon',
    'scale-balanced',
    'gauge-simple-high',
    'shower',
    'desktop',
    'm',
    'table-list',
    'comment-sms',
    'book',
    'user-plus',
    'check',
    'battery-three-quarters',
    'house-circle-check',
    'angle-left',
    'diagram-successor',
    'truck-arrow-right',
    'arrows-split-up-and-left',
    'hand-fist',
    'cloud-moon',
    'briefcase',
    'person-falling',
    'image-portrait',
    'user-tag',
    'rug',
    'earth-europe',
    'cart-flatbed-suitcase',
    'rectangle-xmark',
    'baht-sign',
    'book-open',
    'book-journal-whills',
    'handcuffs',
    'triangle-exclamation',
    'database',
    'share',
    'bottle-droplet',
    'mask-face',
    'hill-rockslide',
    'right-left',
    'paper-plane',
    'road-circle-exclamation',
    'dungeon',
    'align-right',
    'money-bill-1-wave',
    'life-ring',
    'hands',
    'calendar-day',
    'water-ladder',
    'arrows-up-down',
    'face-grimace',
    'wheelchair-move',
    'turn-down',
    'person-walking-arrow-right',
    'square-envelope',
    'dice',
    'bowling-ball',
    'brain',
    'bandage',
    'calendar-minus',
    'circle-xmark',
    'gifts',
    'hotel',
    'earth-asia',
    'id-card-clip',
    'magnifying-glass-plus',
    'thumbs-up',
    'user-clock',
    'hand-dots',
    'file-invoice',
    'window-minimize',
    'mug-saucer',
    'brush',
    'mask',
    'magnifying-glass-minus',
    'ruler-vertical',
    'user-large',
    'train-tram',
    'user-nurse',
    'syringe',
    'cloud-sun',
    'stopwatch-20',
    'square-full',
    'magnet',
    'jar',
    'note-sticky',
    'bug-slash',
    'arrow-up-from-water-pump',
    'bone',
    'user-injured',
    'face-sad-tear',
    'plane',
    'tent-arrows-down',
    'exclamation',
    'arrows-spin',
    'print',
    'turkish-lira-sign',
    'dollar-sign',
    'x',
    'magnifying-glass-dollar',
    'users-gear',
    'person-military-pointing',
    'building-columns',
    'umbrella',
    'trowel',
    'd',
    'stapler',
    'masks-theater',
    'kip-sign',
    'hand-point-left',
    'handshake-simple',
    'jet-fighter',
    'square-share-nodes',
    'barcode',
    'plus-minus',
    'video',
    'graduation-cap',
    'hand-holding-medical',
    'person-circle-check',
    'turn-up',
  ],
  fab: [
    'monero',
    'hooli',
    'yelp',
    'cc-visa',
    'lastfm',
    'shopware',
    'creative-commons-nc',
    'aws',
    'redhat',
    'yoast',
    'cloudflare',
    'ups',
    'pixiv',
    'wpexplorer',
    'dyalog',
    'bity',
    'stackpath',
    'buysellads',
    'first-order',
    'modx',
    'guilded',
    'vnv',
    'square-js',
    'microsoft',
    'qq',
    'orcid',
    'java',
    'invision',
    'creative-commons-pd-alt',
    'centercode',
    'glide-g',
    'drupal',
    'hire-a-helper',
    'creative-commons-by',
    'unity',
    'whmcs',
    'rocketchat',
    'vk',
    'untappd',
    'mailchimp',
    'css3-alt',
    'square-reddit',
    'vimeo-v',
    'contao',
    'square-font-awesome',
    'deskpro',
    'brave',
    'sistrix',
    'square-instagram',
    'battle-net',
    'the-red-yeti',
    'square-hacker-news',
    'edge',
    'threads',
    'napster',
    'square-snapchat',
    'google-plus-g',
    'artstation',
    'markdown',
    'sourcetree',
    'google-plus',
    'diaspora',
    'foursquare',
    'stack-overflow',
    'github-alt',
    'phoenix-squadron',
    'pagelines',
    'algolia',
    'red-river',
    'creative-commons-sa',
    'safari',
    'google',
    'square-font-awesome-stroke',
    'atlassian',
    'linkedin-in',
    'digital-ocean',
    'nimblr',
    'chromecast',
    'evernote',
    'hacker-news',
    'creative-commons-sampling',
    'adversal',
    'creative-commons',
    'watchman-monitoring',
    'fonticons',
    'weixin',
    'shirtsinbulk',
    'codepen',
    'git-alt',
    'lyft',
    'rev',
    'windows',
    'wizards-of-the-coast',
    'square-viadeo',
    'meetup',
    'centos',
    'adn',
    'cloudsmith',
    'opensuse',
    'pied-piper-alt',
    'square-dribbble',
    'codiepie',
    'node',
    'mix',
    'steam',
    'cc-apple-pay',
    'scribd',
    'debian',
    'openid',
    'instalod',
    'expeditedssl',
    'sellcast',
    'square-twitter',
    'r-project',
    'delicious',
    'freebsd',
    'vuejs',
    'accusoft',
    'ioxhost',
    'fonticons-fi',
    'app-store',
    'cc-mastercard',
    'itunes-note',
    'golang',
    'kickstarter',
    'grav',
    'weibo',
    'uncharted',
    'firstdraft',
    'square-youtube',
    'wikipedia-w',
    'wpressr',
    'angellist',
    'galactic-republic',
    'nfc-directional',
    'skype',
    'joget',
    'fedora',
    'stripe-s',
    'meta',
    'laravel',
    'hotjar',
    'bluetooth-b',
    'square-letterboxd',
    'sticker-mule',
    'creative-commons-zero',
    'hips',
    'behance',
    'reddit',
    'discord',
    'chrome',
    'app-store-ios',
    'cc-discover',
    'wpbeginner',
    'confluence',
    'shoelace',
    'mdb',
    'dochub',
    'accessible-icon',
    'ebay',
    'amazon',
    'unsplash',
    'yarn',
    'square-steam',
    '500px',
    'square-vimeo',
    'asymmetrik',
    'font-awesome',
    'gratipay',
    'apple',
    'hive',
    'gitkraken',
    'keybase',
    'apple-pay',
    'padlet',
    'amazon-pay',
    'square-github',
    'stumbleupon',
    'fedex',
    'phoenix-framework',
    'shopify',
    'neos',
    'square-threads',
    'hackerrank',
    'researchgate',
    'swift',
    'angular',
    'speakap',
    'angrycreative',
    'y-combinator',
    'empire',
    'envira',
    'google-scholar',
    'square-gitlab',
    'studiovinari',
    'pied-piper',
    'wordpress',
    'product-hunt',
    'firefox',
    'linode',
    'goodreads',
    'square-odnoklassniki',
    'jsfiddle',
    'sith',
    'themeisle',
    'page4',
    'hashnode',
    'react',
    'cc-paypal',
    'squarespace',
    'cc-stripe',
    'creative-commons-share',
    'bitcoin',
    'keycdn',
    'opera',
    'itch-io',
    'umbraco',
    'galactic-senate',
    'ubuntu',
    'draft2digital',
    'stripe',
    'houzz',
    'gg',
    'dhl',
    'square-pinterest',
    'xing',
    'blackberry',
    'creative-commons-pd',
    'playstation',
    'quinscape',
    'less',
    'blogger-b',
    'opencart',
    'vine',
    'signal-messenger',
    'paypal',
    'gitlab',
    'typo3',
    'reddit-alien',
    'yahoo',
    'dailymotion',
    'affiliatetheme',
    'pied-piper-pp',
    'bootstrap',
    'odnoklassniki',
    'nfc-symbol',
    'mintbit',
    'ethereum',
    'speaker-deck',
    'creative-commons-nc-eu',
    'patreon',
    'avianex',
    'ello',
    'gofore',
    'bimobject',
    'brave-reverse',
    'facebook-f',
    'square-google-plus',
    'mandalorian',
    'first-order-alt',
    'osi',
    'google-wallet',
    'd-and-d-beyond',
    'periscope',
    'fulcrum',
    'cloudscale',
    'forumbee',
    'mizuni',
    'schlix',
    'square-xing',
    'bandcamp',
    'wpforms',
    'cloudversify',
    'usps',
    'megaport',
    'magento',
    'spotify',
    'optin-monster',
    'fly',
    'aviato',
    'itunes',
    'cuttlefish',
    'blogger',
    'flickr',
    'viber',
    'soundcloud',
    'digg',
    'tencent-weibo',
    'letterboxd',
    'symfony',
    'maxcdn',
    'etsy',
    'facebook-messenger',
    'audible',
    'think-peaks',
    'bilibili',
    'erlang',
    'x-twitter',
    'cotton-bureau',
    'dashcube',
    '42-group',
    'stack-exchange',
    'elementor',
    'square-pied-piper',
    'creative-commons-nd',
    'palfed',
    'superpowers',
    'resolving',
    'xbox',
    'searchengin',
    'tiktok',
    'square-facebook',
    'renren',
    'linux',
    'glide',
    'linkedin',
    'hubspot',
    'deploydog',
    'twitch',
    'ravelry',
    'mixer',
    'square-lastfm',
    'vimeo',
    'mendeley',
    'uniregistry',
    'figma',
    'creative-commons-remix',
    'cc-amazon-pay',
    'dropbox',
    'instagram',
    'cmplid',
    'upwork',
    'facebook',
    'gripfire',
    'jedi-order',
    'uikit',
    'fort-awesome-alt',
    'phabricator',
    'ussunnah',
    'earlybirds',
    'trade-federation',
    'autoprefixer',
    'whatsapp',
    'slideshare',
    'google-play',
    'viadeo',
    'line',
    'google-drive',
    'servicestack',
    'simplybuilt',
    'bitbucket',
    'imdb',
    'deezer',
    'raspberry-pi',
    'jira',
    'docker',
    'screenpal',
    'bluetooth',
    'gitter',
    'd-and-d',
    'microblog',
    'cc-diners-club',
    'gg-circle',
    'pied-piper-hat',
    'kickstarter-k',
    'yandex',
    'readme',
    'html5',
    'sellsy',
    'sass',
    'wirsindhandwerk',
    'buromobelexperte',
    'salesforce',
    'octopus-deploy',
    'medapps',
    'ns8',
    'pinterest-p',
    'apper',
    'fort-awesome',
    'waze',
    'cc-jcb',
    'snapchat',
    'fantasy-flight-games',
    'rust',
    'wix',
    'square-behance',
    'supple',
    'webflow',
    'rebel',
    'css3',
    'staylinked',
    'kaggle',
    'space-awesome',
    'deviantart',
    'cpanel',
    'goodreads-g',
    'square-git',
    'square-tumblr',
    'trello',
    'creative-commons-nc-jp',
    'get-pocket',
    'perbyte',
    'grunt',
    'weebly',
    'connectdevelop',
    'leanpub',
    'black-tie',
    'themeco',
    'python',
    'android',
    'bots',
    'free-code-camp',
    'hornbill',
    'js',
    'ideal',
    'git',
    'dev',
    'sketch',
    'yandex-international',
    'cc-amex',
    'uber',
    'github',
    'php',
    'alipay',
    'youtube',
    'skyatlas',
    'firefox-browser',
    'replyd',
    'suse',
    'jenkins',
    'twitter',
    'rockrms',
    'pinterest',
    'buffer',
    'npm',
    'yammer',
    'btc',
    'dribbble',
    'stumbleupon-circle',
    'internet-explorer',
    'stubber',
    'telegram',
    'old-republic',
    'odysee',
    'square-whatsapp',
    'node-js',
    'edge-legacy',
    'slack',
    'medrt',
    'usb',
    'tumblr',
    'vaadin',
    'quora',
    'square-x-twitter',
    'reacteurope',
    'medium',
    'amilia',
    'mixcloud',
    'flipboard',
    'viacoin',
    'critical-role',
    'sitrox',
    'discourse',
    'joomla',
    'mastodon',
    'airbnb',
    'wolf-pack-battalion',
    'buy-n-large',
    'gulp',
    'creative-commons-sampling-plus',
    'strava',
    'ember',
    'canadian-maple-leaf',
    'teamspeak',
    'pushed',
    'wordpress-simple',
    'nutritionix',
    'wodu',
    'google-pay',
    'intercom',
    'zhihu',
    'korvue',
    'pix',
    'steam-symbol',
  ],
};

export const iconList = Object.entries(rawIconList).flatMap(([category, icons]) =>
  icons.map((name: string) => ({
    name,
    category,
  })),
);
