<div class="px-6 pt-6 settings-panel-list-container">
  <div
    *ngFor="let item of settingsList"
    class="settings-list-item flex justify-between items-center"
    [class.selected]="item.value === (activeSettingsCategory$ | async)"
    [class.pages-selected]="
      item.value === 'pages' && item.value === (activeSettingsCategory$ | async)
    "
    (click)="onSelectItem(item.value)"
  >
    <div class="">
      <fa-icon [icon]="item.icon"></fa-icon>
      <span> {{ item.translationKey | translate }}</span>
    </div>
    @if ((item.value) !== 'pages') {
      <fa-icon icon="pen" class="pen text-sm"></fa-icon>
    }
  </div>
  <rk-pages-list></rk-pages-list>
</div>
