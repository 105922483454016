<ng-container *ngIf="isLoading$ | async; else showContent">
  <div class="flex justify-center items-center w-full h-full">
    <mat-progress-spinner
      mode="indeterminate"
      [strokeWidth]="5"
      [diameter]="50"
    ></mat-progress-spinner>
  </div>
</ng-container>

<ng-template #showContent>
  <div
    class="w-full h-full flex flex-col md:flex-row gap-8 notification-dashboard-container"
    [@fadeInOut]
  >
    <div class="w-full h-full flex flex-col">
      <div class="w-full md:mb-8">
        <!-- Header -->
        <div
          class="flex flex-col h-full notification-dashboard-title-container md:flex-row md:items-end md:justify-between p-6 md:p-0"
        >
          <div class="">
            <!-- Title -->
            <h1 class="notification-dashboard-title">
              {{ 'notification-dashboard.title' | translate }}
            </h1>
            <div class="opacity-70 font-medium md:block hidden">
              {{
                'notification-dashboard.total'
                  | translate : { count: (totalNumber$ | async) }
              }}
            </div>
          </div>
          <!-- Create notification button -->
          <div class="hidden md:block">
            <rk-button
              *ngIf="(isOpenFormButtonVisible$ | async)"
              [@quickFadeInOut]
              class="h-full"
              (buttonClick)="openForm()"
              [text]="'notification-dashboard.create' | translate"
            >
            </rk-button>
          </div>
        </div>
      </div>
      <!-- Table container -->
      <rk-notification-table ></rk-notification-table>
    </div>
    <!-- Form container -->
    <rk-notification-form
      *ngIf="isFormVisible$ | async"
      class="h-full"
      [@slideInOut]
    ></rk-notification-form>
  </div>
</ng-template>
