<div class="p-6">
  <h1 class="text-xl font-bold mb-5">
    {{ 'player-content.icon-and-name' | translate }}
  </h1>
  <rk-page-icon-and-name-editor [pageIndex]="0"></rk-page-icon-and-name-editor>
  <rk-page-name-error></rk-page-name-error>
</div>
<rk-setting-divider></rk-setting-divider>
<rk-setting-container [name]="'player-content.stream' | translate">
  <rk-stream-list></rk-stream-list>
</rk-setting-container>
<rk-setting-divider></rk-setting-divider>
<rk-player-buttons></rk-player-buttons>
