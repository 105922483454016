import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResetPagination } from '@app/features/notifications/states/notifications.actions';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, tap } from 'rxjs';
import { AuthState } from '../auth/states/auth.state';
import { App } from '../models/app.model';
import { AppsListService } from '../services/apps-list.service';
import { SwloggerService } from '../services/swlogger.service';
import {
  FetchAppsEmptyError,
  FetchAppsError,
  FetchAppsRequest,
  FetchAppsSuccess,
  ResetAppState,
  ResetCurrentApp,
  SetCurrentApp,
  SetCurrentAppAsAdmin,
  ShowAppList,
  ShowMainContainerPadding,
  ShowMainMenu,
  ShowReturnToDashboardLink,
  UpdateAvailable,
} from './app.actions';

export class AppStateModel {
  apps: App[];
  isLoading: boolean;
  currentAppId: number;
  currentApp: App;
  currentAppAsAdmin: App;
  hasUpdateAvailable: boolean;
  showAppList: boolean;
  showReturnToDashboardLink: boolean;
  showMainMenu: boolean;
  showMainContainerPadding: boolean;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    apps: null,
    isLoading: true,
    currentAppId: null,
    currentApp: null,
    currentAppAsAdmin: null,
    hasUpdateAvailable: false,
    showAppList: false,
    showReturnToDashboardLink: false,
    showMainMenu: false,
    showMainContainerPadding: false,
  },
})
@Injectable()
export class AppState {
  constructor(
    private readonly appsListService: AppsListService,
    private readonly swLogger: SwloggerService,
    private readonly store: Store,
    private readonly router: Router,
  ) {
    this.swLogger.watchForUpdate();
  }

  @Selector()
  static isLoading(state: AppStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  static apps(state: AppStateModel): App[] {
    return state.apps;
  }

  @Selector()
  static currentApp(state: AppStateModel): App {
    if (state.currentAppAsAdmin) {
      return state.currentAppAsAdmin;
    } else {
      return state.apps?.find(app => app.appId === Number(state.currentAppId));
    }
  }

  @Selector()
  static currentAppId(state: AppStateModel): number {
    return state.currentAppId;
  }

  @Selector()
  static hasUpdateAvailable(state: AppStateModel): boolean {
    return state.hasUpdateAvailable;
  }

  @Selector()
  static showMainMenu(state: AppStateModel): boolean {
    return state.showMainMenu;
  }

  @Selector()
  static showAppList(state: AppStateModel): boolean {
    return state.showAppList;
  }

  @Selector()
  static showReturnToDashboardLink(state: AppStateModel): boolean {
    return state.showReturnToDashboardLink;
  }

  @Selector()
  static showMainContainerPadding(state: AppStateModel): boolean {
    return state.showMainContainerPadding;
  }

  @Selector()
  static isIphoneReady(state: AppStateModel): boolean {
    if (state.currentAppAsAdmin) {
      return state.currentAppAsAdmin.iPhoneReady;
    } else {
      return state.apps?.find(app => app.appId === state.currentAppId).iPhoneReady;
    }
  }

  @Selector()
  static currentAppIsNotOwnedByUser(state: AppStateModel): boolean {
    return !state.apps.some(app => app.appId === Number(state.currentAppId));
  }

  @Action(FetchAppsRequest)
  fetchAppsRequest(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      isLoading: true,
    });

    return this.appsListService.getApps().pipe(
      tap(apps => {
        ctx.dispatch(new FetchAppsSuccess(apps));
      }),
      catchError(err => {
        if (err.error.code === 404) {
          return ctx.dispatch(new FetchAppsEmptyError());
        }

        return ctx.dispatch(new FetchAppsError());
      }),
    );
  }

  @Action(FetchAppsSuccess)
  FetchAppsSuccess(ctx: StateContext<AppStateModel>, { apps }: FetchAppsSuccess) {
    ctx.patchState({
      apps,
    });

    // if no app is selected, defaults to first app in the user app list
    if (!ctx.getState().currentAppId) {
      this.store.dispatch(new SetCurrentApp(apps[0].appId));
    }
  }

  @Action([FetchAppsSuccess, FetchAppsError])
  toggleLoadingState(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      isLoading: false,
    });
  }

  @Action(FetchAppsEmptyError)
  fetchAppsEmptyError(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      isLoading: false,
    });
    const userRKDomain = this.store.selectSnapshot(AuthState.userRKDomain);
    const isAdminOrSuperuser = this.store.selectSnapshot(AuthState.isAdminOrSuperuser);
    if (!isAdminOrSuperuser) {
      window.location.href = `${userRKDomain}/on/myapps.php`;
    }
  }

  @Action(FetchAppsError)
  fetchAppsError(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      isLoading: false,
    });
  }

  @Action(SetCurrentApp)
  setCurrentApp(ctx: StateContext<AppStateModel>, { appId }: SetCurrentApp) {
    const currentState = ctx.getState();

    if (currentState.currentAppId !== appId) {
      ctx.patchState({
        currentAppId: appId,
        currentAppAsAdmin: null,
      });

      this.store.dispatch(new ResetPagination());
    }
  }

  @Action(SetCurrentAppAsAdmin)
  SetCurrentAppAsAdmin(ctx: StateContext<AppStateModel>, { app }: SetCurrentAppAsAdmin) {
    ctx.patchState({
      currentAppId: app?.appId,
      currentAppAsAdmin: app,
    });
    this.store.dispatch(new ResetPagination());
  }

  @Action(UpdateAvailable)
  updateAvailable(ctx: StateContext<AppStateModel>) {
    ctx.patchState({ hasUpdateAvailable: true });
  }

  @Action(ShowMainMenu)
  showMainMenu(ctx: StateContext<AppStateModel>, { show }: ShowMainMenu) {
    ctx.patchState({ showMainMenu: show });
  }

  @Action(ShowAppList)
  showAppList(ctx: StateContext<AppStateModel>, { show }: ShowAppList) {
    ctx.patchState({ showAppList: show });
  }

  @Action(ShowReturnToDashboardLink)
  showReturnToDashboardLink(
    ctx: StateContext<AppStateModel>,
    { show }: ShowReturnToDashboardLink,
  ) {
    ctx.patchState({ showReturnToDashboardLink: show });
  }

  @Action(ShowMainContainerPadding)
  showMainContainerPadding(
    ctx: StateContext<AppStateModel>,
    { show }: ShowMainContainerPadding,
  ) {
    ctx.patchState({ showMainContainerPadding: show });
  }

  @Action(ResetAppState)
  resetAppState(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      apps: null,
      currentAppId: null,
    });
  }

  @Action(ResetCurrentApp)
  resetCurrentApp(ctx: StateContext<AppStateModel>) {
    const state = ctx.getState();
    if (state.apps) {
      ctx.patchState({
        currentAppId: ctx.getState().apps[0].appId,
      });
    } else {
      ctx.patchState({
        currentAppId: null,
      });
    }
    this.router.navigate(['/apps']);
  }
}
