import { Component } from '@angular/core';

@Component({
  selector: 'rk-mobile-access-warning',
  templateUrl: './mobile-access-warning.component.html',
  styleUrls: ['./mobile-access-warning.component.scss']
})
export class MobileAccessWarningComponent {

}
