<p class="mb-2  opacity-70">{{ 'general-configuration.button-color' | translate }}</p>
<rk-color-selector
  [opacityEnabled]="true"
  [parameter]="''"
  (colorChange)="onButtonColorChange($event)"
  [initialColor]="mainColor$ | async"
  class="mt-6"
></rk-color-selector>
<div class="mt-8">
  <p class="mb-2  opacity-70">{{ 'general-configuration.button-type' | translate }}</p>
  <div class="flex gap-4">
    <!-- primary -->
    <div
      [class.selected]="(buttonStyle$ | async) === 'primary'"
      class="design-option-container rounded p-3 button-style-selector"
      (click)="onButtonStyleSelection('primary')"
    >
      <span
        class="w-full h-full rounded flex justify-center items-center bg-brand text-brand-light"
        >{{ 'general-configuration.button-label' | translate }}</span
      >
    </div>
    <!-- secondary -->
    <div
      [class.selected]="(buttonStyle$ | async) === 'secondary'"
      class="design-option-container rounded p-3 button-style-selector"
      (click)="onButtonStyleSelection('secondary')"
    >
      <span
        class="w-full h-full rounded flex justify-center items-center bg-brand-light text-brand"
        >{{ 'general-configuration.button-label' | translate }}</span
      >
    </div>
  </div>
</div>
