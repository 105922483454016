import { AdminApp } from '@app/core/models/app.model';

const tag = '[Admin]';

export class FetchAdminAppListsRequest {
  static readonly type = `${tag} fetch admin apps list request`;
  constructor(public page: number, public query: string) {}
}

export class FetchAdminAppListsSuccess {
  static readonly type = `${tag} fetch admin apps list success`;
  constructor(public adminAppList: AdminApp[]) {}
}

export class FetchAdminAppListsError {
  static readonly type = `${tag} fetch admin apps list error`;
}

export class IncrementPage {
  static readonly type = `${tag} page increment`;
}

export class DecrementPage {
  static readonly type = `${tag} page decrement`;
}

export class PostIosKeyRequest {
  static readonly type = `${tag} post iOS key`;
  constructor(public appId: number, public teamId: string, public keyId: string) {}
}

export class PostIosKeySuccess {
  static readonly type = `${tag} post iOS key success`;
}

export class PostIosKeyError {
  static readonly type = `${tag} post iOS key error`;
  constructor(public error: any) {}
}

export class PostP8CertRequest {
  static readonly type = `${tag} post p8 cert`;
  constructor(public appId: number, public cert: Blob) {}
}

export class PostP8CertSuccess {
  static readonly type = `${tag} post p8 cert success`;
}

export class PostP8CertError {
  static readonly type = `${tag} post p8 cert error`;
  constructor(public error: any) {}
}

export class PostAndroidApiKeyRequest {
  static readonly type = `${tag} post Android Api key`;
  constructor(public appId: number, public androidApiKey: string) {}
}

export class PostAndroidApiKeySuccess {
  static readonly type = `${tag} post Android Api key success`;
}

export class PostAndroidApiKeyError {
  static readonly type = `${tag} post Android Api key error`;
  constructor(public error: any) {}
}

export class PostAndroidArchive {
  static readonly type = `${tag} post Android archive`;
  constructor(public appId: number, public archive: Blob) {}
}

export class PostAndroidArchiveSuccess {
  static readonly type = `${tag} post Android archive success`;
}

export class PostAndroidArchiveError {
  static readonly type = `${tag} post Android archive error`;
  constructor(public error: any) {}
}

export class DownloadAndroidArchive {
  static readonly type = `${tag} download Android archive`;
  constructor(public app: AdminApp) {}
}

export class PostAppleArchive {
  static readonly type = `${tag} post Apple archive`;
  constructor(public appId: number, public archive: Blob) {}
}

export class PostAppleArchiveSuccess {
  static readonly type = `${tag} post Apple archive success`;
}

export class PostAppleArchiveError {
  static readonly type = `${tag} post Apple archive error`;
  constructor(public error: any) {}
}

export class DownloadAppleArchive {
  static readonly type = `${tag} download Apple archive`;
  constructor(public app: AdminApp) {}
}

export class ArchiveUploadSuccess {
  static readonly type = `${tag} archive upload success`;
}
