<rk-page-configuration-container>
  <div content>
    <rk-setting-divider> </rk-setting-divider>
    <div class="p-6">
      <div class="font-bold mb-4">{{ 'html-config.content' | translate }}</div>
      <div class="border-2 border-[#f8f8f8]  rounded">
        
        <ngx-editor-menu
          [editor]="editor"
          [toolbar]="toolbar"
          [colorPresets]="colorPresets"
        >
        </ngx-editor-menu>

        <ngx-editor [editor]="editor" [formControl]="htmlContent"> </ngx-editor>
      </div>
    </div>
  </div>
  <div design>
    <div class="p-6">
      <rk-color-selector
        [parameter]="'html-config.background' | translate"
        (colorChange)="onBackgroundColorChange($event)"
        [initialColor]="backgroundColor$ | async"
      ></rk-color-selector>
    </div>
  </div>
</rk-page-configuration-container>
