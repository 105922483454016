import { DatePipe, DecimalPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { AppState } from '@app/core/states/app.state';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { ChartDataset, ChartOptions } from 'chart.js';
import { Observable, Subscription } from 'rxjs';
import { SubscribersPerDay } from '../../models/statistics.models';

@Component({
  selector: 'rk-data-chart',
  templateUrl: './data-chart.component.html',
  styleUrls: ['./data-chart.component.scss'],
})
export class DataChartComponent implements AfterViewInit, OnDestroy {
  @ViewChild('myChart') myChart: ElementRef<HTMLCanvasElement>;
  @Input() dataStream$: Observable<SubscribersPerDay[]>;
  @Input() title: string;
  @Input() unit: string;
  @Input() stepSize: number;
  @Input() lineColor?: string;

  @Select(AppState.isIphoneReady)
  isIphoneReady$: Observable<boolean>;

  private readonly subscription = new Subscription();
  chartData: ChartDataset[] = [];
  chartLabels: string[] = [];
  chartOptions: ChartOptions & { skipNull: boolean } = {
    skipNull: true,
    responsive: true,
    maintainAspectRatio: false,
    hover: {
      intersect: false,
    },
    scales: {
      y: {
        beginAtZero: true,
        grace: '15%',
        ticks: {
          precision: 1,
          color: '#7c7c7c',
          maxTicksLimit: 7,
          callback: tickvalue => {
            if (!Number.isInteger(tickvalue)) {
              return null;
            }

            return this.decimalPipe.transform(
              tickvalue,
              '1.0-0',
              this.translate.currentLang,
            );
          },
        },
        min: 0,
        grid: {
          color: ctx => (ctx.index === 0 ? '#CECECE' : '#EEECEC'),
        },
      },
      x: {
        beginAtZero: true,
        min: 0,
        offset: false,
        ticks: {
          color: '#7c7c7c',
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {},
    locale: 'fr-FR',
  };

  chartLegend = false;
  chartType = 'bar';

  constructor(
    private readonly datePipe: DatePipe,
    private readonly translate: TranslateService,
    private readonly cd: ChangeDetectorRef,
    private readonly decimalPipe: DecimalPipe,
    private readonly store: Store,
  ) {}

  ngAfterViewInit() {
    const context = this.myChart.nativeElement.getContext('2d');

    this.chartOptions.plugins.tooltip = {
      displayColors: false,
      padding: 12,
      callbacks: {
        title: () => '',
        label: tooltipContext => {
          const value = tooltipContext.raw;
          const labelUnit =
            this.unit === 'subscriber'
              ? this.translate.instant('dashboard.new-subscribers-unit', { count: value })
              : this.translate.instant('dashboard.notifications-sent-unit', {
                  count: value,
                });

          return `${value}  ${labelUnit}`;
        },
        afterLabel: () => '',
      },
      bodyFont: {
        family: 'Quicksand',
      },
    };

    this.chartOptions.locale = this.translate.currentLang;

    this.subscription.add(
      this.dataStream$.subscribe((data: SubscribersPerDay[]) => {
        let androidData: number[] = [];
        let iPhoneData: number[] = [];
        let totalData: number[] = [];
        const labels: string[] = [];

        const lastSevenDaysData = data.slice(-7);

        lastSevenDaysData.forEach(subscriber => {
          androidData.push(subscriber.android);
          iPhoneData.push(subscriber.iphone);
          totalData.push(subscriber.android + subscriber.iphone);
          const format = this.translate.currentLang === 'fr' ? 'EEEE d' : 'EEEE d';
          const formattedDate = this.datePipe.transform(
            subscriber.date,
            format,
            undefined,
            this.translate.currentLang,
          );
          labels.push(formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1));
        });

        if (data.length >= 8) {
          const eighthDay = data[data.length - 8];
          // adds the 8th day to the totalData in order to start the line one step before the bars
          totalData.unshift(eighthDay.android + eighthDay.iphone);
          // adds a null value for the 8th day to other datasets
          androidData.unshift(null);
          iPhoneData.unshift(null);
          // adds an extra empty label for the 8th day
          labels.unshift('');
          // adds an extra empty day to maintain a space between the current day and the right border of the chart
          androidData.push(null);
          iPhoneData.push(null);
          labels.push('');

          // replaces zeros with null in order to hide the line chart when value is zero
          totalData = totalData.map(value => (value === 0 ? null : value));

          // checks for edge case on the line chart when 8th day has a value and the following day is null
          if (totalData[0] !== 0 && totalData[1] === null) {
            totalData[0] = null;
          }

          // checks for edge case on the line chart  when 1st day has a value and the next day is null
          if (totalData[7] !== 0 && totalData[6] === null) {
            totalData[7] = null;
          }

          // checks for isolated non-null values and surrounds them with zeros
          // to make sure the line is still showing around isolated values
          for (let i = 1; i < totalData.length - 1; i++) {
            if (totalData[i] !== null) {
              if (
                (totalData[i - 1] === null || totalData[i - 1] === 0) &&
                (totalData[i + 1] === null || totalData[i + 1] === 0)
              ) {
                totalData[i - 1] = 0;
                totalData[i + 1] = 0;
              }
            }
          }
        }

        // transforms zeros into null on the bar graphs in order to center the remaining bar if one platform equals zero
        androidData = androidData.map(v => (v === 0 ? null : v));
        iPhoneData = iPhoneData.map(v => (v === 0 ? null : v));

        const blackGradient = context.createLinearGradient(0, 0, 0, 200);
        blackGradient.addColorStop(0, '#474747');
        blackGradient.addColorStop(1, '#252525');

        const orangeGradient = context.createLinearGradient(0, 0, 0, 200);
        orangeGradient.addColorStop(0, '#FF9150');
        orangeGradient.addColorStop(1, '#FF3648');

        const lineBackgroundGradient = context.createLinearGradient(0, 0, 0, 200);
        lineBackgroundGradient.addColorStop(0, 'rgba(255, 145, 80, 0.15)');
        lineBackgroundGradient.addColorStop(1, 'rgba(255, 145, 80, 0.00)');

        this.chartData = [
          {
            data: androidData,
            label: 'Android',
            type: 'bar',
            backgroundColor: blackGradient,
            borderRadius: 5,
            barPercentage: 1,
            maxBarThickness: 13,
            categoryPercentage: 0.3,
            order: 2
          },
        ];

        // only creates iPhone bar graph and total line graph if the current app is iPhone-ready
        const isIphoneReady = this.store.selectSnapshot(AppState.isIphoneReady);
        if (isIphoneReady) {
          this.chartData.push(
            {
              data: iPhoneData,
              label: 'iPhone',
              type: 'bar',
              backgroundColor: orangeGradient,
              borderRadius: 5,
              barPercentage: 1,
              maxBarThickness: 13,
              categoryPercentage: 0.3,
              order: 2
            },
            {
              data: totalData,
              label: 'Total',
              type: 'line',
              borderColor:
                this.lineColor === 'orangeGradient' ? orangeGradient : '#FF9050',
              borderWidth: 5,
              pointRadius: 0,
              pointHoverRadius: 8,
              hoverBorderWidth: 5,
              pointBorderColor: 'rgba(0, 0, 0, 0)',
              pointBackgroundColor: 'rgba(0, 0, 0, 0)',
              pointHoverBackgroundColor: '#FFFFFF',
              pointHoverBorderColor:
                this.lineColor === 'orangeGradient' ? orangeGradient : '#FF9050',
              backgroundColor: lineBackgroundGradient,
              fill: true,
              borderJoinStyle: 'round',
              borderCapStyle: 'round',
              order: 1
            },
          );
        }

        this.chartLabels = labels;

        this.cd.detectChanges();
      }),
    );
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
