import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SetCurrentApp } from '@app/core/states/app.actions';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import { UnsavedChangesService } from '@app/features/app-creation-and-configuration/services/unsaved-changes.service';
import {
  FetchAppConfigRequest,
  FetchUserRadiosRequest,
  PostAppConfigRequest,
} from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { SkipToStep } from '@app/features/app-creation-and-configuration/states/stepper.actions';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, filter } from 'rxjs';

@Component({
  selector: 'rk-app-configuration-shell',
  templateUrl: './app-configuration-shell.component.html',
  styleUrls: ['./app-configuration-shell.component.scss'],
})
export class AppConfigurationShellComponent implements OnInit, OnDestroy {
  @Select(AppConfigurationState.activeSettingsCategory)
  activeSettingsCategory$: Observable<string>;

  @Select(AppConfigurationState.currentAppWhmcsUserId)
  currentAppWhmcsUserId$: Observable<number>;

  @Select(AppConfigurationState.activePageType)
  activePageType$: Observable<string>;

  constructor(
    private readonly store: Store,
    private readonly route: ActivatedRoute,
    private readonly unsavedChangesService: UnsavedChangesService,
  ) {}

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.unsavedChangesService.checkForUnsavedChanges()) {
      $event.returnValue = true;
    }
  }

  private readonly subscriptions: Subscription = new Subscription();

  ngOnInit() {
    this.store.dispatch(new SkipToStep(1));

    // fetch user radios
    this.subscriptions.add(
      this.currentAppWhmcsUserId$
        .pipe(
          filter(userId => !!userId),
        )
        .subscribe(userId => {
          this.store.dispatch(new FetchUserRadiosRequest(userId));
        }),
    );

    // fetches app config
    this.subscriptions.add(
      this.route.params.subscribe(params => {
        const appId = params['appId'];
        if (appId) {
          this.store.dispatch(new FetchAppConfigRequest(appId));
          this.store.dispatch(new SetCurrentApp(Number(appId)))
        }
      }),
    );

    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: 'PageSimulatorDesignContent',
        component: 'Page',
      }),
    );
    this.store.dispatch(new LogEvent('Page Viewed'));
  }

  postAppConfig = (): void => {
    this.store.dispatch(new PostAppConfigRequest());
  };

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
