<div class="p-8 rounded-md w-full md:w-[611px]">
  <div class="mb-8">
    <h1 class="text-2xl font-bold mb-3">
      {{ 'notification-form.close-confirmation-message' | translate }}
    </h1>
    <p class="opacity-70">
      {{ 'notification-form.close-confirmation-message-details' | translate }}
    </p>
  </div>
  <div class="flex justify-between flex-col gap-2 items-start md:flex-row md:items-end">
    <rk-button
      *ngIf="isFormValid; else invalid"
      [variant]="'border-grey'"
      [text]="'notification-form.draft' | translate"
      (buttonClick)="onSaveDraft()"
    ></rk-button>
    <ng-template #invalid>
      <rk-button
        rkTooltip
        [tooltipDescription]="'notification-form.close-confirmation-tooltip' | translate"
        [tooltipPosition]="'bottom'"
        [arrowPosition]="'left'"
        [variant]="'border-grey'"
        [disabled]="true"
        [text]="'notification-form.draft' | translate"
      ></rk-button>
    </ng-template>
    <div class="flex justify-end gap-2 md:gap-4">
      <rk-button
        [text]="'notification-form.cancel' | translate"
        [variant]="'secondary'"
        (buttonClick)="onCancel()"
      ></rk-button>
      <rk-button
        [text]="'notification-form.close-confirmation-button' | translate"
        (buttonClick)="onConfirm()"
      ></rk-button>
    </div>
  </div>
</div>
