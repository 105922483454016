const tag = '[Stepper]';

export class NextStep {
  static readonly type = `${tag} NextStep`;
}

export class PreviousStep {
  static readonly type = `${tag} PreviousStep`;
}

export class SkipToStep {
  static readonly type = `${tag} skip to step`;
  constructor(public targetStep: number) {}
}

export class ShowSteps {
  static readonly type = `${tag} show main container padding`;
  constructor(public show: boolean) {}
}

export class UpdateTooltipMessage {
  static readonly type = `${tag} update tooltip message`;
  constructor(public tooltipMessage: string) {}
}
