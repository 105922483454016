import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DragDropService } from '@app/core/services/drag-drop.service';
import { AppConfigTabs } from '@app/features/app-creation-and-configuration/models/app-config.model';
import { UpdateAppConfig } from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'rk-menu-content',
  templateUrl: './menu-content.component.html',
  styleUrls: ['./menu-content.component.scss'],
})
export class MenuContentComponent implements OnInit, OnDestroy {
  @Select(AppConfigurationState.currentAppConfigPageList)
  currentAppConfigPageList$: Observable<AppConfigTabs>;

  pageList: AppConfigTabs;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store,
    private readonly dragDropService: DragDropService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.currentAppConfigPageList$
        .pipe(
          distinctUntilChanged(
            (prev, curr) =>
              prev.length === curr.length &&
              prev.every((value, index) => value.idonglets === curr[index].idonglets),
          ),
        )
        .subscribe(list => {
          this.pageList = list;
        }),
    );
  }

  dragStart() {
    this.dragDropService.startDragging();
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.currentIndex !== 0) {
      const updatedPageList = [...this.pageList];
      moveItemInArray(updatedPageList, event.previousIndex, event.currentIndex);

      this.store.dispatch(
        new UpdateAppConfig(['onglets'], updatedPageList as AppConfigTabs),
      );
    }

    this.dragDropService.stopDragging();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
