<div class="table-container flex flex-1 flex-col w-full pt-8 max-h-full rounded-xl" [@fadeInOut]>
  <!-- Header -->
  <div class="table-header w-full">
    <div class="table-date">{{ 'notification-table.date' | translate }}</div>
    <div class="table-message">{{ 'notification-table.message' | translate }}</div>
    <div class="table-number">{{ 'notification-table.reach' | translate }}</div>
    <div class="table-number">{{ 'notification-table.android' | translate }}</div>
    <div class="table-number">{{ 'notification-table.ios' | translate }}</div>
  </div>
  <!-- Body -->
  <div class="h-full overflow-auto rows-container flex flex-col">
    <!-- Rows -->
    <div class="table-lines mb-6">
      <div
        class="table-line-container flex w-full px-8"
        *ngFor="let notification of notifications$ | async"
        [class.waiting]="notification.waiting"
      >
        <!-- Row -->
        <div class="table-line w-full h-full flex">
          <!-- Date -->
          <div class="table-date flex items-center">
            <div
              *ngIf="notification.enabled; else draft"
              class="flex items-center whitespace-nowrap"
            >
              {{ formatDate(notification.sendAt) }}
            </div>
            <div class="w-[20px] h-[20px]">
              <fa-icon
                [icon]="'calendar-alt'"
                *ngIf="notification.waiting && notification.enabled"
                class="flex justify-center items-center calendar-icon"
              ></fa-icon>
            </div>
            <ng-template #draft>
              {{ 'notification-table.draft' | translate }}
            </ng-template>
          </div>
          <!-- Message -->
          <div class="table-message flex items-center">{{ notification.message }}</div>
          <!-- Total -->
          <div class="table-number flex items-center">
            {{ getTotalSent(notification) || '-' }}
          </div>
          <!-- Sent to Android -->
          <div class="table-number flex items-center">
            <span
              rkTooltip
              tooltipPosition="left"
              [tooltipDisabled]="getSentCount(notification, 'android') !== 0"
              [tooltipDescription]="'notification-table.not-sent-android' | translate"
              >{{ getSentCount(notification, 'android') || '-' }}</span
            >
            <span
              *ngIf="getSentCountPercent(notification, 'android') > 0"
              class="table-number-percentage"
              >({{
                getSentCountPercent(notification, 'android') | number : '1.0-0'
              }}
              %)</span
            >
          </div>
          <!-- Sent to iOS -->
          <div class="table-number flex items-center">
            <span
              rkTooltip
              tooltipPosition="left"
              [tooltipDisabled]="getSentCount(notification, 'iphone') !== 0"
              [tooltipDescription]="'notification-table.not-sent-ios' | translate"
              >{{ getSentCount(notification, 'iphone') || '-' }}</span
            >
            <span
              *ngIf="getSentCountPercent(notification, 'iphone') > 0"
              class="table-number-percentage"
            >
              ({{ getSentCountPercent(notification, 'iphone') | number : '1.0-0' }} %)
            </span>
          </div>
          <!-- Action buttons -->
          <div
            class="action-buttons"
            *ngIf="notification.waiting && !isSending(notification)"
          >
            <rk-button
              [icon]="'pen'"
              variant="white"
              class="action-button"
              [iconOnly]="true"
              (buttonClick)="openEditForm(notification)"
            ></rk-button>
            <rk-button
              [icon]="'trash'"
              variant="white"
              class="action-button delete-notification-button"
              [iconOnly]="true"
              (buttonClick)="deleteNotification(notification.id)"
            ></rk-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Pagination : see style overrides in the main styles.scscc file -->
    <div
      class="pagination flex items-center justify-center mt-auto mb-8"
      *ngIf="(lastPage$ | async) > 1"
    >
      <!-- Previous button -->
      <rk-button
        [variant]="'white'"
        [icon]="'chevron-left'"
        [iconOnly]="true"
        [ngClass]="(currentPage$ | async) === 1 ? 'invisible' : ''"
        (buttonClick)="decrement()"
      ></rk-button>

      <!-- Page buttons -->
      <ng-container *ngFor="let page of paginationArray">
        <ng-container *ngIf="page.label === '...'; else buttonTemplate">
          <rk-button
            class="pagination-ellipsis"
            [variant]="'white'"
            [text]="page.label"
          ></rk-button>
        </ng-container>
        <ng-template #buttonTemplate>
          <rk-button
            class="pagination-button"
            [class]="
              (currentPage$ | async) === page.value ? 'pagination-button-active' : ''
            "
            [variant]="(currentPage$ | async) === page.value ? 'primary' : 'white'"
            [text]="page.label"
            (buttonClick)="setPage(page.value)"
          ></rk-button>
        </ng-template>
      </ng-container>
      <!-- Next button -->
      <rk-button
        [variant]="'white'"
        [icon]="'chevron-right'"
        [iconOnly]="true"
        [ngClass]="(currentPage$ | async) === (lastPage$ | async) ? 'invisible' : ''"
        (buttonClick)="increment()"
      ></rk-button>
    </div>
  </div>
</div>
