import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable, Subscription, combineLatest, startWith, timer } from 'rxjs';
import { fadeInOutAnimation } from '@app/core/animations';
import * as moment from 'moment';
import { AppState } from '@app/core/states/app.state';
import {
  DecrementPage,
  DeleteNotificationRequest,
  FetchNotificationsRequest,
  IncrementPage,
  SetPage,
  OpenEditNotificationForm,
} from '../../states/notifications.actions';
import { Notification } from '../../models/notification.model';
import { NotificationsState } from '../../states/notifications.state';

interface Page {
  label: string;
  value: number | '...';
}

@Component({
  selector: 'rk-notification-table',
  templateUrl: './notification-table.component.html',
  styleUrls: ['./notification-table.component.scss'],
  animations: [fadeInOutAnimation],
})
export class NotificationTableComponent implements OnInit, OnDestroy {
  @Select(NotificationsState.notifications)
  notifications$: Observable<Notification[]>;
  @Select(NotificationsState.currentPage)
  currentPage$: Observable<number>;
  @Select(AppState.currentAppId)
  currentAppId$: Observable<number>;
  @Select(NotificationsState.lastPage)
  lastPage$: Observable<number>;

  paginationArray: Page[] = [];

  private readonly subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([
        this.currentPage$.pipe(startWith(0)),
        timer(0, 10000),
        this.currentAppId$,
      ]).subscribe(([currentPage]) => this.store.dispatch(new FetchNotificationsRequest(currentPage))),
    );
    this.subscription.add(
      combineLatest([this.lastPage$, this.currentPage$]).subscribe(
        ([lastPage, currentPage]) => {
          this.buildPaginationArray(currentPage, lastPage);
        },
      ),
    );
  }

  setPage(page: number | string): void {
    if (typeof page === 'number') {
      this.store.dispatch(new SetPage(page));
      this.scrollToTop();
    }
  }

  formatDate(date: Date): string {
    return moment(date).format('DD/MM/YY - HH:mm');
  }

  getSentCount(notification: Notification, platform: string): number {
    const process = notification.processes.find(pro => pro.platform === platform);

    return process ? process.nbSent : 0;
  }

  getTotalSent(notification: Notification): number {
    return notification.processes.reduce((total, process) => total + process.nbSent, 0);
  }

  getSentCountPercent(notification: Notification, platform: string): number {
    const platformCount = this.getSentCount(notification, platform);
    const totalCount = this.getTotalSent(notification);

    return totalCount !== 0 ? (platformCount / totalCount) * 100 : 0;
  }

  openEditForm(notification: Notification) {
    this.store.dispatch(new OpenEditNotificationForm(notification));
  }

  deleteNotification(notificationId: number) {
    this.store.dispatch(new DeleteNotificationRequest(notificationId));
  }

  increment(): void {
    this.store.dispatch(new IncrementPage());
    this.scrollToTop();
  }

  decrement(): void {
    this.store.dispatch(new DecrementPage());
    this.scrollToTop();
  }

  scrollToTop() {
    const container = this.el.nativeElement.querySelector('.rows-container');
    if (container) {
      this.renderer.setStyle(container, 'scrollBehavior', 'smooth');
      container.scrollTop = 0;
      this.renderer.removeStyle(container, 'scrollBehavior');
    }
  }

  isSending(notification: Notification): boolean {
    const now = new Date();
    const iosStatuses = notification.processes
      .filter(p => p.platform === 'iphone')
      .map(p => p.status);

    const androidStatuses = notification.processes
      .filter(p => p.platform === 'android')
      .map(p => p.status);

    const condition1 =
      notification.sendAt < now && notification.waiting && notification.enabled;

    const condition2 =
      notification.enabled &&
      notification.sendAt < now &&
      (iosStatuses.some(status => ['waiting', 'running'].includes(status)) ||
        androidStatuses.some(status => ['waiting', 'running'].includes(status)));

    return condition1 || condition2;
  }

  buildPaginationArray(currentPage: number, lastPage: number): void {
    // Reset paginationArray
    this.paginationArray = [];
    const maxSize = currentPage >= 3 ? 7 : 5;
    const halfWay = Math.ceil(maxSize / 2);
    const isStart = currentPage <= halfWay;
    const isEnd = lastPage - halfWay < currentPage;
    const isMiddle = !isStart && !isEnd;

    for (let i = 1; i <= maxSize && i <= lastPage; i++) {
      let label;
      const pageNumber = this.calculatePageNumber(i, currentPage, maxSize, lastPage);

      const openingEllipsesNeeded = i === 2 && (isMiddle || isEnd);
      const closingEllipsesNeeded = i === maxSize - 1 && (isMiddle || isStart);

      if (openingEllipsesNeeded || closingEllipsesNeeded) {
        label = '...';
      } else {
        label = pageNumber;
      }

      this.paginationArray.push({ label: label.toString(), value: pageNumber });
    }
  }

  calculatePageNumber(
    i: number,
    currentPage: number,
    maxSize: number,
    totalPages: number,
  ): number {
    const halfWay = Math.ceil(maxSize / 2);
    if (i === maxSize) {
      return totalPages;
    } else if (i === 1) {
      return i;
    } else if (maxSize < totalPages) {
      if (totalPages - halfWay < currentPage) {
        return totalPages - maxSize + i;
      } else if (halfWay < currentPage) {
        return currentPage - halfWay + i;
      } else {
        return i;
      }
    } else {
      return i;
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
