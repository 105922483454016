import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fadeInOutAnimation } from '@app/core/animations';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { AppNameInputComponent } from '@app/ui/components/app-name-input/app-name-input.component';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import {
  FetchAppNameAndLogoRequest,
  PostNameAndLogo,
} from '../../../states/app-configuration.actions';

@Component({
  selector: 'rk-create-app-form',
  templateUrl: './create-app-form.component.html',
  styleUrls: ['./create-app-form.component.scss'],
  animations: [fadeInOutAnimation],
})
export class CreateAppFormComponent implements OnInit, OnDestroy {
  @ViewChild('appNameInput') appNameInput!: AppNameInputComponent;

  @Select(AppConfigurationState.isLoading)
  isLoading$: Observable<boolean>;

  appName = '';
  appId: number | undefined;
  showWelcomeAnimation = true;
  errorCount = 0;
  nameIsAvailable = true;
  nameHasNoErrors = true;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly route: ActivatedRoute,
  ) {}

  get formIsInvalid(): boolean {
    return this.appName === '' || !this.nameHasNoErrors;
  }

  ngOnInit(): void {
    // fetching config if for route id parameter
    this.subscriptions.add(
      this.route.params.subscribe(params => {
        const appId = params['appId'];
        if (appId) {
          this.showWelcomeAnimation = false;
          this.appId = appId;
          this.store.dispatch(new FetchAppNameAndLogoRequest(appId));
        } else {
          setTimeout(() => {
            this.showWelcomeAnimation = false;
          }, 3700);
        }
      }),
    );

    this.subscriptions.add(
      this.appNameInput?.nameIsAvailable.subscribe(isAvailable => {
        this.nameIsAvailable = isAvailable;
      }),
    );

    this.subscriptions.add(
      this.appNameInput?.nameHasNoErrors.subscribe(hasNoErrors => {
        this.nameHasNoErrors = hasNoErrors;
      }),
    );

    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: 'PageSimulatorName',
        component: 'Page',
      }),
    );
    this.store.dispatch(new LogEvent('Page Viewed'));
  }

  onConfirmAppName(): void {
    if (this.appId) {
      this.store.dispatch(new PostNameAndLogo(this.appName, undefined, this.appId));
    } else {
      if (this.appName) {
        this.store.dispatch(new PostNameAndLogo(this.appName));
      }
    }
    // this.store.dispatch(new NextStep());
  }

  onAppNameChange(name: string) {
    this.appName = name;
  }

  onErrorCountChange(count: number) {
    this.errorCount = count;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
