<div class="px-6 pt-6 pb-8">
  <div class="flex justify-between items-center mb-5">
    <span class="opacity-70">{{ 'menu-design.show-logo' | translate }}</span>
    <rk-switch [isChecked]="showLogo" (toggle)="onLogoToggle($event)"></rk-switch>
  </div>
  <div *ngIf="showLogo">
    <rk-image-upload
      (fileSelected)="onImageSelected($event)"
      (imageRemoved)="removeImage()"
      [fetchedImageUrl]="currentAppConfigMenuLogo$ | async"
      [aspectRatio]="'552/248'"
      [radius]="40"
      [compactLayout]="true"
    ></rk-image-upload>
    <div class="mt-3">
      <rk-image-size-guide
        [width]="552"
        [height]="248"
        [lineBreakOption]="'second'"
      ></rk-image-size-guide>
    </div>
  </div>
</div>
<rk-setting-divider></rk-setting-divider>
<div class="p-6">
  <div class="font-bold mb-4">{{ 'menu-design.styles' | translate }}</div>
  <div class="mb-10">
    <rk-color-selector
      [parameter]="'menu-design.text-color' | translate"
      (colorChange)="onMenuTextColorChange($event)"
      [initialColor]="currentAppConfigMenuTextColor$ | async"
    ></rk-color-selector>
  </div>
  <rk-color-selector
    [parameter]="'menu-design.background' | translate"
    (colorChange)="onMenuBackgroundColorChange($event)"
    [initialColor]="currentAppConfigMenuBackgroundColor$ | async"
  ></rk-color-selector>
</div>
