import { Component, Input } from '@angular/core';
import { MenuItem } from './menu.model';

@Component({
  selector: 'rk-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Input() menuItems: MenuItem[] | undefined;
  @Input() reduced: boolean | undefined;
}
