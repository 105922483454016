import { Component } from '@angular/core';
import { UpdateAppConfig } from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'rk-margin-settings',
  templateUrl: './margin-settings.component.html',
  styleUrl: './margin-settings.component.scss',
})
export class MarginSettingsComponent {
  constructor(private readonly store: Store) {}

  @Select(AppConfigurationState.showMargins)
  showMargins$: Observable<boolean>;

  onMarginStyleSelection(show: boolean) {
    if (show) {
      this.store.dispatch(new UpdateAppConfig(['onglets', '0', 'divWithMargin'], 'YES'));
    } else {
      this.store.dispatch(new UpdateAppConfig(['onglets', '0', 'divWithMargin'], 'NO'));
    }
  }
}
