import { StreamApi } from '@app/features/app-creation-and-configuration/models/app-config.model';

export const newStreamConfig: StreamApi = {
  idflux: getRandomInt(1000),
  idapplications: null,
  idonglets: null,
  typeFlux: 'autre',
  manager: null,
  identifiant: null,
  urlFlux: "https://www.radioking.com/play/my-radio",
  nomFlux: null,
  mountPoint: null,
  radiouid: null,
  urlTitrage: "https://api.radioking.io/widget/radio/my-radio/track/current?format=text",
  typeTitrage: 'autre',
  logofluxappfield: null,
  ordre: null,
  fondTitrage: null,
  pochette: null,
  idradio: null,
  urlFluxBD: null,
  urlFluxHD: null,
};

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}
