<rk-setting-container [name]="'player-design.cover' | translate">
  <div class="opacity-70 mb-4">
    {{ 'player-design.cover-explained' | translate }}
  </div>
  <!-- defaut cover -->

  <rk-image-upload
    #uploadLogoComponent
    (fileSelected)="onImageSelected($event)"
    (imageRemoved)="onRemoveImage()"
    [aspectRatio]="'1/1'"
    [width]="'200px'"
    [compactLayout]="true"
    [technicalRequirementsText]="'player-design.constraints-body' | translate"
    [fetchedImageUrl]="currentAppConfigDefaultCover$ | async"
  ></rk-image-upload>

  <div class="mt-3">
    <rk-image-size-guide
      [width]="440"
      [height]="440"
      [lineBreakOption]="'second'"
    ></rk-image-size-guide>
  </div>

  <!-- always show defaut cover option -->
  <div class="flex justify-between items-center mt-6">
    <div class="opacity-70">
      {{ 'player-design.always-show-default-cover' | translate }}
    </div>
    <rk-switch
      [isChecked]="alwaysDefaultCover"
      (toggle)="onAlwaysShowDefaultCoverChange($event)"
    ></rk-switch>
  </div>
</rk-setting-container>
<rk-setting-divider></rk-setting-divider>
<rk-setting-container [name]="'player-design.cover-type' | translate">
  <!-- cover type -->
  <div class="opacity-70 mb-3">
    {{ 'player-design.cover-shape' | translate }}
  </div>
  <div class="flex gap-4">
    <!-- square cover -->
    <div
      class="design-option-container"
      [class.selected]="coverType === 'square'"
      (click)="selectCovertype('square')"
    >
      <div class="cover-type-item design-option-item"></div>
    </div>
    <!-- rounded square cover -->
    <div
      class="design-option-container"
      [class.selected]="coverType === 'rounded-square'"
      (click)="selectCovertype('rounded-square')"
    >
      <div class="cover-type-item design-option-item rounded"></div>
    </div>
    <!-- rounded cover -->
    <div
      class="design-option-container"
      [class.selected]="coverType === 'circle'"
      (click)="selectCovertype('circle')"
    >
      <div class="cover-type-item rounded-full design-option-item"></div>
    </div>
  </div>
  <!-- show cover cover option -->
  <div class="flex justify-between items-center mt-6">
    <div class="opacity-70">
      {{ 'player-design.show-cover-border' | translate }}
    </div>
    <rk-switch
      [isChecked]="showCoverBorder"
      (toggle)="onShowCoverBorderChange($event)"
    ></rk-switch>
  </div>
  <!--  cover border color -->
  @if (showCoverBorder) {
    <div class="mt-6 opacity-70">
      {{ 'player-design.cover-border-color' | translate }}
    </div>
    <rk-color-selector
      [opacityEnabled]="true"
      (colorChange)="onCoverBorderColorChange($event)"
      [initialColor]="coverBorderColor$ | async"
    ></rk-color-selector>
  }
</rk-setting-container>
<rk-setting-divider></rk-setting-divider>
<rk-setting-container [name]="'player-design.title-and-artist' | translate">
  <div class="mb-10">
    <div class="opacity-70">{{ 'player-design.titling-background' | translate }}</div>
    <rk-color-selector
      [opacityEnabled]="true"
      [supportsTransparentColor]="true"
      (colorChange)="onTitlingBackgroundColorChange($event)"
      [initialColor]="titlingBackgroundColor$ | async"
    ></rk-color-selector>
  </div>
  <div class="mb-10">
    <rk-color-selector
      [parameter]="'player-design.title-color' | translate"
      (colorChange)="onTitleColorChange($event)"
      [initialColor]="titleColor$ | async"
    ></rk-color-selector>
  </div>
  <div>
    <rk-color-selector
      [parameter]="'player-design.artist-color' | translate"
      (colorChange)="onArtistColorChange($event)"
      [initialColor]="artistColor$ | async"
    ></rk-color-selector>
  </div>
</rk-setting-container>
