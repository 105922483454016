<div class="p-5 rounded apple-notification-form admin-form">
  <h2 class="m-8 font-bold">
    <fa-icon [icon]="platform === 'android' ? faAndroid : faApple"> </fa-icon> Files App
    {{ data.app.appId }}
  </h2>
  <div class="flex flex-col m-8 w-full">
    <label> Télécharger les fichiers pour la génération de l'archive </label>
    <rk-button
      [variant]="'secondary'"
      [icon]="'download'"
      [iconOnly]="true"
      (buttonClick)="onDownloadFiles()"
    ></rk-button>
  </div>
  <div class="flex flex-col m-8">
    <label>
      Importer l'archive
      <fa-icon [icon]="platform === 'android' ? faAndroid : faApple"> </fa-icon>
    </label>

    <input type="file" (change)="handleFileInput($event)" />
    <div class="m-5 w-full">
      <mat-progress-spinner
        mode="indeterminate"
        *ngIf="isUploading$ | async"
        [strokeWidth]="5"
        [diameter]="30"
      ></mat-progress-spinner>
      <fa-icon class="text-valid text-3xl" [icon]="'check'" *ngIf="isUploaded"></fa-icon>
    </div>
  </div>
</div>
