import { User, whmcsCurrency } from '@app/core';

const tag = '[Auth]';

export class SetUser {
  static readonly type = `${tag} set username`;

  constructor(public user: User, public redirect = false) {}
}

export class RequestUser {
  static readonly type = `${tag} request me`;
}

export class SetTokens {
  static readonly type = `${tag} set tokens`;

  constructor(public token: string, public expire: number, public refreshToken: string) {}
}

export class LoginRequest {
  static readonly type = `${tag} try login method`;

  constructor(public login: string, public password: string) {}
}

export class LoginError {
  static readonly type = `${tag} error loggin`;

  constructor(public error: Error) {}
}

export class Logout {
  static readonly type = `${tag} set user disconnected status`;
}

export class RedirectToLogin {
  static readonly type = `${tag} redirect to login page`;
}

export class SetCurrency {
  static readonly type = `${tag} set currency`;

  constructor(public currency: whmcsCurrency) {}
}

export class RequestCurrency {
  static readonly type = `${tag} request currency`;
}
