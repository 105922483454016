import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HighlightDirective } from './highlight.directive';
import { IsCustomerDirective } from './is-customer.directive';
import { IsSuperUserDirective } from './is-super-user.directive';

@NgModule({
  declarations: [
    IsSuperUserDirective,
    IsCustomerDirective,
    HighlightDirective,
  ],
  exports: [
    IsSuperUserDirective,
    IsCustomerDirective,
    HighlightDirective
  ],
  imports: [CommonModule],
})
export class DirectivesModule { }
