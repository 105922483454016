import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthState } from '@app/core/auth/states/auth.state';
import { App } from '@app/core/models/app.model';
import { AppState } from '@app/core/states/app.state';
import { MenuItem } from '@app/ui/components/menu/menu.model';
import { environment } from '@env/environment';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'rk-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit, OnDestroy {
  menuItems: MenuItem[] = [];
  @Select(AuthState.userRKDomain)
  userRKDomain$: Observable<string>;
  @Select(AuthState.isSuperUser)
  isSuperUser$: Observable<boolean>;
  @Select(AppState.currentApp)
  currentApp$: Observable<App>;

  isReduced = false;

  private readonly subscription: Subscription = new Subscription();

  constructor(
    private readonly translate: TranslateService,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.subscription.add(
      combineLatest([this.userRKDomain$, this.currentApp$, this.isSuperUser$])
        .pipe(
          map(([domain, app, isSuperUser]) => {
            const menu = [
              {
                label: this.translate.instant('menu.dashboard'),
                icon: ['fas', 'gauge-high'] as IconProp,
                route: '/apps',
                isExternalURL: false,
              },
              {
                label: this.translate.instant('menu.notifications'),
                icon: ['fas', 'comment'] as IconProp,
                route: '/notifications',
                isExternalURL: false,
              },
              {
                label: this.translate.instant('menu.configuration'),
                icon: ['fas', 'palette'] as IconProp,
                route: app?.theme === '4' ? `/simulator/${app?.appId}`: `${domain}/update-app-${app?.appId}`,
                isExternalURL: app?.theme !== '4',
              },
              {
                label: this.translate.instant('menu.publication'),
                icon: ['fas', 'share-nodes'] as IconProp,
                route: `${environment.urls.MOBILE_API}/publication/app/${app?.appId}`,
                isExternalURL: true,
              },
            ];
            if (isSuperUser) {
              menu.push({
                label: 'Admin',
                icon: ['fas', 'user-shield'] as IconProp,
                route: '/admin',
                isExternalURL: false,
              });
            }

            return menu;
          }),
        )
        .subscribe(menuItems => {
          this.menuItems = menuItems;
        }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
