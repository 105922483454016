import { Component } from '@angular/core';

@Component({
  selector: 'rk-menu-configuration-shell',
  templateUrl: './menu-configuration-shell.component.html',
  styleUrls: ['./menu-configuration-shell.component.scss'],
})
export class MenuConfigurationShellComponent {
  selectedSubMenu = 'content';

  onSelectSubmenu(event: string) {
    this.selectedSubMenu = event;
  }
}
