<rk-welcome-animation *ngIf="showWelcomeAnimation"></rk-welcome-animation>
<!-- creation form -->
<div
  *ngIf="!showWelcomeAnimation"
  class="create-form-container w-full h-full flex justify-center items-center"
  [@fadeInOut]
>
  <div class="h-full w-full content-container">
    <!-- form -->
    <div class="flex flex-col justify-center form-container">
      <div class="relative">
        <div class="font-bold text-[40px] mb-2">
          {{ 'create-app-form.choose-name' | translate }}
        </div>
        <!-- name input and error messages -->
        <rk-app-name-input
          [appName]="appName"
          (appNameChange)="onAppNameChange($event)"
          (errorCountChange)="onErrorCountChange($event)"
          (nameIsAvailable)="nameIsAvailable = $event"
          (nameHasNoErrors)="nameHasNoErrors = $event"
        ></rk-app-name-input>
        <!-- Button -->
        <!-- vertical offset if number of errors is > 2 -->
        <div
          class="absolute left-0 top-[153px] create-app-button"
          [style.transform]="
            errorCount > 1 ? 'translateY(' + errorCount * 12 + 'px)' : 'translateY(0px)'
          "
        >
          <rk-button
            [text]="'create-app-form.start' | translate"
            (buttonClick)="onConfirmAppName()"
            [size]="'large'"
            [disabled]="formIsInvalid || (isLoading$ | async)"
            [isLoading]="isLoading$ | async"
          ></rk-button>
        </div>
      </div>
    </div>
    <div class="background-image"></div>
  </div>
</div>
