import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { DropdownOption } from './dropdown.model';

@Component({
  selector: 'rk-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  @Input() options: DropdownOption[] | undefined;
  @Input() selectedOption?: DropdownOption | undefined;
  @Input() placeholder?: string | undefined;
  @Input() isDisabled?: boolean | undefined;
  @Input() whiteBackground?: boolean | undefined;
  @Input() fullWidth? = false;
  @Output() readonly selectionChange: EventEmitter<any> = new EventEmitter();
  @ViewChild('dropdownContainer', { static: true })
  dropdownContainer!: ElementRef;
  isOpen = false;

  toggleDropdown() {
    if (this.options && this.options.length > 1) {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        const rect = this.dropdownContainer.nativeElement.getBoundingClientRect();
        const spaceBelow = window.innerHeight - rect.bottom;
        const dropdownOptionsEl =
          this.dropdownContainer.nativeElement.querySelector('.dropdown-options');
        dropdownOptionsEl.style.maxHeight = `${spaceBelow - 20}px`;
      }
    }
  }

  onOptionSelected(option: any) {
    this.selectedOption = option;
    this.selectionChange.emit(option.value);
    this.isOpen = false;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    if (!this.dropdownContainer.nativeElement.contains(targetElement)) {
      this.isOpen = false;
    }
  }
}
