import { Component } from '@angular/core';
import {TooltipArrowPosition, TooltipPosition, TooltipProps} from './tooltip.model';

@Component({
  selector: 'rk-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  title = '';
  description = '';
  position: TooltipPosition = 'top';
  arrowPosition: TooltipArrowPosition = 'left';
  style: object = {};
  destroy = false;

  setTooltipProperties(props: TooltipProps) {
    Object.assign(this, props);
  }
  destroyTooltip() {
    this.destroy = true;
  }
}
