import { Component, OnDestroy, OnInit } from '@angular/core';
import { TabModel } from '@app/features/app-creation-and-configuration/models/app-tabs.model';
import { UpdateAppConfig } from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, combineLatest, filter, map } from 'rxjs';

@Component({
  selector: 'rk-page-design-editor',
  templateUrl: './page-design-editor.component.html',
  styleUrl: './page-design-editor.component.scss',
})
export class PageDesignEditorComponent implements OnInit, OnDestroy {
  @Select(AppConfigurationState.activePageIndex)
  activePageIndex$: Observable<number>;

  @Select(AppConfigurationState.currentAppConfigPageList)
  currentAppConfigPageList$: Observable<TabModel>;

  headerColor$: Observable<string>;
  headerTextColor$: Observable<string>;

  private readonly subscriptions = new Subscription();
  pageIndex: number;

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.headerColor$ = combineLatest([
      this.currentAppConfigPageList$,
      this.activePageIndex$,
    ]).pipe(
      filter(([pageList, pageIndex]) => !!pageList && !!pageIndex),
      map(([pageList, pageIndex]) => pageList[pageIndex].headerColor),
    );

    this.headerTextColor$ = combineLatest([
      this.currentAppConfigPageList$,
      this.activePageIndex$,
    ]).pipe(
      filter(([pageList, pageIndex]) => !!pageList && !!pageIndex),
      map(([pageList, pageIndex]) => pageList[pageIndex].headerTextColor),
    );

    this.subscriptions.add(
      this.activePageIndex$
        .pipe(filter(index => !!index))
        .subscribe(id => (this.pageIndex = Number(id))),
    );
  }

  onHeaderColorChange(color: string) {
    this.store.dispatch(
      new UpdateAppConfig(['onglets', this.pageIndex.toString(), 'headerColor'], color),
    );
  }

  onHeaderTextColorChange(color: string) {
    this.store.dispatch(
      new UpdateAppConfig(
        ['onglets', this.pageIndex.toString(), 'headerTextColor'],
        color,
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
