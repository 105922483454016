<div class="container m-auto flex justify-center">
  <div class="flex flex-col items-center">
    <rk-button class="p-2" [text]="'Log in as Jérémie'" *ngIf="isLocal" (buttonClick)="autoLog()" [variant]="'secondary'"> </rk-button>
    <form [formGroup]="loginForm" (ngSubmit)="login()" class="flex flex-col gap-2 items-center">
      <rk-form-field>
        <input type="text" formControlName="email" placeholder="Email" />
      </rk-form-field>
      <rk-form-field>
        <input type="password" formControlName="password" placeholder="Password" />
      </rk-form-field>
      <button class="p-2" type="submit" >
        <rk-button [text]="'login.login-button' | translate " [variant]="'secondary'" [disabled]="!loginForm.valid" (buttonClick)="login()"> </rk-button>
      </button>
    </form>
  </div>
</div>
