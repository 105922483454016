import { Component, EventEmitter, forwardRef, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rk-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
})
export class DatePickerComponent implements ControlValueAccessor {
  @Output() readonly dateChange = new EventEmitter<Date>();
  dateControl = new FormControl(moment());
  minDate: Date = new Date();

  onChange: any = () => {};
  onTouch: any = () => {};

  constructor(
    private readonly adapter: NgxMatDateAdapter<Date>,
    private readonly translateService: TranslateService,
  ) {
    const currentLocale = this.translateService.currentLang;
    adapter.setLocale(currentLocale);
  }

  writeValue(value: any) {
    if (value instanceof Date) {
      this.dateControl.setValue(moment(value));
      this.onChange(value);
      this.onTouch(value);
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  handleDateChange(event: any) {
    this.onChange(event.value);
    this.dateChange.emit(event.value);
  }
}
