<div class="container" [@alertAnimation] *ngIf="opened">
  <div>
    <div class="title text-xl">{{ title }}</div>
    <div class="description">{{ description }}</div>
  </div>
  <div class="buttons-container">
    <div class="cta-wrapper">
      <rk-button *ngIf="cta" [text]="cta" [variant]="variant" class="cta-button" ></rk-button>
    </div>
    <rk-button
      [variant]="'white'"
      [iconOnly]="true"
      (buttonClick)="closeAlert()"
      [icon]="'close'"
      [colorVariant]="variant"
      *ngIf="close"
    ></rk-button>
  </div>
</div>
