<div class="flex gap-4 w-full">
  <div
    class="flex flex-col rounded border flex-1 icon-style-container "
    [class.icon-style-selected]="(iconStyle$ | async) === 'solid'"
    (click)="onIconStyleSelection('solid')"
  >
    <div class="px-4 py-2 flex items-center">
      <div class="size-[15px] rounded-full border selector-circle"></div>
      <span class="ml-2">{{ 'icon-type-selector.solid' | translate }} </span>
    </div>
    <div class="flex justify-around items-center px-4 py-2 border-t icon-list">
      <fa-icon [icon]="'heart'"></fa-icon>
      <fa-icon [icon]="'message'"></fa-icon>
      <fa-icon [icon]="'envelope'"></fa-icon>
      <fa-icon [icon]="'bell'"></fa-icon>
      <fa-icon [icon]="'eye'"></fa-icon>
      <fa-icon [icon]="'calendar'"></fa-icon>
    </div>
  </div>
  <div
    class="flex flex-col rounded border flex-1 icon-style-container "
    [class.icon-style-selected]="(iconStyle$ | async) === 'regular'"
    (click)="onIconStyleSelection('regular')"
  >
    <div class="px-4 py-2 flex items-center">
      <div class="size-[15px] rounded-full border selector-circle"></div>
      <span class="ml-2">{{ 'icon-type-selector.regular' | translate }} </span>
    </div>
    <div class="flex justify-around items-center px-4 py-2 border-t icon-list">
      <fa-icon [icon]="['far', 'heart']"></fa-icon>
      <fa-icon [icon]="['far', 'message']"></fa-icon>
      <fa-icon [icon]="['far', 'envelope']"></fa-icon>
      <fa-icon [icon]="['far', 'bell']"></fa-icon>
      <fa-icon [icon]="['far', 'eye']"></fa-icon>
      <fa-icon [icon]="['far', 'calendar']"></fa-icon>
    </div>
  </div>
</div>
