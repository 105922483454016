import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AppConfigTabs } from '@app/features/app-creation-and-configuration/models/app-config.model';
import {
  SetPageNameError,
  UpdateAppConfig,
} from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import {
  Icon,
  convertToIconFormat,
  iconCategoryMapping,
} from '@app/ui/components/icon-search/icon-list';
import { environment } from '@env/environment';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Select, Store } from '@ngxs/store';
import {
  Observable,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  filter,
  take,
} from 'rxjs';

@Component({
  selector: 'rk-page-icon-and-name-editor',
  templateUrl: './page-icon-and-name-editor.component.html',
  styleUrl: './page-icon-and-name-editor.component.scss',
})
export class PageIconAndNameEditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  pageIndex: number;

  @Select(AppConfigurationState.activePageIndex)
  activePageIndex$: Observable<number>;

  faIcon: IconProp;
  legacyIconUrl: string;
  pageNameControl: FormControl;

  @Select(AppConfigurationState.currentAppConfigPageList)
  currentAppConfigPageList$: Observable<AppConfigTabs>;

  private readonly subscriptions: Subscription = new Subscription();

  iconSearchVisible = false;

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    if (!event.target['closest']('.icon-search')) {
      this.iconSearchVisible = false;
    }
  }

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.pageNameControl = new FormControl('', [
      Validators.required,
      Validators.minLength(2),
    ]);
    this.subscribeToCurrentPageChanges();
    this.subscribeToPageNameChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pageIndex']) {
      this.updateCurrentPage();
    }
  }

  subscribeToCurrentPageChanges(): void {
    this.subscriptions.add(
      this.currentAppConfigPageList$.pipe(filter(pages => !!pages)).subscribe(() => {
        this.updateCurrentPage();
      }),
    );
  }

  updateCurrentPage(): void {
    this.currentAppConfigPageList$.pipe(take(1)).subscribe(pages => {
      const currentPage = pages[this.pageIndex];
      if (currentPage) {
        this.faIcon = this.getFaIcon(currentPage.faIcon);
        this.legacyIconUrl = this.getLegacyIconLink(currentPage.icon);
        this.pageNameControl?.setValue(currentPage.titre, { emitEvent: false });
      }
    });
  }

  subscribeToPageNameChanges(): void {
    this.subscriptions.add(
      this.pageNameControl.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(newName => {
          this.store.dispatch(
            new UpdateAppConfig(['onglets', this.pageIndex.toString(), 'titre'], newName),
          );
          this.store.dispatch(new SetPageNameError(this.pageNameControl?.invalid));
        }),
    );
  }

  onIconClick(event: Event) {
    event.stopPropagation();
    this.iconSearchVisible = true;
  }

  getLegacyIconLink(iconName: string) {
    return iconName
      ? `https://www.${environment.urls.RADIOKING_DOMAIN}/images/mobile/icons/${iconName}.png`
      : null;
  }

  getFaIcon(iconName: string) {
    return iconName ? convertToIconFormat(iconName) : null;
  }

  onIconSelected(selectedIcon: Icon) {
    this.iconSearchVisible = false;
    const formattedIconName = `${iconCategoryMapping[selectedIcon.category]} fa-${
      selectedIcon.name
    }`;
    this.store.dispatch(
      new UpdateAppConfig(
        ['onglets', this.pageIndex.toString(), 'faIcon'],
        formattedIconName,
      ),
    );
    const newDisplayIcon = [];
    newDisplayIcon.push(selectedIcon.category, selectedIcon.name);
    this.faIcon = newDisplayIcon as IconProp;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
