import { Component } from '@angular/core';

@Component({
  selector: 'rk-welcome-animation',
  templateUrl: './welcome-animation.component.html',
  styleUrls: ['./welcome-animation.component.scss']
})
export class WelcomeAnimationComponent {

}
