import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthState } from '@app/core/auth/states/auth.state';
import { App } from '@app/core/models/app.model';
import { ResetCurrentApp } from '@app/core/states/app.actions';
import { AppState } from '@app/core/states/app.state';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { I18nService } from './../../services/i18n.service';

@Component({
  selector: 'rk-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements AfterViewInit, OnDestroy {
  @Select(AuthState.currentUsername)
  userName$: Observable<string>;
  @Select(AuthState.userLang)
  userLang$: Observable<string>;
  @Select(AppState.showAppList)
  showAppList$: Observable<boolean>;
  @Select(AppState.showReturnToDashboardLink)
  showReturnToDashboardLink$: Observable<boolean>;
  @Select(AppState.apps)
  apps$: Observable<App[]>;
  @Select(AppState.currentAppIsNotOwnedByUser)
  currentAppIsNotOwnedByUser$: Observable<boolean>;

  isLocal = !environment.production;
  headwayEn = '7N2LA7';
  headwayFr = 'xYOvAJ';

  private readonly subscription: Subscription = new Subscription();

  constructor(
    private readonly translateService: TranslateService,
    private readonly i18nService: I18nService,
    private readonly router: Router,
    private readonly store: Store,
  ) {}

  ngAfterViewInit(): void {
    this.userLang$.subscribe(lang => {
      const config = {
        selector: '.headway-widget', // CSS selector where to inject the badge
        account: lang !== 'fr' ? this.headwayEn : this.headwayFr,
        translations: {
          title: this.translateService.instant('changelog.title'),
          readMore: this.translateService.instant('changelog.read-more'),
          labels: {
            new: this.translateService.instant('changelog.new'),
            improvement: this.translateService.instant('changelog.improvement'),
            fix: this.translateService.instant('changelog.fixes'),
          },
          footer: this.translateService.instant('changelog.read-more'),
        },
      };
      let tries = 0;
      const itv = setInterval(() => {
        // @ts-ignore
        if (typeof Headway !== 'undefined') {
          // @ts-ignore
          Headway.init(config);
          clearInterval(itv);
        }
        tries += 1;
        if (tries === 10) {
          clearInterval(itv);
        }
      }, 500);
    });
  }

  switchLanguage() {
    const current = this.translateService.currentLang;
    this.i18nService.language = current === 'fr-FR' ? 'en-US' : 'fr-FR';
  }

  resetCurrentApp() {
    this.store.dispatch(new ResetCurrentApp());
  }

  onReturnToDashboard() {
    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: 'ButtonBackToDashboard',
        component: 'Button',
      }),
    );
    this.store.dispatch(new LogEvent('Button Clicked'));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
