import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, mergeMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { getAppId$ } from '@app/core/utils';
import { StatisticsService } from '../services/statistics.service';
import { PlatformSubscriber, SubscribersPerDay } from '../models/statistics.models';
import {
  FetchConsumptionError,
  FetchConsumptionRequest,
  FetchConsumptionSuccess,
  FetchNewSubscribersError,
  FetchNewSubscribersRequest,
  FetchNewSubscribersSuccess,
  FetchNotificationsSentError,
  FetchNotificationsSentRequest,
  FetchNotificationsSentSuccess,
  FetchSubscribersCountError,
  FetchSubscribersCountRequest,
  FetchSubscribersCountSuccess,
  ResetStatisticsState,
} from './statistics.actions';

export class StatisticsStateModel {
  subscriberCount: PlatformSubscriber[];
  newSubscribers: SubscribersPerDay[];
  notificationsSent: SubscribersPerDay[];
  consumption: { consumption: number; limit: number; use_percent: number };
  isLoading: boolean;
}

@State<StatisticsStateModel>({
  name: 'statistics',
  defaults: {
    subscriberCount: [],
    newSubscribers: [],
    notificationsSent: [],
    consumption: { consumption: 0, limit: 0, use_percent: 0 },
    isLoading: false,
  },
})
@Injectable()
export class StatisticsState {
  constructor(
    private readonly statisticsService: StatisticsService,
    private readonly store: Store,
  ) {}

  @Selector()
  static isLoading(state: StatisticsStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  static subscriberCount(state: StatisticsStateModel): PlatformSubscriber[] {
    return state.subscriberCount;
  }

  @Selector()
  static newSubscribers(state: StatisticsStateModel): SubscribersPerDay[] {
    return state.newSubscribers;
  }

  @Selector()
  static notificationsSent(state: StatisticsStateModel): SubscribersPerDay[] {
    return state.notificationsSent;
  }

  @Selector()
  static consumption(state: StatisticsStateModel): {
    consumption: number;
    limit: number;
    use_percent: number;
  } {
    return state.consumption;
  }

  @Action(FetchSubscribersCountRequest)
  fetchSubscribersCountRequest(ctx: StateContext<StatisticsStateModel>) {
    ctx.patchState({ isLoading: true });

    return getAppId$(this.store).pipe(
      mergeMap(appId => this.statisticsService.getSubscribersCount(appId)),
      mergeMap(subscriberCount =>
        ctx.dispatch(new FetchSubscribersCountSuccess(subscriberCount)),
      ),
      catchError(err => ctx.dispatch(new FetchSubscribersCountError(err))),
    );
  }

  @Action(FetchSubscribersCountSuccess)
  fetchSubscribersCountSuccess(
    ctx: StateContext<StatisticsStateModel>,
    { subscriberCount }: FetchSubscribersCountSuccess,
  ) {
    ctx.patchState({ subscriberCount, isLoading: false });
  }

  @Action(FetchNewSubscribersRequest)
  fetchNewSubscribersRequest(ctx: StateContext<StatisticsStateModel>) {
    ctx.patchState({ isLoading: true });

    return getAppId$(this.store).pipe(
      mergeMap(appId => this.statisticsService.getNewSubscribers(appId)),
      mergeMap(newSubscribers =>
        ctx.dispatch(new FetchNewSubscribersSuccess(newSubscribers)),
      ),
      catchError(err => ctx.dispatch(new FetchNewSubscribersError(err))),
    );
  }

  @Action(FetchNewSubscribersSuccess)
  fetchNewSubscribersSuccess(
    ctx: StateContext<StatisticsStateModel>,
    { newSubscribers }: FetchNewSubscribersSuccess,
  ) {
    ctx.patchState({ newSubscribers, isLoading: false });
  }

  @Action(FetchConsumptionRequest)
  fetchConsumptionRequest(ctx: StateContext<StatisticsStateModel>) {
    ctx.patchState({ isLoading: true });

    return getAppId$(this.store).pipe(
      mergeMap(appId => this.statisticsService.getConsumption(appId)),
      mergeMap(consumption => ctx.dispatch(new FetchConsumptionSuccess(consumption))),
      catchError(err => ctx.dispatch(new FetchConsumptionError(err))),
    );
  }

  @Action(FetchConsumptionSuccess)
  fetchConsumptionSuccess(
    ctx: StateContext<StatisticsStateModel>,
    { consumption }: FetchConsumptionSuccess,
  ) {
    ctx.patchState({ consumption, isLoading: false });
  }

  @Action(FetchNotificationsSentRequest)
  fetchNotificationsSentRequest(ctx: StateContext<StatisticsStateModel>) {
    ctx.patchState({ isLoading: true });

    return getAppId$(this.store).pipe(
      mergeMap(appId => this.statisticsService.getNotificationsSent(appId)),
      mergeMap(notificationsSent =>
        ctx.dispatch(new FetchNotificationsSentSuccess(notificationsSent)),
      ),
      catchError(err => ctx.dispatch(new FetchNotificationsSentError(err))),
    );
  }

  @Action(FetchNotificationsSentSuccess)
  fetchNotificationsSentSuccess(
    ctx: StateContext<StatisticsStateModel>,
    { notificationsSent }: FetchNotificationsSentSuccess,
  ) {
    ctx.patchState({ notificationsSent, isLoading: false });
  }

  @Action(FetchSubscribersCountError)
  errorSubscribersCountAction(
    ctx: StateContext<StatisticsStateModel>,
    { error }: FetchSubscribersCountError,
  ) {
    console.error('Error fetching subscriber count:', error);
    ctx.patchState({ isLoading: false });
  }

  @Action(FetchNewSubscribersError)
  errorNewSubscribersAction(
    ctx: StateContext<StatisticsStateModel>,
    { error }: FetchNewSubscribersError,
  ) {
    console.error('Error fetching new subscribers:', error);
    ctx.patchState({ isLoading: false });
  }

  @Action(FetchConsumptionError)
  errorConsumptionAction(
    ctx: StateContext<StatisticsStateModel>,
    { error }: FetchConsumptionError,
  ) {
    console.error('Error fetching consumption:', error);
    ctx.patchState({ isLoading: false });
  }

  @Action(FetchNotificationsSentError)
  errorNotificationsSentAction(
    ctx: StateContext<StatisticsStateModel>,
    { error }: FetchNotificationsSentError,
  ) {
    console.error('Error fetching notifications sent:', error);
    ctx.patchState({ isLoading: false });
  }

  @Action(ResetStatisticsState)
  resetStatisticsState(ctx: StateContext<StatisticsStateModel>) {
    ctx.patchState({
      subscriberCount: [],
      newSubscribers: [],
      notificationsSent: [],
      consumption: { consumption: 0, limit: 0, use_percent: 0 },
    });
  }
}
