import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UiModule } from '@app/ui';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxsModule } from '@ngxs/store';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { AndroidNotificationFormComponent } from './components/android-notification-form/android-notification-form.component';
import { AppGenerationFilesComponent } from './components/app-generation-files/app-generation-files.component';
import { AppleNotificationFormComponent } from './components/apple-notification-form/apple-notification-form.component';
import { AdminState } from './states/admin.state';

@NgModule({
  declarations: [
    AdminDashboardComponent,
    AppleNotificationFormComponent,
    AndroidNotificationFormComponent,
    AppGenerationFilesComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    NgxsModule.forFeature([AdminState]),
    UiModule
  ],
})
export class AdminModule {}
