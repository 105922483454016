<div class="bg-[#f8f8f8] p-5 mb-4 relative">
  <!-- delete stream button -->
  @if (streamId !== 0) {
    <div class="absolute top-5 right-5 cursor-pointer ">
      <rk-button [iconOnly]="true" [icon]="'trash'" [variant]="'danger'" [size]="'small'" (buttonClick)="deleteStream()"></rk-button>
    </div>
  }
  <!-- stream name -->
  <rk-form-field class="mb-10">
    <span class="opacity-70"> {{ 'stream-edit.name' | translate }} </span>
    <input
      class="mt-3"
      [(ngModel)]="currentStreamName"
      (input)="onStreamNameChange($event)"
    />
  </rk-form-field>
  <!-- second row -->
  <div class="flex mb-4">
    <!-- stream type -->
    <div class="flex-1">
      <div class="opacity-70 mb-3">
        {{ 'stream-edit.stream-type' | translate }}
      </div>
      <div class="w-full">
        <rk-dropdown
          [options]="streamTypeOptions"
          [whiteBackground]="true"
          [selectedOption]="currentStreamType"
          [fullWidth]="true"
          (selectionChange)="onStreamTypeChange($event)"
        >
        </rk-dropdown>
      </div>
    </div>
  </div>

  <!-- third row  -->
  @if (!(currentStreamType?.value === 'radioking')) {
    <!-- stream url -->
    <div class="mb-3">
      <rk-form-field>
        <span class="opacity-70"> {{ 'stream-edit.stream-url' | translate }} </span>
        <input
          class="mt-3"
          [formControl]="streamURLControl"
          [class.form-alert]="streamURLControl?.invalid && streamURLControl?.touched"
        />
        <!-- required error -->
        <div
          *ngIf="streamURLControl.errors?.['required'] && streamURLControl?.touched"
          class="form-error-message mt-1"
        >
          {{ 'stream-edit.url-required' | translate }}
        </div>
        <!-- URL format error -->
        <div
          *ngIf="streamURLControl.errors?.['invalidUrl'] && streamURLControl?.touched"
          class="form-error-message mt-1"
        >
          {{ 'stream-edit.url-error' | translate }}
        </div>
      </rk-form-field>
    </div>

    @if (currentStreamType?.value === 'autre') {
      <!-- title url -->
      <rk-form-field>
        <span class="opacity-70"> {{ 'stream-edit.title-url' | translate }} </span>
        <input
          class="mt-3"
          [formControl]="titleURLControl"
          [class.form-alert]="titleURLControl?.invalid && titleURLControl?.touched"
        />
        <!-- URL format error -->
        <div
          *ngIf="titleURLControl.errors?.['invalidUrl'] && titleURLControl?.touched"
          class="form-error-message mt-1"
        >
          {{ 'stream-edit.url-error' | translate }}
        </div>
        <div class="mt-3">
          <rk-infobox>
            {{ 'stream-edit.title-url-infobox' | translate }}
          </rk-infobox>
        </div>
      </rk-form-field>
    }
    @if (currentStreamType?.value === 'icecast') {
      <!-- icecast mountpoint -->
      <rk-form-field>
        <span class="opacity-70"> {{ 'stream-edit.mount-point' | translate }} </span>
        <div class="w-full mt-3 mb-3">
          <rk-dropdown
            [options]="icecastMountpoints"
            [fullWidth]="true"
            [whiteBackground]="true"
            [selectedOption]="currentIcecastMountPoint"
            (selectionChange)="onMounPointChange($event)"
            [placeholder]="'stream-edit.choose-mountpoint' | translate"
          >
          </rk-dropdown>
        </div>
      </rk-form-field>
    }
  } @else {
    <!-- user radio list -->
    <div class="opacity-70 mb-3">
      {{ 'stream-edit.radio' | translate }}
    </div>
    <div class="mb-3">
      <rk-dropdown
        [options]="userRadioDropdownOptions"
        [selectedOption]="currentUserRadio"
        [fullWidth]="true"
        [whiteBackground]="true"
        (selectionChange)="onUserRadioChange($event)"
        [placeholder]="'stream-edit.choose-radio' | translate"
      >
      </rk-dropdown>
      <rk-form-field>
        <div
          *ngIf="userRadioDropdownOptions.length === 0"
          class="form-error-message mt-1"
        >
          {{ 'stream-edit.radioking-no-radios-error' | translate }}
        </div>
      </rk-form-field>
    </div>
  }

  <!-- logo upload -->
  <div class="mt-10">
    <span class="opacity-70"> {{ 'stream-edit.logo' | translate }} </span>
    <rk-image-upload
      class="mt-3"
      #uploadLogoComponent
      (fileSelected)="onImageSelected($event)"
      (imageRemoved)="onRemoveImage()"
      [fetchedImageUrl]="logoUrl"      
      [aspectRatio]="'552/248'"
      [iconSize]="32"
      [radius]="37"
      [compactLayout]="true"
    ></rk-image-upload>
    <div class="mt-3">
      <rk-image-size-guide
        [width]="552"
        [height]="248"
        [lineBreakOption]="'second'"
      ></rk-image-size-guide>
    </div>
  </div>
</div>
