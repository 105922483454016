<div class="flex flex-col overflow-hidden h-full">
  <h2 class="opacity-70 text-black text-sm mb-6">{{ title }}</h2>
  <div class="w-full chart-wrapper" [class.h-full]="(isIphoneReady$ | async) === false">
    <canvas
      baseChart
      #myChart
      class="h-full"
      [datasets]="chartData"
      [labels]="chartLabels"
      [options]="chartOptions"
      [legend]="chartLegend"
    >
    </canvas>
  </div>
  <div class="chart-legend flex justify-center gap-5" *ngIf="isIphoneReady$ | async">
    <div class="flex justify-center items-center">
      <div class="chart-legend-total"></div>
      <span class="chart-legend-title">Total</span>
    </div>
    <div class="flex justify-center items-center">
      <div class="chart-legend-apple"></div>
      <span class="chart-legend-title">iOS</span>
    </div>
    <div class="flex justify-center items-center">
      <div class="chart-legend-android"></div>
      <span class="chart-legend-title"> Android </span>
    </div>
  </div>
</div>
