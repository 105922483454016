import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Store } from '@ngxs/store';
import {
  Observable,
  Subscription,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs';
import { RequestUser } from './core/auth/states/auth.actions';
import { AuthState } from './core/auth/states/auth.state';
import { I18nService } from './core/services/i18n.service';
import {
  FetchAppsRequest,
  ShowAppList,
  ShowMainContainerPadding,
  ShowMainMenu,
  ShowReturnToDashboardLink,
} from './core/states/app.actions';
import { AppState } from './core/states/app.state';
import { ShowSteps } from './features/app-creation-and-configuration/states/stepper.actions';
import { GlobalAlertService } from './ui/services/global-alert.service';
import { AlertService } from './ui/components/alert/alert.service';
import { GtmService } from './core/services/gtm.service';
import { App } from './core/models/app.model';

@Component({
  selector: 'rk-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @Select(AuthState.userId)
  user$: Observable<number>;

  @Select(AppState.showMainMenu)
  showMainMenu$: Observable<number>;

  @Select(AppState.showMainContainerPadding)
  showMainContainerPadding$: Observable<number>;

  @Select(AppState.currentApp)
  currentApp$: Observable<App>;

  private readonly subscription: Subscription = new Subscription();

  constructor(
    private readonly i18nService: I18nService,
    private readonly globalAlertService: GlobalAlertService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly gtmService: GtmService,
    private readonly store: Store,
    private readonly library: FaIconLibrary,
  ) {
    this.alertService.setRootViewContainerRef(viewContainerRef);
    library.addIconPacks(fab, far, fas);
  }

  @Dispatch()
  getMe() {
    return new RequestUser();
  }

  @Dispatch()
  showMainMenu(show: boolean) {
    return new ShowMainMenu(show);
  }

  @Dispatch()
  showAppList(show: boolean) {
    return new ShowAppList(show);
  }

  @Dispatch()
  showReturnToDashboardLink(show: boolean) {
    return new ShowReturnToDashboardLink(show);
  }

  @Dispatch()
  showMainContainerPadding(show: boolean) {
    return new ShowMainContainerPadding(show);
  }

  @Dispatch()
  showSteps(show: boolean) {
    return new ShowSteps(show);
  }

  @Dispatch()
  fetchUserApps() {
    return new FetchAppsRequest();
  }

  ngOnInit(): void {
    this.i18nService.init();
    this.getMe();
    this.globalAlertService.init();

    const routeData$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route.root.firstChild.snapshot.data),
    );

    this.gtmService.registerPageChangeEvent();

    // the route data is used to conditionally display certain components.
    // Additionally, it determines whether to activate the fetchUserApps.

    this.subscription.add(
      combineLatest([routeData$, this.user$]).subscribe(([data, user]) => {
        this.showMainMenu(
          data['showMainMenu'] !== undefined ? data['showMainMenu'] : false,
        );
        this.showAppList(
          data['showAppsList'] !== undefined ? data['showAppsList'] : false,
        );
        this.showMainContainerPadding(
          data['showMainContainerPadding'] !== undefined
            ? data['showMainContainerPadding']
            : false,
        );
        this.showSteps(data['showSteps'] !== undefined ? data['showSteps'] : false);
        this.showReturnToDashboardLink(
          data['showReturnToDashboardLink'] !== undefined
            ? data['showReturnToDashboardLink']
            : false,
        );
        if (data['fetchUserApps'] && user !== -1) {
          this.fetchUserApps();
        }
      }),
    );

    // sends tags to google tag manager
    this.subscription.add(
      this.currentApp$
        .pipe(
          filter(app => app !== null && app !== undefined),
          // avoids sending the tags twice
          distinctUntilChanged(
            (prev, curr) =>
              prev?.creationDate === curr?.creationDate &&
              prev?.planId === curr?.planId &&
              prev?.appId === curr?.appId,
          ),
        )
        .subscribe(app => {
          this.gtmService.pushToDataLayer({
            date_creation_id_app: app.creationDate,
            id_plan: app.planId,
            id_app: app.appId,
          });
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
