import { Component } from '@angular/core';
import { TextAlign } from '@app/features/app-creation-and-configuration/models/app-tabs.model';
import { UpdateAppConfig } from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'rk-text-align-settings',
  templateUrl: './text-align-settings.component.html',
  styleUrl: './text-align-settings.component.scss',
})
export class TextAlignSettingsComponent {
  @Select(AppConfigurationState.textAlign)
  textAlign$: Observable<TextAlign>;

  alignments: TextAlign[] = ['left', 'center', 'right', 'justify'];
  iconMap: { [key: string]: IconProp } = {
    left: 'align-left',
    center: 'align-center',
    right: 'align-right',
    justify: 'align-justify',
  };

  constructor(private readonly store: Store) {}

  selectTextAlign(textAlign: TextAlign) {
    this.store.dispatch(new UpdateAppConfig(['onglets', '0', 'textAlign'], textAlign));
  }
}
