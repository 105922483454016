<h1>{{ planName }}</h1>
<div class="price-line">
  <div class="price">
    <span class="price-value">{{ getPricing() }}</span>
    <span class="per-month" translate>plan.per-month</span>
  </div>
</div>
<div class="monthly" *ngIf="billing === 'Annually'">
  <div class="line-through text-center text-lg">
    {{ getMonthlyPricing()
    }}<span class="per-month" translate>plan.per-month</span>
  </div>
  <span class="opacity-40 text-sm" translate
    >plan.billed-annually</span
  >
</div>
<div class="upgrade-button">
  <rk-button
    (buttonClick)="choosePlan()"
    [class]="recommended ? 'primary' : 'secondary'"
    [text]="'plan.choose' | translate"
    [fullWidth]="true"
  ></rk-button>
</div>
<div class="offer-details mt-5">
  <div class="limit" >
    <div class="value" [innerHTML]=" 'plan.android' | translate "></div>
  </div>
  <div class="limit" *ngIf="plan > 2">
    <div class="value" [innerHTML]=" 'plan.ios' | translate "></div>
  </div>
  <div class="limit" *ngIf="plan > 2">
    <div class="value" [innerHTML]=" 'plan.apple-watch' | translate "></div>
  </div>
  <div class="limit" *ngIf="plan > 1">
    <div class="value" [innerHTML]=" 'plan.android-tv' | translate "></div>
  </div>
  <div class="limit" *ngIf="plan > 2">
    <div class="value" [innerHTML]=" 'plan.apple-tv' | translate "></div>
  </div>
  <div class="limit" *ngIf="plan > 1">
    <div class="value" [innerHTML]=" 'plan.android-auto' | translate "></div>
  </div>
  <div class="limit">
    <div
      class="value"
      [innerHTML]="
        'plan.push' | translate : { number: pushNotifications }
      "
    ></div>
  </div>
  <div class="limit" *ngIf="plan > 1">
    <div class="value" [innerHTML]=" 'plan.white-label' | translate "></div>
  </div>
</div>
