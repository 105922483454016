<div class="mb-2 opacity-70">
  {{ 'general-configuration.shodow-box' | translate }}
</div>
<div class="flex gap-4">
  <!-- shadow 0 -->
  <div
    [class.selected]="(divShadow$ | async) === 0"
    class="design-option-container"
    (click)="onDivShadowSelection(0)"
  >
    <div class="design-option-item div-shadow-selector shadow-0 bg-white"></div>
  </div>
  <!-- shadow 30 -->
  <div
    [class.selected]="(divShadow$ | async) === 30"
    class="design-option-container"
    (click)="onDivShadowSelection(30)"
  >
    <div class="design-option-item div-shadow-selector shadow-30 bg-white"></div>
  </div>
  <!-- shadow 40 -->
  <div
    [class.selected]="(divShadow$ | async) === 40"
    class="design-option-container"
    (click)="onDivShadowSelection(40)"
  >
    <div class="design-option-item div-shadow-selector shadow-40 bg-white"></div>
  </div>
  <!-- shadow 60 -->
  <div
    [class.selected]="(divShadow$ | async) === 60"
    class="design-option-container"
    (click)="onDivShadowSelection(60)"
  >
    <div class="design-option-item div-shadow-selector shadow-60 bg-white"></div>
  </div>
</div>
