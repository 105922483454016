<nav class="menu-container" [ngClass]="{'reduced': reduced}">
  <div>
    <div *ngFor="let item of menuItems">
      <a *ngIf="item.isExternalURL; else internalLink" href="{{item.route}}" target="_blank">
        <div class="icon-container">
          <fa-icon [icon]="item.icon"></fa-icon>
        </div>  
        <span *ngIf="!reduced">{{item.label}}</span>
      </a>
      <ng-template #internalLink>
        <a [routerLink]="item.route" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <div class="icon-container">
            <fa-icon [icon]="item.icon"></fa-icon>
          </div>
          <span *ngIf="!reduced">{{item.label}}</span>
        </a>
      </ng-template>
    </div>
  </div>
  <ng-content></ng-content> 
</nav>