export const LANGUAGES = [
  { code: 'aa', name: ['Afar', 'Afar'] },
  { code: 'ab', name: ['Abkhazian', 'Аҧсуа'] },
  { code: 'af', name: ['Afrikaans', 'Afrikaans'] },
  { code: 'ak', name: ['Akan', 'Akana'] },
  { code: 'am', name: ['Amharic', 'አማርኛ'] },
  { code: 'an', name: ['Aragonese', 'Aragonés'] },
  { code: 'ar', name: ['Arabic', 'العربية'] },
  { code: 'as', name: ['Assamese', 'অসমীয়া'] },
  { code: 'av', name: ['Avar', 'Авар'] },
  { code: 'ay', name: ['Aymara', 'Aymar'] },
  { code: 'az', name: ['Azerbaijani', 'Azərbaycanca / آذربايجان'] },
  { code: 'ba', name: ['Bashkir', 'Башҡорт'] },
  { code: 'be', name: ['Belarusian', 'Беларуская'] },
  { code: 'bg', name: ['Bulgarian', 'Български'] },
  { code: 'bh', name: ['Bihari', 'भोजपुरी'] },
  { code: 'bi', name: ['Bislama', 'Bislama'] },
  { code: 'bm', name: ['Bambara', 'Bamanankan'] },
  { code: 'bn', name: ['Bengali', 'বাংলা'] },
  { code: 'bo', name: ['Tibetan', 'བོད་ཡིག / Bod skad'] },
  { code: 'br', name: ['Breton', 'Brezhoneg'] },
  { code: 'bs', name: ['Bosnian', 'Bosanski'] },
  { code: 'ca', name: ['Catalan', 'Català'] },
  { code: 'ce', name: ['Chechen', 'Нохчийн'] },
  { code: 'ch', name: ['Chamorro', 'Chamoru'] },
  { code: 'co', name: ['Corsican', 'Corsu'] },
  { code: 'cr', name: ['Cree', 'Nehiyaw'] },
  { code: 'cs', name: ['Czech', 'Česky'] },
  {
    code: 'cu',
    name: ['Old Church Slavonic / Old Bulgarian', 'словѣньскъ / slověnĭskŭ'],
  },
  { code: 'cv', name: ['Chuvash', 'Чăваш'] },
  { code: 'cy', name: ['Welsh', 'Cymraeg'] },
  { code: 'da', name: ['Danish', 'Dansk'] },
  { code: 'de', name: ['German', 'Deutsch'] },
  { code: 'dv', name: ['Divehi', 'ދިވެހިބަސް'] },
  { code: 'dz', name: ['Dzongkha', 'ཇོང་ཁ'] },
  { code: 'ee', name: ['Ewe', 'Ɛʋɛ'] },
  { code: 'el', name: ['Greek', 'Ελληνικά'] },
  { code: 'en', name: ['English', 'English'] },
  { code: 'eo', name: ['Esperanto', 'Esperanto'] },
  { code: 'es', name: ['Spanish', 'Español'] },
  { code: 'et', name: ['Estonian', 'Eesti'] },
  { code: 'eu', name: ['Basque', 'Euskara'] },
  { code: 'fa', name: ['Persian', 'فارسی'] },
  { code: 'ff', name: ['Peul', 'Fulfulde'] },
  { code: 'fi', name: ['Finnish', 'Suomi'] },
  { code: 'fj', name: ['Fijian', 'Na Vosa Vakaviti'] },
  { code: 'fo', name: ['Faroese', 'Føroyskt'] },
  { code: 'fr', name: ['French', 'Français'] },
  { code: 'fy', name: ['West Frisian', 'Frysk'] },
  { code: 'ga', name: ['Irish', 'Gaeilge'] },
  { code: 'gd', name: ['Scottish Gaelic', 'Gàidhlig'] },
  { code: 'gl', name: ['Galician', 'Galego'] },
  { code: 'gn', name: ['Guarani', "Avañe'ẽ"] },
  { code: 'gu', name: ['Gujarati', 'ગુજરાતી'] },
  { code: 'gv', name: ['Manx', 'Gaelg'] },
  { code: 'ha', name: ['Hausa', 'هَوُسَ'] },
  { code: 'he', name: ['Hebrew', 'עברית'] },
  { code: 'hi', name: ['Hindi', 'हिन्दी'] },
  { code: 'ho', name: ['Hiri Motu', 'Hiri Motu'] },
  { code: 'hr', name: ['Croatian', 'Hrvatski'] },
  { code: 'ht', name: ['Haitian', 'Krèyol ayisyen'] },
  { code: 'hu', name: ['Hungarian', 'Magyar'] },
  { code: 'hy', name: ['Armenian', 'Հայերեն'] },
  { code: 'hz', name: ['Herero', 'Otsiherero'] },
  { code: 'ia', name: ['Interlingua', 'Interlingua'] },
  { code: 'id', name: ['Indonesian', 'Bahasa Indonesia'] },
  { code: 'ie', name: ['Interlingue', 'Interlingue'] },
  { code: 'ig', name: ['Igbo', 'Igbo'] },
  { code: 'ii', name: ['Sichuan Yi', 'ꆇꉙ / 四川彝语'] },
  { code: 'ik', name: ['Inupiak', 'Iñupiak'] },
  { code: 'io', name: ['Ido', 'Ido'] },
  { code: 'is', name: ['Icelandic', 'Íslenska'] },
  { code: 'it', name: ['Italian', 'Italiano'] },
  { code: 'iu', name: ['Inuktitut', 'ᐃᓄᒃᑎᑐᑦ'] },
  { code: 'ja', name: ['Japanese', '日本語'] },
  { code: 'jv', name: ['Javanese', 'Basa Jawa'] },
  { code: 'ka', name: ['Georgian', 'ქართული'] },
  { code: 'kg', name: ['Kongo', 'KiKongo'] },
  { code: 'ki', name: ['Kikuyu', 'Gĩkũyũ'] },
  { code: 'kj', name: ['Kuanyama', 'Kuanyama'] },
  { code: 'kk', name: ['Kazakh', 'Қазақша'] },
  { code: 'kl', name: ['Greenlandic', 'Kalaallisut'] },
  { code: 'km', name: ['Cambodian', 'ភាសាខ្មែរ'] },
  { code: 'kn', name: ['Kannada', 'ಕನ್ನಡ'] },
  { code: 'ko', name: ['Korean', '한국어'] },
  { code: 'kr', name: ['Kanuri', 'Kanuri'] },
  { code: 'ks', name: ['Kashmiri', 'कश्मीरी / كشميري'] },
  { code: 'ku', name: ['Kurdish', 'Kurdî / كوردی'] },
  { code: 'kv', name: ['Komi', 'Коми'] },
  { code: 'kw', name: ['Cornish', 'Kernewek'] },
  { code: 'ky', name: ['Kirghiz', 'Kırgızca / Кыргызча'] },
  { code: 'la', name: ['Latin', 'Latina'] },
  { code: 'lb', name: ['Luxembourgish', 'Lëtzebuergesch'] },
  { code: 'lg', name: ['Ganda', 'Luganda'] },
  { code: 'li', name: ['Limburgian', 'Limburgs'] },
  { code: 'ln', name: ['Lingala', 'Lingála'] },
  { code: 'lo', name: ['Laotian', 'ລາວ / Pha xa lao'] },
  { code: 'lt', name: ['Lithuanian', 'Lietuvių'] },
  { code: 'lv', name: ['Latvian', 'Latviešu'] },
  { code: 'mg', name: ['Malagasy', 'Malagasy'] },
  { code: 'mh', name: ['Marshallese', 'Kajin Majel / Ebon'] },
  { code: 'mi', name: ['Maori', 'Māori'] },
  { code: 'mk', name: ['Macedonian', 'Македонски'] },
  { code: 'ml', name: ['Malayalam', 'മലയാളം'] },
  { code: 'mn', name: ['Mongolian', 'Монгол'] },
  { code: 'mo', name: ['Moldovan', 'Moldovenească'] },
  { code: 'mr', name: ['Marathi', 'मराठी'] },
  { code: 'ms', name: ['Malay', 'Bahasa Melayu'] },
  { code: 'mt', name: ['Maltese', 'bil-Malti'] },
  { code: 'my', name: ['Burmese', 'Myanmasa'] },
  { code: 'na', name: ['Nauruan', 'Dorerin Naoero'] },
  { code: 'nd', name: ['North Ndebele', 'Sindebele'] },
  { code: 'ne', name: ['Nepali', 'नेपाली'] },
  { code: 'ng', name: ['Ndonga', 'Oshiwambo'] },
  { code: 'nl', name: ['Dutch', 'Nederlands'] },
  { code: 'nn', name: ['Norwegian Nynorsk', 'Norsk (nynorsk)'] },
  { code: 'no', name: ['Norwegian', 'Norsk (bokmål / riksmål)'] },
  { code: 'nr', name: ['South Ndebele', 'isiNdebele'] },
  { code: 'nv', name: ['Navajo', 'Diné bizaad'] },
  { code: 'ny', name: ['Chichewa', 'Chi-Chewa'] },
  { code: 'oc', name: ['Occitan', 'Occitan'] },
  { code: 'oj', name: ['Ojibwa', 'ᐊᓂᔑᓈᐯᒧᐎᓐ / Anishinaabemowin'] },
  { code: 'om', name: ['Oromo', 'Oromoo'] },
  { code: 'or', name: ['Oriya', 'ଓଡ଼ିଆ'] },
  { code: 'os', name: ['Ossetian / Ossetic', 'Иронау'] },
  { code: 'pa', name: ['Panjabi / Punjabi', 'ਪੰਜਾਬੀ / पंजाबी / پنجابي'] },
  { code: 'pi', name: ['Pali', 'Pāli / पाऴि'] },
  { code: 'pl', name: ['Polish', 'Polski'] },
  { code: 'ps', name: ['Pashto', 'پښتو'] },
  { code: 'pt', name: ['Portuguese', 'Português'] },
  { code: 'qu', name: ['Quechua', 'Runa Simi'] },
  { code: 'rm', name: ['Raeto Romance', 'Rumantsch'] },
  { code: 'rn', name: ['Kirundi', 'Kirundi'] },
  { code: 'ro', name: ['Romanian', 'Română'] },
  { code: 'ru', name: ['Russian', 'Русский'] },
  { code: 'rw', name: ['Rwandi', 'Kinyarwandi'] },
  { code: 'sa', name: ['Sanskrit', 'संस्कृतम्'] },
  { code: 'sc', name: ['Sardinian', 'Sardu'] },
  { code: 'sd', name: ['Sindhi', 'सिनधि'] },
  { code: 'se', name: ['Northern Sami', 'Sámegiella'] },
  { code: 'sg', name: ['Sango', 'Sängö'] },
  { code: 'sh', name: ['Serbo-Croatian', 'Srpskohrvatski / Српскохрватски'] },
  { code: 'si', name: ['Sinhalese', 'සිංහල'] },
  { code: 'sk', name: ['Slovak', 'Slovenčina'] },
  { code: 'sl', name: ['Slovenian', 'Slovenščina'] },
  { code: 'sm', name: ['Samoan', 'Gagana Samoa'] },
  { code: 'sn', name: ['Shona', 'chiShona'] },
  { code: 'so', name: ['Somalia', 'Soomaaliga'] },
  { code: 'sq', name: ['Albanian', 'Shqip'] },
  { code: 'sr', name: ['Serbian', 'Српски'] },
  { code: 'ss', name: ['Swati', 'SiSwati'] },
  { code: 'st', name: ['Southern Sotho', 'Sesotho'] },
  { code: 'su', name: ['Sundanese', 'Basa Sunda'] },
  { code: 'sv', name: ['Swedish', 'Svenska'] },
  { code: 'sw', name: ['Swahili', 'Kiswahili'] },
  { code: 'ta', name: ['Tamil', 'தமிழ்'] },
  { code: 'te', name: ['Telugu', 'తెలుగు'] },
  { code: 'tg', name: ['Tajik', 'Тоҷикӣ'] },
  { code: 'th', name: ['Thai', 'ไทย / Phasa Thai'] },
  { code: 'ti', name: ['Tigrinya', 'ትግርኛ'] },
  { code: 'tk', name: ['Turkmen', 'Туркмен / تركمن'] },
  { code: 'tl', name: ['Tagalog / Filipino', 'Tagalog'] },
  { code: 'tn', name: ['Tswana', 'Setswana'] },
  { code: 'to', name: ['Tonga', 'Lea Faka-Tonga'] },
  { code: 'tr', name: ['Turkish', 'Türkçe'] },
  { code: 'ts', name: ['Tsonga', 'Xitsonga'] },
  { code: 'tt', name: ['Tatar', 'Tatarça'] },
  { code: 'tw', name: ['Twi', 'Twi'] },
  { code: 'ty', name: ['Tahitian', 'Reo Mā`ohi'] },
  { code: 'ug', name: ['Uyghur', 'Uyƣurqə / ئۇيغۇرچە'] },
  { code: 'uk', name: ['Ukrainian', 'Українська'] },
  { code: 'ur', name: ['Urdu', 'اردو'] },
  { code: 'uz', name: ['Uzbek', 'Ўзбек'] },
  { code: 've', name: ['Venda', 'Tshivenḓa'] },
  { code: 'vi', name: ['Vietnamese', 'Tiếng Việt'] },
  { code: 'vo', name: ['Volapük', 'Volapük'] },
  { code: 'wa', name: ['Walloon', 'Walon'] },
  { code: 'wo', name: ['Wolof', 'Wollof'] },
  { code: 'xh', name: ['Xhosa', 'isiXhosa'] },
  { code: 'yi', name: ['Yiddish', 'ייִדיש'] },
  { code: 'yo', name: ['Yoruba', 'Yorùbá'] },
  { code: 'za', name: ['Zhuang', 'Cuengh / Tôô / 壮语'] },
  { code: 'zh', name: ['Chinese', '中文'] },
  { code: 'zu', name: ['Zulu', 'isiZulu'] },
];
