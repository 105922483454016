import { App } from '../models/app.model';

const tag = '[App]';

export class FetchAppsRequest {
  static readonly type = `${tag} fetch apps request`;
}

export class FetchAppsSuccess {
  static readonly type = `${tag} fetch apps success`;
  constructor(public apps: App[]) {}
}

export class FetchAppsError {
  static readonly type = `${tag} fetch apps error`;
}

export class FetchAppsEmptyError {
  static readonly type = `${tag} fetch apps empty error`;
}

export class SetCurrentApp {
  static readonly type = `${tag} set current app`;
  constructor(public appId: number) {}
}

export class SetCurrentAppAsAdmin {
  static readonly type = `${tag} set current app as admin`;
  constructor(public app: App) {}
}

export class UpdateAvailable {
  static readonly type = `${tag} service worker update available`;
}

export class ShowMainMenu {
  static readonly type = `${tag} show main menu`;
  constructor(public show: boolean) {}
}

export class ShowAppList {
  static readonly type = `${tag} show app list`;
  constructor(public show: boolean) {}
}

export class ShowReturnToDashboardLink {
  static readonly type = `${tag} show return to dashboard link`;
  constructor(public show: boolean) {}
}

export class ShowMainContainerPadding {
  static readonly type = `${tag} show main container padding`;
  constructor(public show: boolean) {}
}

export class ResetAppState {
  static readonly type = `${tag} reset app state`;
}

export class ResetCurrentApp {
  static readonly type = `${tag} reset current app`;
}
