import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  InitEventTracking,
  LogUserProperty,
} from '@app/core/states/event-tracking.actions';
import { UserProperties } from '@app/core/states/event-tracking.state';
import { getDeviceCategory } from '@app/core/utils';
import { environment } from '@env/environment';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import Cookies from 'js-cookie';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { GtmService } from '@app/core/services/gtm.service';
import { I18nService } from '../../services/i18n.service';
import { AuthService, TokenItem, User, whmcsCurrency } from '../auth.service';
import {
  LoginError,
  LoginRequest,
  Logout,
  RedirectToLogin,
  RequestCurrency,
  RequestUser,
  SetCurrency,
  SetTokens,
  SetUser,
} from './auth.actions';

export class AuthStateModel {
  isLoggingIn: boolean;
  user: {
    username: string;
    firstname: string;
    lastname: string;
    email: string;
    id: number;
    imageUrl?: string;
    lang: string;
    isAdmin: boolean;
    isSuperUser?: boolean;
    whmcsId: number;
    onlySocial: boolean;
    numberLang?: string;
    currency?: whmcsCurrency;
  };
  auth: {
    token: string;
    expire: number;
    refreshToken: string;
  };
}

const defaultUser = {
  username: '',
  firstname: '',
  lastname: '',
  email: '',
  id: -1,
  lang: 'us-US',
  isAdmin: false,
  whmcsId: -1,
  onlySocial: false,
  currency: 'EUR' as whmcsCurrency,
};

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    auth: {
      token: undefined,
      refreshToken: '',
      expire: -1,
    },
    user: defaultUser,
    isLoggingIn: false,
  },
})
@Injectable()
export class AuthState {
  constructor(
    private readonly authenticationService: AuthService,
    private readonly i18nService: I18nService,
    private readonly router: Router,
    private readonly store: Store,
    private readonly gtmService: GtmService,
  ) {}

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean | undefined {
    return state.auth.token === undefined ? undefined : !!state.auth.token;
  }

  @Selector()
  static isLogging(state: AuthStateModel): boolean {
    return state.isLoggingIn;
  }

  @Selector()
  static currentUsername(state: AuthStateModel): string {
    return state.user.username;
  }

  @Selector()
  static currentFirstname(state: AuthStateModel): string {
    return state.user.firstname;
  }

  @Selector()
  static currentLastname(state: AuthStateModel): string {
    return state.user.lastname;
  }

  @Selector()
  static currentEmail(state: AuthStateModel): string {
    return state.user.email;
  }

  @Selector()
  static currentProfilePic(state: AuthStateModel): string {
    return state.user.imageUrl;
  }

  @Selector()
  static getAccessToken(state: AuthStateModel): string {
    return state.auth.token;
  }

  @Selector()
  static getRefreshToken(state: AuthStateModel): string {
    return state.auth.refreshToken;
  }

  @Selector()
  static isSuperUser(state: AuthStateModel): boolean {
    return state.user.isSuperUser;
  }

  @Selector()
  static isAdmin(state: AuthStateModel): boolean {
    return state.user.isAdmin;
  }

  @Selector()
  static isAdminOrSuperuser(state: AuthStateModel): boolean {
    return state.user.isAdmin || state.user.isSuperUser;
  }

  @Selector()
  static userId(state: AuthStateModel): number {
    return state.user.id;
  }

  @Selector()
  static userLang(state: AuthStateModel): string {
    return state.user.lang;
  }

  @Selector()
  static userCurrency(state: AuthStateModel): string {
    return state.user.currency;
  }

  @Selector()
  static userRKDomain(state: AuthStateModel): string {
    let domain = state.user.lang;
    if (state.user.lang !== 'fr') {
      domain = 'www';
    }

    return `https://${domain}.${environment.urls.RADIOKING_DOMAIN}`;
  }

  @Action(RequestCurrency)
  requestCurrency(ctx: StateContext<AuthStateModel>) {
    return this.authenticationService.getCurrency().pipe(
      map((currency: any) => {
        ctx.dispatch(new SetCurrency(currency.currency));

        return currency.currency;
      }),
    );
  }

  @Action(RequestUser)
  requestUser(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({ isLoggingIn: true });

    return this.authenticationService.getMe().pipe(
      map((user: User) => {
        ctx.dispatch(new SetUser(user));
        ctx.dispatch(new RequestCurrency());

        return user;
      }),
      catchError(() => ctx.dispatch(new Logout())),
    );
  }

  @Action(SetCurrency)
  setCurrency(ctx: StateContext<Partial<AuthStateModel>>, { currency }: SetCurrency) {
    ctx.setState(
      patch({
        user: patch({
          currency,
        }),
      }),
    );

    // tracking
    this.store.dispatch(new LogUserProperty('currency', currency));
  }

  @Action(SetUser)
  setUser(ctx: StateContext<AuthStateModel>, { user, redirect }: SetUser) {
    ctx.patchState({
      user: {
        ...ctx.getState().user,
        username: user.username,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        id: user.iduser,
        imageUrl: user.idfile_avatar,
        lang: user.lang,
        isSuperUser: user.is_superuser,
        isAdmin: user.is_admin,
        whmcsId: user.idwhmcs,
        onlySocial: user.only_social,
      },
      isLoggingIn: false,
    });
    this.i18nService.language = user.lang;

    // amplitude tracking
    const userProperties: UserProperties = {
      language: user.lang,
      user_id: user.iduser.toString(),
      device_size: `${window.screen.width.toString()} x ${window.screen.height.toString()}`,
      device_category: getDeviceCategory(),
      product_name: 'App simulator',
    };

    ctx.dispatch(new InitEventTracking(userProperties));

    // google tag manager
    if (environment.gtm) {
      const dataLayer = {
        country_user: user.country,
        email_user: user.email,
        id_user: user.iduser,
        first_name: user.firstname,
        name_user: user.lastname,
        user_zendesk_hash: user.zendesk_hash,
      };
      this.gtmService.pushToDataLayer(dataLayer);
    }

    if (redirect) {
      ctx.dispatch(new Navigate([''], {}, { replaceUrl: true }));
    }
  }

  @Action(SetTokens)
  setTokens(
    ctx: StateContext<AuthStateModel>,
    { token, expire, refreshToken }: SetTokens,
  ) {
    ctx.patchState({ auth: { token, expire, refreshToken } });
    Cookies.set(environment.cookies.tokenKey, token);
    Cookies.set(environment.cookies.refreshTokenKey, refreshToken);
  }

  @Action(LoginError)
  loginError(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({ isLoggingIn: false, auth: { ...ctx.getState().auth, token: '' } });
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    return this.authenticationService.logout().pipe(
      catchError(() => of()),
      mergeMap(() => ctx.dispatch(new RedirectToLogin())),
    );
  }

  @Action(RedirectToLogin)
  redirectToLogin(ctx: StateContext<AuthStateModel>): Observable<void> | void {
    ctx.patchState({
      user: defaultUser,
      auth: {
        refreshToken: '',
        expire: 0,
        token: '',
      },
      isLoggingIn: false,
    });

    if (environment.auth.isLocalLoginEnabled) {
      return ctx.dispatch(new Navigate(['/login'], {}, { replaceUrl: true }));
    }
    document.location.href = environment.auth.redirectUrl;
  }

  @Action(LoginRequest)
  loginRequest(ctx: StateContext<AuthStateModel>, { login, password }: LoginRequest) {
    ctx.patchState({ isLoggingIn: true });

    return this.authenticationService.login({ password, username: login }).pipe(
      map((tok: TokenItem) =>
        ctx.dispatch(new SetTokens(tok.access_token, tok.expire, tok.refresh_token)),
      ),
      switchMap(() => this.authenticationService.getMe()),
      switchMap((user: User) => ctx.dispatch(new SetUser(user, true))),
      catchError(error => ctx.dispatch(new LoginError(error))),
    );
  }
}
