<div class="mb-2 opacity-70 mt-7 ">
  {{ 'general-configuration.font-size' | translate }}
</div>
<div class="flex items-center relative">
  <div class="markers flex justify-between absolute">
    <span class="marker"></span>
    <span class="marker"></span>
    <span class="marker"></span>
    <span class="marker"></span>
    <span class="marker"></span>
  </div>
  <span class="text-xs">A</span>
  <input
    type="range"
    min="1"
    max="5"
    step="1"
    [(ngModel)]="fontSize"
    (input)="onSizeChange()"
    class="p-0 mx-4 range-input relative"
  />
  <span class="text-[32px] font-bold">A</span>
</div>
