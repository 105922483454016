import { Injectable } from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';
import { Store } from '@ngxs/store';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { UpdateAppConfig } from '../states/app-configuration.actions';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private readonly store: Store) {}

  createFormControl(
    initialValue: any,
    config: {
      configPath: string[];
      validators?: ValidatorFn[];
    },
  ): FormControl {
    const control = new FormControl(initialValue, config.validators);
    control.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        const actionInstance = new UpdateAppConfig(config.configPath, value);
        this.store.dispatch(actionInstance);
      });

    return control;
  }

  setToggleState(configKey: string): boolean {
    return configKey === 'YES';
  }

  onToggleChange(event: boolean, configPath: string[]): void {
    const value = event ? 'YES' : 'NO';
    this.store.dispatch(new UpdateAppConfig(configPath, value));
  }
}
