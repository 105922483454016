import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminApp, AdminAppApi, transformAdminApp } from '@app/core/models/app.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const ADMIN_URLS = {
  getApps: (query?: string) =>
    `${environment.urls.MOBILE_API}/admin/app${query ? `/search/${query}` : ''}`,
  postIosKey: (appId: number) =>
    `${environment.urls.MOBILE_API}/admin/app/${appId}/ioskey`,
  postP8Certificate: (appId: number) =>
    `${environment.urls.MOBILE_API}/admin/app/${appId}/p8cert`,
  postAndroidApiKey: (appId: number) =>
    `${environment.urls.MOBILE_API}/admin/app/${appId}/apikey`,
  postAndroidArchive: (appId: number) =>
    `${environment.urls.MOBILE_API}/admin/app/${appId}/android-archive`,
  downloadAndroidArchive: (appId: number) =>
    `${environment.urls.MOBILE_API}/admin/app/${appId}/android-files`,
  postAppleArchive: (appId: number) =>
    `${environment.urls.MOBILE_API}/admin/app/${appId}/ios-archive`,
  downloadAppleArchive: (appId: number) =>
    `${environment.urls.MOBILE_API}/admin/app/${appId}/ios-files`,
};

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private readonly http: HttpClient) {}

  getApps(page: number, query: string): Observable<AdminApp[]> {
    const params = new HttpParams()
      .set('sort', '-dateCreation')
      .set('page', `${page}`)
      .set('limit', '30');

    return this.http
      .get<AdminAppApi[]>(ADMIN_URLS.getApps(query), { params })
      .pipe(map((response: any) => response.data.map(transformAdminApp)));
  }

  postIosKey(appId: number, teamId: string, keyId: string): Observable<void> {
    const payload = { team_id: teamId, key_id: keyId };

    return this.http.post<void>(ADMIN_URLS.postIosKey(appId), payload);
  }

  postP8Certificate(appId: number, p8Cert: Blob): Observable<void> {
    const formData = new FormData();
    formData.append('cert', p8Cert);

    return this.http.post<void>(ADMIN_URLS.postP8Certificate(appId), formData);
  }

  postAndroidApiKey(appId: number, androidApiKey: string): Observable<void> {
    const payload = { apikey: androidApiKey };

    return this.http.post<void>(ADMIN_URLS.postAndroidApiKey(appId), payload);
  }

  postAndroidArchive(appId: number, archive: Blob): Observable<void> {
    const formData = new FormData();
    formData.append('archive', archive);

    return this.http.post<void>(ADMIN_URLS.postAndroidArchive(appId), formData);
  }

  downloadAndroidArchive(appId: number): Observable<Blob> {
    return this.http.get<Blob>(ADMIN_URLS.downloadAndroidArchive(appId), {
      responseType: 'blob' as 'json',
    });
  }

  postAppleArchive(appId: number, archive: Blob): Observable<void> {
    const formData = new FormData();
    formData.append('archive', archive);

    return this.http.post<void>(ADMIN_URLS.postAppleArchive(appId), formData);
  }

  downloadAppleArchive(appId: number): Observable<Blob> {
    return this.http.get<Blob>(ADMIN_URLS.downloadAppleArchive(appId), {
      responseType: 'blob' as 'json',
    });
  }
}
