import { Component } from '@angular/core';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import { AppConfigurationService } from '@app/features/app-creation-and-configuration/services/app-configuration.service';
import {
  UpdateActivePage,
  UpdateActiveSettingsCategory,
} from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

interface settingsItem {
  translationKey: string;
  value: string;
  icon: IconProp;
}

@Component({
  selector: 'rk-settings-category-list',
  templateUrl: './settings-category-list.component.html',
  styleUrls: ['./settings-category-list.component.scss'],
})
export class SettingsCategoryListComponent {
  @Select(AppConfigurationState.activeSettingsCategory)
  activeSettingsCategory$: Observable<string>;

  private readonly subscription: Subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly appConfigurationService: AppConfigurationService,
  ) {}

  settingsList: settingsItem[] = [
    {
      translationKey: marker('settings-list.general-configuration'),
      value: 'general',
      icon: 'gears',
    },
    {
      translationKey: marker('settings-list.menu'),
      value: 'menu',
      icon: 'bars',
    },
    {
      translationKey: marker('settings-list.pages'),
      value: 'pages',
      icon: 'file-lines',
    },
  ];

  onSelectItem(value: string): void {
    switch (value) {
      case 'menu':
        this.appConfigurationService.openMenu();
        this.store.dispatch(new UpdateActiveSettingsCategory(value));
        this.store.dispatch(new UpdateActivePage(null));

        // event tracking
        this.store.dispatch(
          new SetEventProperties({
            name: 'NavMenu',
            component: 'Navigation',
          }),
        );
        this.store.dispatch(new LogEvent('Navigation Clicked'));
        break;
      case 'pages':
        this.store.dispatch(new UpdateActiveSettingsCategory(value));
        this.store.dispatch(new UpdateActivePage(0));

        // event tracking
        this.store.dispatch(
          new SetEventProperties({
            name: 'NavPagesPlayer',
            component: 'Navigation',
          }),
        );
        this.store.dispatch(new LogEvent('Navigation Clicked'));
        break;
      case 'general':
        this.store.dispatch(new UpdateActiveSettingsCategory(value));
        this.store.dispatch(new UpdateActivePage(null));

        // event tracking
        this.store.dispatch(
          new SetEventProperties({
            name: 'NavConfiguration',
            component: 'Navigation',
          }),
        );
        this.store.dispatch(new LogEvent('Navigation Clicked'));
        break;
      default:
        break;
    }
  }
}
