import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { PlatformSubscriber, SubscribersPerDay } from '../models/statistics.models';

const STATISTICS_URLS = {
  getSubscribersCount: (id: number) =>
    `${environment.urls.MOBILE_API}/app/${id}/subscriber/count?empty&group=platform`,
  getNewSubscribers: (id: number) =>
    `${environment.urls.MOBILE_API}/app/${id}/subscriber/perday`,
  getConsumption: (id: number) => `${environment.urls.MOBILE_API}/app/${id}/consumption`,
  getNotificationsSent: (id: number) =>
    `${environment.urls.MOBILE_API}/app/${id}/notification/subscriber/perday`,
};

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  constructor(private readonly http: HttpClient) {}

  getSubscribersCount(id: number): Observable<PlatformSubscriber[]> {
    return this.http
      .get<PlatformSubscriber[]>(STATISTICS_URLS.getSubscribersCount(id))
  }

  getNewSubscribers(id: number): Observable<SubscribersPerDay[]> {
    return this.http
      .get<SubscribersPerDay[]>(STATISTICS_URLS.getNewSubscribers(id))
  }

  getConsumption(
    id: number,
  ): Observable<{ consumption: number; limit: number; use_percent: number }> {
    return this.http
      .get<{ consumption: number; limit: number; use_percent: number }>(
        STATISTICS_URLS.getConsumption(id),
      )
  }

  getNotificationsSent(id: number): Observable<SubscribersPerDay[]> {
    return this.http
      .get<SubscribersPerDay[]>(STATISTICS_URLS.getNotificationsSent(id))
  }
}
