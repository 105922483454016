<!-- color background style selector -->
<div class="opacity-70 mb-3">
  {{ 'general-configuration.background-color-style' | translate }}
</div>
<div class="flex gap-4 mb-6">
  <!-- solid background -->
  <div
    class="design-option-container"
    [class.selected]="(currentAppConfigBackgroundColorStyle$ | async) === 'solid'"
    (click)="selectBackgroundStyle('solid')"
    rkTooltip
    [tooltipDescription]="'general-configuration.solid-color-tooltip' | translate"
    [customStyle]="tooltipFitContentStyle"
  >
    <div
      class="design-option-item size-[40px] rounded bg-[#848484] solid-background-selector"
    ></div>
  </div>
  <!-- gradient background -->
  <div
    class="design-option-container"
    [class.selected]="(currentAppConfigBackgroundColorStyle$ | async) === 'gradient'"
    (click)="selectBackgroundStyle('gradient')"
    rkTooltip
    [tooltipDescription]="'general-configuration.gradient-color-tooltip' | translate"
    [customStyle]="tooltipFitContentStyle"
  >
    <div
      class="design-option-item size-[40px] rounded gradient-background-selector"
    ></div>
  </div>
  <!-- bicolor background -->
  <div
    class="design-option-container"
    [class.selected]="(currentAppConfigBackgroundColorStyle$ | async) === 'bicolor'"
    (click)="selectBackgroundStyle('bicolor')"
    rkTooltip
    [tooltipDescription]="'general-configuration.bicolor-color-tooltip' | translate"
    [customStyle]="tooltipFitContentStyle"
  >
    <div class="design-option-item size-[40px] rounded bicolor-background-selector"></div>
  </div>
</div>
<!-- background color 1 -->
<div
  class="color-selector"
  [style.transform]="isSwapped ? 'translateY(174px)' : ''"
>
  <div
    class="opacity-70 hide"
    [class.show]="(currentAppConfigBackgroundColorStyle$ | async) !== 'solid'"
  >
    <p class="opacity-70">{{ 'general-configuration.background-color' | translate }}</p>
  </div>

  <rk-color-selector
    [opacityEnabled]="true"
    [parameter]="''"
    (colorChange)="onColorChange($event, 'backgroundColor')"
    [initialColor]="currentAppConfigBackgroundColor$ | async"
  ></rk-color-selector>
</div>

<!-- ivert colors button -->
<div
  class="my-3 flex justify-center items-center hide"
  [class.show]="(currentAppConfigBackgroundColorStyle$ | async) !== 'solid'"
>
  <div class="border border-[#e6e6e6] w-full"></div>
  <div
    class="flex opacity-10 swap-colors-button rounded mx-1 cursor-pointer p-2"
    (click)="swapColors()"
    rkTooltip
    tooltipPosition="bottom"
    [tooltipDescription]="'general-configuration.invert-colors' | translate"
    [customStyle]="tooltipFitContentStyle"
  >
    <fa-icon class="arrow-up" [icon]="'arrow-up'"></fa-icon>
    <fa-icon class="arrow-down" [icon]="'arrow-down'"></fa-icon>
  </div>
  <div class="border border-[#e6e6e6] w-full"></div>
</div>
<div
  class="hide"
  [class.show]="(currentAppConfigBackgroundColorStyle$ | async) !== 'solid'"
>
  <!-- background color 2 -->
  <div
    class="color-selector"
    [style.transform]="isSwapped ? 'translateY(-174px)' : ''"
    [style.zIndex]="'10'"
  >
    <div class="opacity-70">
      {{ 'general-configuration.background-color-2' | translate }}
    </div>

    <rk-color-selector
      [opacityEnabled]="true"
      [parameter]="''"
      (colorChange)="onColorChange($event, 'backgroundColorSecondary')"
      [initialColor]="currentAppConfigBackgroundColorSecondary$ | async"
    ></rk-color-selector>
  </div>
</div>
<!-- gradient direction -->
<div
  class="hide"
  [class.show]="(currentAppConfigBackgroundColorStyle$ | async) !== 'solid'"
>
  <div class="opacity-70 mb-3 mt-6">
    {{ 'general-configuration.background-gradient-direction' | translate }}
  </div>
  <div class="flex gap-4 mb-[2px] ml-[2px]">
    <!-- bottom direction -->
    <div
      class="design-option-container"
      [class.selected]="
        (currentAppConfigBackgroundGradientDirection$ | async) === 'bottom'
      "
      (click)="selectGradientDirection('bottom')"
    >
      <div
        class="design-option-item size-[40px] rounded gradient-background-selector flex justify-center items-center"
      >
        <fa-icon [icon]="'arrow-down'" class="text-white text-2xl"> </fa-icon>
      </div>
    </div>
    <!-- bottom right direction -->
    <div
      class="design-option-container"
      [class.selected]="
        (currentAppConfigBackgroundGradientDirection$ | async) === 'bottom-right'
      "
      (click)="selectGradientDirection('bottom-right')"
    >
      <div
        class="design-option-item size-[40px] rounded gradient-background-selector flex justify-center items-center"
      >
        <fa-icon [icon]="'arrow-down'" class="text-white text-2xl -rotate-45"> </fa-icon>
      </div>
    </div>
    <!--  right direction -->
    <div
      class="design-option-container"
      [class.selected]="
        (currentAppConfigBackgroundGradientDirection$ | async) === 'right'
      "
      (click)="selectGradientDirection('right')"
    >
      <div
        class="design-option-item size-[40px] rounded gradient-background-selector flex justify-center items-center"
      >
        <fa-icon [icon]="'arrow-down'" class="text-white text-2xl -rotate-90"> </fa-icon>
      </div>
    </div>
    <!--  bottom left direction -->
    <div
      class="design-option-container"
      [class.selected]="
        (currentAppConfigBackgroundGradientDirection$ | async) === 'bottom-left'
      "
      (click)="selectGradientDirection('bottom-left')"
    >
      <div
        class="design-option-item size-[40px] rounded gradient-background-selector flex justify-center items-center"
      >
        <fa-icon [icon]="'arrow-down'" class="text-white text-2xl rotate-45"> </fa-icon>
      </div>
    </div>
  </div>
</div>
