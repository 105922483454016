import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppConfigApi } from '../models/app-config.model';
import { AppConfigAssets, UserRadio } from '../states/app-configuration.state';

const APP_CONFIGURATION_URLS = {
  getNameAndLogo: (appId: number) =>
    `${environment.urls.MOBILE_API}/app/${appId}/get-name-and-logo`,
  postNameAndLogo: (appId?: number) =>
    appId
      ? `${environment.urls.MOBILE_API}/app/${appId}/create`
      : `${environment.urls.MOBILE_API}/app/create`,
  getAppConfig: (appId: number) =>
    `${environment.urls.MOBILE_API}/app/${appId}/simulator-configuration`,
  postAppConfig: (appId: number) =>
    `${environment.urls.MOBILE_API}/app/${appId}/save-simulator-configuration`,
  postAssets: (appId: number) =>
    `${environment.urls.MOBILE_API}/app/${appId}/update-assets`,
  checkAppName: () => `${environment.urls.MOBILE_API}/app/name-available`,
  plan: (appId: number, planId: number) => `${environment.urls.MOBILE_API}/app/${appId}/plan?plan=${planId}`,
  getUserRadios: (userId: number) =>
    `${environment.urls.MOBILE_API}/app/get-radios?id=${userId}`,
  getIcecastMountPoints: (url: string) =>
    `${environment.urls.MOBILE_API}/app/get-mountpoints?url=${url}`,
};

@Injectable({
  providedIn: 'root',
})
export class AppConfigurationService {
  onOpenMenu = new EventEmitter<boolean>();

  constructor(private readonly http: HttpClient, private readonly store: Store) {}

  getNameAndLogo(appId: number): Observable<Partial<AppConfigApi>> {
    return this.http.get<Partial<AppConfigApi>>(
      APP_CONFIGURATION_URLS.getNameAndLogo(appId),
    );
  }

  postNameAndLogo(name: string, logo?: Blob, appId?: number): Observable<AppConfigApi> {
    const formData = new FormData();
    formData.append('name', name);
    if (logo) {
      formData.append('logo', logo);
    }

    return this.http.post<any>(APP_CONFIGURATION_URLS.postNameAndLogo(appId), formData);
  }

  getAppConfig(appId: number): Observable<AppConfigApi> {
    return this.http.get<AppConfigApi>(APP_CONFIGURATION_URLS.getAppConfig(appId));
  }

  postAppConfig(
    config: AppConfigApi,
    appId: number,
    configAssets?: AppConfigAssets,
  ): Observable<AppConfigApi> {
    const formData = new FormData();
    formData.append('data', JSON.stringify(config));

    if (configAssets) {
      Object.keys(configAssets).forEach(key => {
        const file = configAssets[key];
        formData.append(key, file);
      });
    }

    return this.http.post<AppConfigApi>(
      APP_CONFIGURATION_URLS.postAppConfig(appId),
      formData,
    );
  }

  plan(planId: number, appId: number): Observable<boolean> {
    return this.http.put<boolean>(APP_CONFIGURATION_URLS.plan(appId, planId), undefined);
  }

  postAssets(appId: number, configAssets?: AppConfigAssets): Observable<AppConfigApi> {
    const formData = new FormData();
    const assets = configAssets || {};
    Object.keys(assets).forEach(key => {
      const file = assets[key];
      formData.append(key, file);
    });

    return this.http.post<AppConfigApi>(
      APP_CONFIGURATION_URLS.postAssets(appId),
      formData,
    );
  }

  checkAppName(name: string): Observable<boolean> {
    const params = new HttpParams().set('name', name);

    return this.http.get<boolean>(APP_CONFIGURATION_URLS.checkAppName(), { params });
  }

  getUserRadios(userId: number): Observable<UserRadio[]> {

    return this.http.get<UserRadio[]>(APP_CONFIGURATION_URLS.getUserRadios(userId));
  }

  getIcecastMountPoints(url: string): Observable<string[]> {
    return this.http.get<string[]>(APP_CONFIGURATION_URLS.getIcecastMountPoints(url));
  }

  openMenu() {
    this.onOpenMenu.emit();
  }
}
