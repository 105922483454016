import { Component } from '@angular/core';

@Component({
  selector: 'rk-player-configuration-shell',
  templateUrl: './player-configuration-shell.component.html',
  styleUrl: './player-configuration-shell.component.scss',
})
export class PlayerConfigurationShellComponent {
  selectedSubMenu = 'content';

  onSelectSubmenu(event: string) {
    this.selectedSubMenu = event;
  }
}
