import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class MockTabsService {
  constructor(private readonly translate: TranslateService) {}

  mockTabs = {
    dedicaces: {
      idonglets: 0,
      type: 'dedicaces',
      titre: this.translate.instant('mock-tabs.dedications'),
      icon: 'chat',
      faIcon: 'fa-solid fa-comments',
      headerColor: '#000000',
      headerTextColor: '#ffffff',
    },
    rss: {
      idonglets: 0,
      type: 'rss',
      titre: this.translate.instant('mock-tabs.news'),
      icon: 'rss',
      faIcon: 'fa-solid fa-rss',
      headerColor: '#000000',
      headerTextColor: '#ffffff',
      urlFlux: this.translate.instant('mock-tabs.rss-link'),
    },
    site: {
      idonglets: 0,
      type: 'site',
      titre: this.translate.instant('mock-tabs.website'),
      icon: 'globe_black',
      faIcon: 'fa-solid fa-earth-europe',
      headerColor: '#000000',
      headerTextColor: '#ffffff',
      urlSite: 'https://www.google.com',
    },
    html: {
      idonglets: 0,
      type: 'html',
      titre: 'Informations',
      icon: 'info',
      faIcon: 'fa-solid fa-circle-info',
      headerColor: '#000000',
      headerTextColor: '#ffffff',
      backgroundColor: '#ffffff',
      codeHTML: 'Hello World',
    },
    livefeed: {
      idonglets: 0,
      type: 'livefeed',
      titre: 'LiveFeed',
      faIcon: 'fa-solid fa-comment',
      icon: 'chat1',
      headerColor: '#000000',
      headerTextColor: '#ffffff',
    },
    dedicacesaudio: {
      idonglets: 0,
      type: 'dedicacesaudio',
      titre: this.translate.instant('mock-tabs.shout-outs'),
      icon: 'quote2',
      faIcon: 'fa-solid fa-quote-right',
      headerColor: '#1E7FCB',
      headerTextColor: '#ffffff',
      idradio: -1,
      foregroundColor: '#ffffff',
      backgroundColor: '#1e80cb',
      welcomeMessage: '',
      endMessage: '',
    },
    reveil: {
      idonglets: 0,
      type: 'reveil',
      titre: this.translate.instant('mock-tabs.alarm'),
      faIcon: 'fa-regular fa-clock',
      icon: 'time',
      headerColor: '#000000',
      headerTextColor: '#ffffff',
    },
  };

  getTabs() {
    return this.mockTabs;
  }
}
