import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthState } from '@app/core/auth/states/auth.state';
import { AppConfigApi } from '@app/features/app-creation-and-configuration/models/app-config.model';
import { AudioDedicationTabModel } from '@app/features/app-creation-and-configuration/models/app-tabs.model';
import { FormService } from '@app/features/app-creation-and-configuration/services/form.service';
import {
  FetchUserRadiosRequest,
  UpdateAppConfig,
} from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import {
  AppConfigurationState,
  UserRadio,
} from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { DropdownOption } from '@app/ui/components/dropdown/dropdown.model';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, combineLatest, filter, map, take } from 'rxjs';

@Component({
  selector: 'rk-audio-dedications-configuration',
  templateUrl: './audio-dedications-configuration.component.html',
  styleUrl: './audio-dedications-configuration.component.scss',
})
export class AudioDedicationsConfigurationComponent implements OnInit, OnDestroy {
  @Select(AppConfigurationState.activePageIndex)
  activePageIndex$: Observable<string>;

  @Select(AuthState.userId)
  userId$: Observable<number>;

  @Select(AppConfigurationState.currentAppConfig)
  currentAppConfig$: Observable<AppConfigApi>;

  @Select(AppConfigurationState.userRadios)
  userRadios$: Observable<UserRadio[]>;

  @Select(AuthState.userRKDomain)
  userRKDomain$: Observable<string>;

  private readonly subscriptions = new Subscription();

  createRadioMarketingLink$: Observable<string>;
  pageIndex: number;
  userRadioOptions: DropdownOption[] = [];
  currentUserRadio: DropdownOption;
  welcomeMessage: FormControl;
  endMessage: FormControl;
  welcomeMessageIsActive = false;
  endMessageIsActive = false;
  userHasNoRkRadio = false;

  constructor(
    private readonly store: Store,
    private readonly formService: FormService,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit(): void {
    // set page index
    this.subscriptions.add(
      this.activePageIndex$
        .pipe(filter(index => !!index))
        .subscribe(id => (this.pageIndex = Number(id))),
    );

    // fetch user radios
    this.subscriptions.add(
      this.userId$
        .pipe(
          filter(userId => userId !== -1),
          take(1),
        )
        .subscribe(userId => {
          this.store.dispatch(new FetchUserRadiosRequest(userId));
        }),
    );

    // set config
    this.subscriptions.add(
      combineLatest([
        this.userRadios$.pipe(filter(radios => !!radios)),
        this.currentAppConfig$.pipe(
          filter(config => !!config),
        ),
        this.activePageIndex$.pipe(
          filter(index => !!index),
        ),
      ]).subscribe(([radios, config, index]) => {
        const audioDedicationTab = config.onglets[
          index
        ] as AudioDedicationTabModel;

        // set welcome and end message
        this.welcomeMessage = this.formService.createFormControl(
          audioDedicationTab.welcomeMessage,
          {
            configPath: ['onglets', index.toString(), 'welcomeMessage'],
          },
        );
        this.endMessage = this.formService.createFormControl(
          audioDedicationTab.endMessage,
          {
            configPath: ['onglets', index.toString(), 'endMessage'],
          },
        );

        this.adjustTextareasOnInit();

        // set toggle states
        this.welcomeMessageIsActive = !!audioDedicationTab.welcomeMessage;
        this.endMessageIsActive = !!audioDedicationTab.endMessage;

        // set user radios
        if (radios.length > 0) {
          this.userRadioOptions = [];
          radios.map(radio =>
            this.userRadioOptions.push({
              value: radio.idradio.toString(),
              label: radio.name,
              disabled: false,
            }),
          );

          // set selected radio
          if (audioDedicationTab.idonglets) {
            this.currentUserRadio = this.userRadioOptions.find(
              option => option?.value === audioDedicationTab.idradio?.toString(),
            );
          } else {
            this.currentUserRadio = this.userRadioOptions[0];
          }
        } else {
          this.userHasNoRkRadio = true;
        }
      }),
    );

    // creates link to maketing page depending on user language
    this.createRadioMarketingLink$ = this.userRKDomain$.pipe(
      map(
        domain =>
          `${domain}${this.translate.instant('audio-dedications.rk-radio-create-link')}`,
      ),
    );
  }

  onWelcomeMessageIsActiveChange(event: boolean) {
    this.welcomeMessageIsActive = event;
    if (!event) {
      this.store.dispatch(
        new UpdateAppConfig(['onglets', this.pageIndex?.toString(), 'welcomeMessage'], ''),
      );
    }
  }

  onEndMessageIsActiveChange(event: boolean) {
    this.endMessageIsActive = event;
    if (!event) {
      this.store.dispatch(
        new UpdateAppConfig(['onglets', this.pageIndex?.toString(), 'endMessage'], ''),
      );
    }
  }

  onUserRadioChange(radioId: string) {
    this.store.dispatch(
      new UpdateAppConfig(['onglets', this.pageIndex?.toString(), 'idradio'], radioId),
    );
  }

  adjustTextareaHeight(arg: Event | HTMLTextAreaElement) {
    let textarea: HTMLTextAreaElement;

    // if function is triggered by user typing
    if (arg instanceof Event) {
      textarea = arg.target as HTMLTextAreaElement;
      // if function is triggered existing text loaded from the app config
    } else {
      textarea = arg;
    }
    if (!textarea) {
      return;
    }

    // adjust the height of the textarea
    textarea.style.height = '40px'; // reset height to recalculate
    textarea.style.height = `${Math.min(textarea.scrollHeight, 120)}px`; // set new height
  }

  adjustTextareasOnInit() {
    // use timeout to ensure the DOM updates have been processed
    setTimeout(() => {
      const welcomeTextarea = document.getElementById(
        'welcomeTextarea',
      ) as HTMLTextAreaElement;
      const endTextarea = document.getElementById('endTextarea') as HTMLTextAreaElement;

      if (welcomeTextarea) {
        this.adjustTextareaHeight(welcomeTextarea);
      }

      if (endTextarea) {
        this.adjustTextareaHeight(endTextarea);
      }
    }, 0);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
