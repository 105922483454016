import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Observable, filter, take } from 'rxjs';
import { AppState } from './states/app.state';

export function getAppId$(store: Store): Observable<number> {
  return store.select(AppState.currentAppId).pipe(
    filter(app => !!app),
    take(1),
  );
}

export function getDeviceCategory() {
  const ua = navigator.userAgent;
  if (/mobile/i.test(ua)) {
    return 'Mobile';
  } else if (/tablet|ipad|playbook|silk/i.test(ua)) {
    return 'Tablet';
  } else if (/MSIE|Trident|Firefox|Safari|Chrome|Edge/i.test(ua)) {
    return 'Desktop';
  }

  return 'Other';
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const emailValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const email = control.value;
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const isValidEmail = emailPattern.test(email);

  return isValidEmail ? null : { invalidEmail: true };
};

export const phoneNumberValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const phoneNumber = control.value;
  const phonePattern = /^[0-9+]*$/;
  const isValidPhoneNumber = phonePattern.test(phoneNumber) && phoneNumber.length >= 8;

  return isValidPhoneNumber ? null : { invalidPhoneNumber: true };
};
