<div
  class="bg-error rounded-[22px] size-11 flex items-center justify-center mr-5 alert-container relative cursor-pointer"
  (click)="onIconClick()"
  [class.visible]="(apiErrorMessage$ | async) !== null"
>
  <fa-icon class="text-2xl text-white" icon="exclamation-triangle"> </fa-icon>
  <div
    class="bg-error size-5 rounded-[10px] border-[3px] border-[#fff0ea] flex items-center justify-center absolute right-0 top-[-5px]"
  >
    <span class="text-sm text-white">1</span>
  </div>
</div>
<div
  class="absolute right-2.5 top-6 bg-white p-4 rounded alert-details"
  [class.visible]="showAlertDetails && (apiErrorMessage$ | async) !== null"
>
  <div class="bg-[#fbe7e8] rounded p-4">
    <div class="text-error w-[348px]">{{ apiErrorMessage$ | async }}</div>
  </div>
</div>

