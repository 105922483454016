import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { AppConfigurationState } from './app-configuration.state';
import { NextStep, PreviousStep, ShowSteps, SkipToStep, UpdateTooltipMessage } from './stepper.actions';

export class StepperStateModel {
  currentStep: number;
  steps: Step[];
  showSteps: boolean;
  tooltipMessage: string
}

export interface Step {
  translationKey: string;
  status: 'current' | 'disabled' | 'done';
  getUrl: (appId: number) => string;
}

const initialSteps: Step[] = [
  {
    translationKey: marker('stepper.name'),
    status: 'current',
    getUrl: (appId: number) => `/create/${appId}`,
  },
  {
    translationKey: marker('stepper.design'),
    status: 'disabled',
    getUrl: (appId: number) => `/configure/${appId}`,
  },
  {
    translationKey: marker('stepper.generation'),
    status: 'disabled',
    getUrl: (appId: number) => `/assets/${appId}`,
  },
  {
    translationKey: marker('stepper.last'),
    status: 'disabled',
    getUrl: (appId: number) => `/publication/${appId}`,
  },
];

@State<StepperStateModel>({
  name: 'stepper',
  defaults: {
    currentStep: 0,
    steps: initialSteps,
    showSteps: false,
    tooltipMessage: null
  },
})

@Injectable()
export class StepperState {
  constructor(
    private readonly router: Router,
    private readonly store: Store,
  ) {}
  @Selector()
  static currentStep(state: StepperStateModel): number {
    return state.currentStep;
  }

  @Selector()
  static steps(state: StepperStateModel): Step[] {
    return state.steps;
  }

  @Selector()
  static stepsLength(state: StepperStateModel): number {
    return state.steps.length;
  }

  @Selector()
  static isLastStep(state: StepperStateModel): boolean {
    return state.currentStep >= state.steps.length - 1;
  }

  @Selector()
  static showSteps(state: StepperStateModel): boolean {
    return state.showSteps;
  }

  @Selector()
  static tooltipMessage(state: StepperStateModel): string {
    return state.tooltipMessage;
  }

  @Action(NextStep)
  nextStep(ctx: StateContext<StepperStateModel>) {
    const state = ctx.getState();
    if (state.currentStep < state.steps.length) {
      const newCurrentStep = state.currentStep + 1;
      const updatedSteps = this.updateStepStatuses(state.steps, newCurrentStep);

      ctx.patchState({
        currentStep: newCurrentStep,
        steps: updatedSteps,
      });
      this.navigateToStep(ctx);
    }
  }

  @Action(PreviousStep)
  previousStep(ctx: StateContext<StepperStateModel>) {
    const state = ctx.getState();
    if (state.currentStep > 0) {
      const newCurrentStep = state.currentStep - 1;
      const updatedSteps = this.updateStepStatuses(state.steps, newCurrentStep);

      ctx.patchState({
        currentStep: newCurrentStep,
        steps: updatedSteps,
      });
      this.navigateToStep(ctx);
    }
  }

  @Action(SkipToStep)
  skipToStep(ctx: StateContext<StepperStateModel>, action: SkipToStep) {
    const state = ctx.getState();
    const targetStep = action.targetStep;

    if (targetStep >= 0 && targetStep < state.steps.length) {
      const updatedSteps = this.updateStepStatuses(state.steps, targetStep);
      ctx.patchState({
        currentStep: targetStep,
        steps: updatedSteps,
      });
    }
  }

  @Action(ShowSteps)
  showSteps(ctx: StateContext<StepperStateModel>, { show }: ShowSteps) {
    ctx.patchState({ showSteps: show });
  }

  @Action(UpdateTooltipMessage)
  updateTooltipMessage(ctx: StateContext<StepperStateModel>, { tooltipMessage }: UpdateTooltipMessage) {
    ctx.patchState({ tooltipMessage });
  }

  private updateStepStatuses(steps: Step[], newCurrentStep: number): Step[] {
    return steps.map((step, index) => {
      if (index < newCurrentStep) {
        return { ...step, status: 'done' };
      } else if (index === newCurrentStep) {
        return { ...step, status: 'current' };
      } else {
        return { ...step, status: 'disabled' };
      }
    });
  }

  private navigateToStep(ctx: StateContext<StepperStateModel>) {
    const steps = ctx.getState().steps;
    const step = steps[ctx.getState().currentStep];
    if (step && step.getUrl) {
      const appId = this.store.selectSnapshot(AppConfigurationState.currentAppConfigId);
      const url = step.getUrl(appId);
      this.router.navigateByUrl(url);
    }
  }
}
